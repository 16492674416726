const configCategories = `
 query configCategories($categoryId: String) {
    configCategories(categoryId: $categoryId) {
      _id
      name
      categoryId
      image
      childCategories {
        _id
        name
        categoryId
      }
    }
  }
`;

const configChildCategories = `
 query configChildCategories {
    configChildCategories {
      _id
      name
      categoryId
      configs{
        _id
        name
        dependsOn
      }
      childCategories {
        _id
        name
        categoryId
        configs {
          _id
          name
          order
        }
      }
    }
  }
`;

const configParentCategories = `
  query configParentCategories {
    configParentCategories {
        _id
        name
        categoryId
        image
        childCategories {
          _id
          name
          categoryId
          configs {
            _id
            name
            order
          }
        }
    }
  }
`;
const configCategoryDetails = `
    query configCategoryDetails($_id: String) {
        configCategoryDetails(_id: $_id) {
            _id
            name
            categoryId
            image
            childCategories {
              _id
              name
              categoryId
            }
        }
    }
`;

const configDetails = `
    query configDetails($_id: String) {
      configDetails(_id: $_id) {
            _id
            name
            description
            categoryId
            images
            measureType
            maxValue
            minValue
            order
            dependsOn
        }
    }
`;

const configList = `
    query configs($categoryId: String) {
      configs(categoryId: $categoryId) {
            _id
            name
            description
            categoryId
            images
            measureType
            maxValue
            minValue
            order
            dependsOn
        }
    }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  configDetails,
  configChildCategories,
  configCategories,
  configParentCategories,
  configCategoryDetails,
  configList,
};
