import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Input,
  Pagination,
  Row,
  SelectPicker,
} from 'rsuite';
import bgImg from '../../../assets/img/page-header-bg.png';
import Head from '../../common/components/Head';
import routerUtils from '../../common/routerUtils';
import {
  jobTypes,
  locations,
  workingHoursData,
} from '../../dashboard/constants';
class JobList extends React.Component {
  constructor(props) {
    super(props);

    const qp = this.props.queryParams;
    this.filter = {
      title: qp && qp.title ? qp.title : '',
      jobType: qp && qp.jobType ? qp.jobType : '',
      workingHours: qp && qp.workingHours ? qp.workingHours : '',
      location: qp && qp.location ? qp.location : '',
      page: qp && qp.page ? qp.page : '',
      perPage: qp && qp.perPage ? qp.perPage : 2,
    };

    this.state = {
      title: '',
    };
  }

  onRemove = (_id) => {
    confirmAlert({
      title: 'Та устгахдаа итгэлтэй байна уу?',
      buttons: [
        {
          label: 'Устгах',
          onClick: (e) => this.props.careerRemove(_id),
        },
        {
          label: 'Болих',
        },
      ],
    });
  };

  onFilterChange = (type, value) => {
    this.filter[type] = value;
    this.setState({ [type]: value });
  };

  onSearchClick = () => {
    const { history } = this.props;

    routerUtils.setParams(history, this.filter);
  };

  handleChangePage = (e) => {
    const { history } = this.props;
    this.filter.page = e;
    this.filter.perPage = (e - 1) * 1;

    routerUtils.setParams(history, this.filter);
  };

  renderJob = (
    _id,
    title,
    description,
    salary,
    company,
    phone,
    workingHours,
    location,
    jobType,
    images,
    type
  ) => {
    return (
      <div className='job-item'>
        <Row gutter={40}>
          {images === 'no-image' ? (
            ''
          ) : (
            <Col md={5}>
              <div
                className='bg-image thumbnail'
                style={{
                  background: `url(${images})`,
                }}
              ></div>
            </Col>
          )}
          <Col md={14}>
            <div className='desc'>
              <h4>{title}</h4>
              <p>{description}</p>
              <div className='details'>
                <span>
                  Төрөл: <b>{type}</b>
                </span>
                <span>
                  Зарлагч байгууллага: <b>{company}</b>
                </span>
                <span>
                  Утас: <b>{phone}</b>
                </span>
                <span>
                  Ажлын цаг: <b>{workingHours}</b>
                </span>
                <span>
                  Байршил: <b>{location}</b>
                </span>
                <span>
                  Салбар: <b>{jobType}</b>
                </span>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className='salary'>
              <h4>{salary}</h4>
            </div>
          </Col>
        </Row>
        {this.props.currentUser && this.props.currentUser?.role === 'admin' && (
          <Button className='career-remove' onClick={() => this.onRemove(_id)}>
            <i className='fas fa-trash'></i>
          </Button>
        )}
      </div>
    );
  };
  render() {
    const { careers, head } = this.props;
    const { title, jobType, workingHours, location } = this.filter;
    return (
      <div className='job-list'>
        <Head title='Ажлын зар' />
        <div
          className={`bg-image page-header relative job-header`}
          style={{
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.25) 50%), url(${
              head ? head.pictures[0] : bgImg
            })`,
          }}
        >
          <div className='career-filter'>
            <Container className='min'>
              <h2>{head ? head.title : 'Ажлын зар'}</h2>
              <div className='filter'>
                <Row gutter={36}>
                  <Col md={5} sm={12}>
                    <SelectPicker
                      searchable={false}
                      placeholder='Чиглэл хайх'
                      data={jobTypes}
                      defaultValue={jobType ? jobType : ''}
                      onChange={this.onFilterChange.bind(this, 'jobType')}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <SelectPicker
                      searchable={false}
                      placeholder='Ажлын цаг'
                      data={workingHoursData}
                      defaultValue={workingHours ? workingHours : ''}
                      onChange={this.onFilterChange.bind(this, 'workingHours')}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <SelectPicker
                      searchable={false}
                      placeholder='Байршил'
                      data={locations}
                      defaultValue={location ? location : ''}
                      onChange={this.onFilterChange.bind(this, 'location')}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <div className='search relative'>
                      <Input
                        placeholder='Хайх'
                        value={title ? title : ''}
                        onChange={this.onFilterChange.bind(this, 'title')}
                      />
                      <i
                        className='fas fa-search'
                        onClick={() => this.onSearchClick()}
                      />
                    </div>
                  </Col>
                  <Col md={1} sm={12}>
                    <div className='v-line'></div>
                  </Col>
                  <Col md={6} sm={24}>
                    <Link to='/job-form' className='add flex'>
                      <span>Зар нэмэх</span> <i className='fas fa-plus' />
                    </Link>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
        <section>
          <Container className='min'>
            {careers.reverse().map((career) => (
              <div key={career._id}>
                {this.renderJob(
                  career._id,
                  career.title,
                  career.description,
                  career.salary,
                  career.company,
                  career.phone,
                  career.workingHours,
                  career.location,
                  career.jobType,
                  career.images,
                  career.type
                )}
              </div>
            ))}
            <Pagination
              prev
              last
              next
              first
              size='lg'
              pages={1}
              activePage={parseInt(this.props.queryParams.page) || 1}
              onSelect={(e) => this.handleChangePage(e)}
            />
          </Container>
        </section>
      </div>
    );
  }
}

export default JobList;
