import React from 'react';
import {
  Button,
  ButtonToolbar,
  Form,
  FormGroup,
  HelpBlock,
  FormControl,
  ControlLabel,
  SelectPicker
} from 'rsuite';
import Uploader from '../../../../common/components/Uploader';
import { Avatar } from 'antd';
import { imgTypes } from '../../../constants';
export default class ImageForm extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props.detail);
    this.state = {
      formValue: props.detail || {},
      type: props.detail.type || '',
      images: props.detail.images || []
    };
  }
  onFormChange = formValue => {
    this.setState({ formValue });
  };

  onTypeChange = value => {
    this.setState({ type: value });
  };

  onFileChange = files => {
    this.setState({ images: files });
  };

  handleChange(name, files) {
    this.setState({ [name]: files });
  }

  onSubmit = () => {
    const { formValue, type, images } = this.state;
    const { detail, imageEdit, imageAdd } = this.props;

    const params = {
      doc: {
        ...formValue,
        type: type,
        images: images ? images[0].url : []
      }
    };

    if (detail._id) {
      return imageEdit(params);
    }

    return imageAdd(params);
  };

  renderFileUpload(name, label) {
    const files = this.state[name];
    const fileList = files || [];

    return (
      <div>
        <Uploader
          defaultFileList={fileList}
          onChange={e => this.handleChange(name, e)}
          disabled={false}
          label={label}
        />
        {fileList.length > 0 && fileList[0].url && (
          <Avatar shape="square" size={64} src={fileList[0].url} />
        )}
      </div>
    );
  }

  render() {
    const { type, formValue, images } = this.state;
    return (
      <Form
        onChange={formValue => {
          this.onFormChange(formValue);
        }}
        formValue={formValue}
      >
        <FormGroup>
          <ControlLabel>Зураг</ControlLabel>
          {this.renderFileUpload('images', 'Зураг оруулах')}
          <HelpBlock>Заавал бөглөх</HelpBlock>
        </FormGroup>
        {images && (
          <div className="ant-avatar">
            <img src={images} alt="" />
          </div>
        )}
        <FormGroup>
          <ControlLabel>Зурагны тайлбар</ControlLabel>
          <FormControl name="description" />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Зурагны link</ControlLabel>
          <FormControl name="url" />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Төрөл</ControlLabel>
          <SelectPicker
            name="type"
            data={imgTypes}
            style={{ width: 224 }}
            searchable={false}
            onChange={value => this.onTypeChange(value)}
            defaultValue={type ? type : undefined}
          />
          <HelpBlock>Заавал бөглөх</HelpBlock>
        </FormGroup>
        <FormGroup>
          <ButtonToolbar>
            <Button appearance="primary" onClick={this.onSubmit}>
              Submit
            </Button>
            <Button appearance="default" onClick={this.props.onCancel}>
              Cancel
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    );
  }
}
