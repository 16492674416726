import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Container } from 'rsuite';
import { LinedTitle } from '../../common/components/LinedTitle';
import { NewsCard } from '../../common/components/NewsCard';

class NewsList extends React.Component {
  render() {
    return (
      <div className='blog-lists'>
        <section>
          <Container>
            <LinedTitle title='Мэдээ, мэдээлэл' />
            <Row gutter={50}>
              {this.props.contents &&
                this.props.contents.map((vlog) => (
                  <Col xs={12} sm={6} md={4}>
                    <div>
                      <span>
                        <i className='fas fa-calendar' />
                        {moment(vlog.createdAt, 'x').format('YYYY-MM-DD')}
                      </span>
                    </div>
                    <NewsCard data={vlog} type='news-card' />
                  </Col>
                ))}
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default NewsList;
