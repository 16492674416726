import React from 'react';
import { withRouter } from 'react-router-dom';
import Advice from '../components/Advice';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { queries } from '../graphql';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
class AdviceContainer extends React.Component {
  render() {
    const { contentListQuery } = this.props;

    if (contentListQuery && contentListQuery.loading) {
      return null;
    }

    const contents = contentListQuery.contents || [];

    const updatedProps = {
      ...this.props,
      contents,
    };

    return <Advice {...updatedProps} />;
  }
}
const propTypes = {
  contentListQuery: PropTypes.object.isRequired,
};
AdviceContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.contentListQuery), {
      name: 'contentListQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            title: queryParams.title,
            category: 'law',
          },
        };
      },
    })
  )(AdviceContainer)
);
