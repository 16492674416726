import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
import { mutations, queries } from '../graphql';
import ContentForm from '../components/ContentForm';
import { Alert } from 'rsuite';

class ContentFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.contentAdd = this.contentAdd.bind(this);
    this.contentEdit = this.contentEdit.bind(this);
  }

  contentAdd(variables) {
    const { contentAdd } = this.props;
    contentAdd({ variables })
      .then(() => {
        this.props.onCancel();
        Alert.success('Амжилттай нэмлээ');
      })
      .catch((err) => {
        console.log(err);
        Alert.error(err);
      });
  }

  contentEdit(variables) {
    const { contentEdit } = this.props;

    contentEdit({ variables })
      .then(() => {
        this.props.onCancel();
         Alert.success('Амжилттай заслаа');
      })
      .catch((err) => {
        console.log(err);
        Alert.error(err);
      });
  }

  render() {
    const { contentDetailQuery } = this.props;

    if (contentDetailQuery && contentDetailQuery.loading) {
      return <></>;
    }

    const detail = contentDetailQuery
      ? contentDetailQuery.contentDetail || {}
      : {};

    const updatedProps = {
      ...this.props,
      contentAdd: this.contentAdd,
      contentEdit: this.contentEdit,
      detail,
    };

    return <ContentForm {...updatedProps} />;
  }
}

ContentFormContainer.propTypes = {
  id: PropTypes.string,
  contentDetailQuery: PropTypes.object,
  contentAdd: PropTypes.func,
  contentEdit: PropTypes.func,
};

export default compose(
  graphql(gql(queries.contentDetailQuery), {
    name: 'contentDetailQuery',
    skip: (props) => !props.id,
    options: (props) => {
      return {
        variables: {
          _id: props.id,
        },
      };
    },
  }),

  graphql(gql(mutations.contentAdd), {
    name: 'contentAdd',
    options: ({ doc }) => ({
      variables: {
        doc,
      },
    }),
  }),

  graphql(gql(mutations.contentEdit), {
    name: 'contentEdit',
    options: ({ _id, doc }) => ({
      variables: {
        _id,
        doc,
      },
    }),
  })
)(ContentFormContainer);
