import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Dropdown, Row } from "rsuite";
import english from "../../../assets/img/english.png";
import logo from "../../../assets/img/logo.png";
import ThemeContext from "../../../ThemeContext";
import ThemeSwitcher from "../../common/components/ThemeSwitcher";
import Search from "../containers/Search";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
  }
  static contextType = ThemeContext;

  logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    window.location.href = "/";
  };

  renderLogin = () => {
    const { currentUser } = this.props;

    const content = currentUser ? (
      <Dropdown
        className="admin-dropdown"
        title={<i className="fas fa-user"></i>}
        activeKey="x"
      >
        <li className="rs-dropdown-item">
          <a
            href="/dashboard/mark-categories"
            className="rs-dropdown-item-content"
          >
            Админ
          </a>
        </li>
        <Dropdown.Item onClick={this.logOut}>Гарах</Dropdown.Item>
      </Dropdown>
    ) : (
      <div className="user">
        <Link to="/login">
          <i className="fas fa-user"></i> <span>Нэвтрэх</span>
        </Link>
      </div>
    );

    return <>{content}</>;
  };
  openMenu = () => {
    this.setState({ menu: !this.state.menu });
  };
  closeMenu = () => {
    this.setState({ menu: false });
  };
  render() {
    const { menu } = this.state;
    return (
      <div className="header">
        <header>
          <Container>
            <Row className="flex-v-center">
              <Col md={2} xs={12}>
                <Link to="/">
                  <img className="logo" src={logo} alt="logo" />
                </Link>
              </Col>
              <Col md={12} xs={12}>
                <div className="site-desc">
                  <div className="h-text title">
                    <h1>UPMIL</h1>
                  </div>
                  <div className="h-text slogan-text">
                    <p>Таны эрхэм зэрэг, нандин чанарт …</p>
                  </div>
                </div>
              </Col>
              <Col md={10} xs={24}>
                <div className="control">
                  <div className="burger-menu d-hidden" onClick={this.openMenu}>
                    <i className="fas fa-bars" />
                  </div>
                  <Search />
                  <ThemeSwitcher />

                  {this.renderLogin()}
                  <div
                    className="lang bg-image"
                    style={{ background: `url(${english})` }}
                  ></div>
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        <div className="navbar m-hidden">
          <Container>
            <div className="nav-wrapper">
              <Link to="/">Нүүр</Link>
              <a className="dropdown" href="/intro">
                Танилцуулга <i className="fas fa-angle-down"></i>
                <div className="dropdown-list">
                  <Link to="/greeting">Байгууллагын мэндчилгээ</Link>
                  <Link to="/timeline">Түүхэн замнал</Link>
                  <Link to="/about">Бидний тухай</Link>
                  <Link to="/activity">Үйл ажиллагаа</Link>
                </div>
              </a>
              <a className="dropdown" href="/mark-list">
                Хүртээмжтэй орчин <i className="fas fa-angle-down"></i>
                <div className="dropdown-list">
                  <Link to="/mark-map">Хүртээмжтэй газар хайх</Link>
                  <Link to="/mark-report">Үнэлгээний тайлан</Link>
                </div>
              </a>
              <a className="dropdown" href="/news-main">
                Мэдээ & Блог <i className="fas fa-angle-down"></i>
                <div className="dropdown-list">
                  <Link to="/news">Мэдээ, мэдээлэл</Link>
                  <Link to="/blogs">Влог & Блог</Link>
                  <Link to="/activity-reports">Үйл ажиллагааны тайлан</Link>
                  <Link to="/faq">Түгээмэл асуулт хариулт</Link>
                </div>
              </a>
              <a className="dropdown" href="/advice">
                Зөвлөгөө <i className="fas fa-angle-down"></i>
                <div className="dropdown-list">
                  <Link to="/law">Хууль, эрх зүй</Link>
                  <Link to="/independence">Бие даан амьдрах</Link>
                  <Link to="/infrastructure">
                    Дэд бүтэц, барилга байгууламжийн хүртээмж
                  </Link>
                  <Link to="/employment">Хөдөлмөр эрхлэлт</Link>
                  <Link to="/education">Тэгш хамран сургах боловсрол</Link>
                </div>
              </a>
              <a className="dropdown" href="/job-list">
                Ажлын байр зуучлал <i className="fas fa-angle-down"></i>
                <div className="dropdown-list">
                  <Link to="/job-list?type=Ажилд авна">Ажилд авна</Link>
                  <Link to="/job-list?type=Ажил хайж байна">
                    Ажил хайж байна
                  </Link>
                </div>
              </a>
              <Link to="/contact">Холбоо барих</Link>
            </div>
          </Container>
        </div>

        <div
          className="navbar mobile-nav d-hidden"
          style={menu ? { right: "0" } : { right: "-300px" }}
        >
          <div className="nav-wrapper">
            <Link to="/">Нүүр</Link>
            <Link className="dropdown" to="/intro">
              Танилцуулга
              <div className="dropdown-list">
                <Link to="/greeting">Байгууллагын мэндчилгээ</Link>
                <Link to="/timeline">Түүхэн замнал</Link>
                <Link to="/about">Бидний тухай</Link>
                <Link to="/activity">Үйл ажиллагаа</Link>
              </div>
            </Link>
            <Link className="dropdown" to="/mark-list">
              Хүртээмжтэй орчин
              <div className="dropdown-list">
                <Link to="/mark-map">Хүртээмжтэй газар хайх</Link>
                <Link to="/mark-report">Үнэлгээний тайлан</Link>
              </div>
            </Link>
            <Link className="dropdown" to="/news-main">
              Мэдээ & Блог
              <div className="dropdown-list">
                <Link to="/news-main">Мэдээ, мэдээлэл</Link>
                <Link to="/blogs">Влог & Блог</Link>
                <Link to="/activity-reports">Үйл ажиллагааны тайлан</Link>
                <Link to="/faq">Түгээмэл асуулт хариулт</Link>
              </div>
            </Link>
            <Link className="dropdown" to="/advice">
              Зөвлөгөө
              <div className="dropdown-list">
                <Link to="/law">Хууль, эрх зүй</Link>
                <Link to="/independence">Бие даан амьдрах</Link>
                <Link to="/infrastructure">
                  Дэд бүтэц, барилга байгууламжийн хүртээмж
                </Link>
                <Link to="/employment">Хөдөлмөр эрхлэлт</Link>
                <Link to="/education">Тэгш хамран сургах боловсрол</Link>
              </div>
            </Link>
            <Link className="dropdown" to="/job-list">
              Ажлын байр зуучлал
              <div className="dropdown-list">
                <Link to="/job-list?type=Ажлын зар">Ажлын зар</Link>
                <Link to="/job-list?type=Ажил хайж байна">Ажил хайж байна</Link>
              </div>
            </Link>
            <Link to="/contact">Холбоо барих</Link>
          </div>
        </div>

        <div className="sticky-social">
          <div className="fb">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.facebook.com/MILC.TugeemelKhugjil"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
          </div>
          <div className="tw">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://twitter.com/UPMILC"
            >
              <i className="fab fa-twitter"></i>
            </a>
          </div>
          <div className="yt">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.youtube.com/channel/UCrKReCimedn9WeJilV_gNmQ"
            >
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
