const feedbackAdd = `
    mutation feedbackAdd($doc: FeedbackInput){
        feedbackAdd(doc: $doc){
            _id
        }
    }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  feedbackAdd,
};
