import React from 'react';
import { Table, Button, Icon, IconButton } from 'rsuite';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';

const { Column, HeaderCell, Cell } = Table;

const rowKey = '_id';
const ExpandCell = ({
  rowData,
  dataKey,
  expandedRowKeys,
  onChange,
  ...props
}) => (
  <Cell {...props}>
    <IconButton
      size='md'
      appearance='subtle'
      onClick={() => {
        onChange(rowData);
      }}
      icon={
        <Icon
          icon={
            expandedRowKeys.some((key) => key === rowData[rowKey])
              ? 'minus-square-o'
              : 'plus-square-o'
          }
        />
      }
    />
  </Cell>
);

export default class FeedbackList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      data: props.feedbacks || {},
      show: false,
      expandedRowKeys: [],
      id: props.feedbacks || {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  close() {
    this.setState({ show: false });
  }

  open() {
    this.setState({ show: true });
  }

  handleChange(value) {
    this.setState({ text: value });
  }

  renderDate = (createdAt) => {
    return moment(createdAt, 'x').format('YYYY/MM/DD - HH:mm');
  };

  onRemove = (_id) => {
    confirmAlert({
      title: 'Та устгахдаа итгэлтэй байна уу?',
      buttons: [
        {
          label: 'Устгах',
          onClick: (e) => this.props.feedbackRemove(_id),
        },
        {
          label: 'Болих',
        },
      ],
    });
  };

  renderActions = (_id) => {
    return (
      <span className='action-wrapper'>
        <Button
          color='red'
          appearance='ghost'
          onClick={() => this.onRemove(_id)}
        >
          <i className='fas fa-trash'></i>
        </Button>
      </span>
    );
  };

  handleExpanded = (rowData, dataKey) => {
    const { expandedRowKeys } = this.state;
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }
    this.setState({
      expandedRowKeys: nextExpandedRowKeys,
    });
  };

  render() {
    const { expandedRowKeys, data } = this.state;
    return (
      <div>
        <h4 className='d-title'>Feedback</h4>
        <Table
          height={400}
          data={data}
          rowExpandedHeight={100}
          rowKey={rowKey}
          expandedRowKeys={expandedRowKeys}
          renderRowExpanded={(rowData) => {
            return <div>{rowData.feedback}</div>;
          }}
        >
          <Column width={70} align='center'>
            <HeaderCell>#</HeaderCell>
            <ExpandCell
              dataKey='_id'
              expandedRowKeys={expandedRowKeys}
              onChange={this.handleExpanded}
            />
          </Column>
          <Column width={250}>
            <HeaderCell>Нэр</HeaderCell>
            <Cell dataKey='name' />
          </Column>
          <Column width={250}>
            <HeaderCell>Email</HeaderCell>
            <Cell dataKey='email' />
          </Column>
          <Column width={220}>
            <HeaderCell>Огноо</HeaderCell>
            <Cell dataKey='createdAt'>
              {(rowData) => this.renderDate(rowData.createdAt)}
            </Cell>
          </Column>
          <Column width={140} fixed='right'>
            <HeaderCell>Үйлдэл</HeaderCell>

            <Cell>{(rowData) => this.renderActions(rowData._id)}</Cell>
          </Column>
        </Table>
      </div>
    );
  }
}
