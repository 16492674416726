import React from 'react';
import { Route } from 'react-router-dom';
import Search from './containers/Search';
import queryString from 'query-string';
const routes = () => [
  <Route
    path="/search"
    exact
    key="/search"
    component={({ location, history, match }) => {
      const queryParams = queryString.parse(location.search);
      const id = match.params.id;
      return <Search queryParams={queryParams} id={id} history={history} />;
    }}
  />,
];

export default routes;
