import React from 'react';
import PropTypes from 'prop-types';
import { Upload, message } from 'antd';
import { uploadUrl } from '../constants';
class JobUploader extends React.Component {
  constructor(props) {
    super(props);

    const defaultFileList = this.props.defaultFileList || [];
    const fileList = defaultFileList.map((file, index) => ({
      uid: index,
      ...file,
    }));

    this.state = { fileList };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { multiple, onChange } = this.props;
    const { file, fileList } = e;

    if (file.status === 'error') {
      return message.error(`${file.name} файл хуулхад алдаа гарлаа`);
    }

    if (file.status === 'removed') {
      this.setState({ fileList });
      return onChange(null);
    }

    if (fileList.length > 0) {
      if (multiple) {
        this.setState({ fileList });
        return onChange(fileList.map((f) => ({ name: f.name, url: f.response || f.url })));
      } else {
        this.setState({ fileList: [file] });
        return onChange([{ name: file.name, url: file.response }]);
      }
    }

    return null;
  }

  render() {
    const { beforeUpload, onPreview } = this.props;
    const { fileList } = this.state;

    const extendedProps = {
      ...this.props,
      action: uploadUrl,
      onChange: this.onChange,
      fileList: this.state.fileList,
      onPreview,
      beforeUpload,
    };

    const uploadButton = (
      <div className="job-upload">
        <div style={{ marginTop: 8 }}>
          Зураг оруулах <br />
          /max: 1mb/
        </div>
      </div>
    );
    return (
      <div className="job-uploader">
        <Upload {...extendedProps}>{fileList && fileList.length ? <img style={{ height: 'auto', width: '160px' }} src={fileList[0].response} alt="avatar" /> : uploadButton}</Upload>
      </div>
    );
  }
}

JobUploader.propTypes = {
  defaultFileList: PropTypes.array,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default JobUploader;
