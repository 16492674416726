import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Container, Input, SelectPicker } from 'rsuite';
import bgImg from '../../../assets/img/page-header-bg.png';
import ModalTrigger from '../../common/components/ModalTrigger';
import { lawCategories } from '../../dashboard/constants';
import ContentDetail from '../containers/ContentDetail';
import LawDetail from '../containers/LawDetail';
class Law extends React.Component {
  constructor(props) {
    super(props);

    const qp = props.queryParams;

    this.filter = {
      title: qp && qp.title ? qp.title : '',
      category: qp && qp.category ? qp.category : '',
    };
    this.state = {
      searchValue: '',
      selectedCategory: '',
      show: false,
    };
  }

  showModal = () => {
    this.setState({
      show: true,
    });
  };

  handleCancel = (e) => {
    this.setState({
      show: false,
    });
  };

  renderLaw(data) {
    return (
      <Col md={4} sm={6} xs={12} key={data._id}>
        <ModalTrigger
          title={data.title}
          date={moment(data.date || data.createdAt, 'x').format('YYYY/MM/DD')}
          category={data.category}
          file={data.file}
          law={true}
          size='lg'
          content={(props) => <LawDetail {...props} id={data._id} />}
        />
      </Col>
    );
  }
  renderContent(content) {
    return (
      <Col md={4} sm={6} xs={12} key={content._id}>
        <ModalTrigger
          title={content.title}
          date={moment(content.date || content.createdAt, 'x').format(
            'YYYY/MM/DD'
          )}
          category={content.category}
          law={true}
          size='lg'
          content={(props) => <ContentDetail {...props} id={content._id} />}
        />
      </Col>
    );
  }

  handleSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };

  handleCategory = (value) => {
    this.setState({
      selectedCategory: value,
    });
  };

  handleSubmit = (e) => {
    const { searchValue, selectedCategory } = this.state;
    e.preventDefault();
    this.props.history.push(
      '/law?title=' + searchValue + '&category=' + selectedCategory
    );
  };
  render() {
    const { searchValue } = this.state;
    const { head, laws } = this.props;
    const filteredLaws = laws.filter(
      (law) => law.category !== 'activity-reports'
    );
    return (
      <div className='job-list'>
        <div
          className={`bg-image page-header relative job-header`}
          style={{
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.25) 50%), url(${
              head ? head.pictures[0] : bgImg
            })`,
          }}
        >
          <div className='career-filter'>
            <Container className='min'>
              <Row className='flex-v-center'>
                <Col xs={12} sm={6}>
                  <h2>{head ? head.title : 'Хууль, Эрх зүй'}</h2>
                </Col>
                <Col xs={12} sm={6}>
                  <div className='filter'>
                    <Row gutter={36}>
                      <Col md={7} sm={6}>
                        <SelectPicker
                          searchable={false}
                          placeholder='Ангилал сонгох'
                          data={lawCategories}
                          onChange={this.handleCategory}
                        />
                      </Col>
                      <Col md={5} sm={6}>
                        <div className='search relative'>
                          <form onSubmit={this.handleSubmit}>
                            <Input
                              onChange={this.handleSearch}
                              value={searchValue}
                              placeholder={this.filter.title || 'Хайх'}
                            />
                            <i
                              onClick={this.handleSubmit}
                              className='fas fa-search'
                            />
                          </form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <section>
          <Container className='min'>
            <Row gutter={50}>
              {filteredLaws.reverse().map((law) => this.renderLaw(law))}
              {this.props.contents
                .reverse()
                .map((content) => this.renderContent(content))}
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default Law;
