import React, { Component } from 'react';
import { LawDetail, LawForm } from '../containers';
import ModalTrigger from '../../../../common/components/ModalTrigger';
import { Button, Table, ButtonToolbar, Pagination } from 'rsuite';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';

const { Column, HeaderCell, Cell } = Table;

class Law extends Component {
  constructor(props) {
    super(props);

    const qp = props.queryParams;

    this.state = {
      role: ['Админ'],
      category: qp && qp.category ? qp.category : '',
      endDate: qp && qp.endDate ? qp.endDate : '',
    };

    this.filter = {
      beginDate: qp && qp.beginDate ? qp.beginDate : '',
      endDate: qp && qp.endDate ? qp.endDate : '',
      category: qp && qp.category ? qp.category : '',
      title: qp && qp.title ? qp.title : '',
      importand: qp && qp.importand ? qp.importand : '',
    };
  }

  onRemove = (_id) => {
    confirmAlert({
      title: 'Та устгахдаа итгэлтэй байна уу?',
      buttons: [
        {
          label: 'Устгах',
          onClick: (e) => this.props.lawRemove(_id),
        },
        {
          label: 'Болих',
        },
      ],
    });
  };

  renderDate = (createdAt) => {
    return moment(createdAt, 'x').format('YYYY-MM-DD');
  };

  renderFile = (file) => {
    return (
      <a href={file} download>
        Татах
      </a>
    );
  };

  renderActions(id, title) {
    return (
      <span className="action-wrapper">
        <ModalTrigger iconClassName={'fas fa-edit'} btnColor={'cyan'} size="lg" content={(props) => <LawForm {...props} id={id} />} />
        &nbsp;
        <Button color="red" appearance="ghost" onClick={(e) => this.onRemove(id)}>
          <i className="fas fa-trash"></i>
        </Button>
      </span>
    );
  }

  renderDetailModal(id, title) {
    return (
      <ModalTrigger
        title={<h1 Style="color: black;font-size: 15px;font-weight: bold;">{title}</h1>}
        className="more-btn"
        buttonText="Дэлгэрэнгүй"
        type=""
        content={(props) => <LawDetail {...props} id={id} />}
      />
    );
  }

  renderDownload(file) {
    if (file[0]) {
      return (
        <a href={file[0]} className="ant-btn" download={file[0]}>
          Татах
        </a>
      );
    } else {
      return (
        <a disabled={true} href={file[0]} className="ant-btn">
          Татах
        </a>
      );
    }
  }

  render() {
    const { laws } = this.props;

    this.filter.importand = this.filter.importand === 'true';

    return (
      <div>
        <h4 className="d-title">{this.state.category && this.state.category === 'activity-reports' ? 'Үйл ажилаагааны тайлан' : 'Хууль, эрх зүй'}</h4>
        <div className="admin-bar">
          <ButtonToolbar>
            <ModalTrigger
              btnText={'Нэмэх'}
              btnClassName={'create - btn'}
              iconClassName={'fas fa-plus'}
              size="lg"
              btnColor={'blue'}
              content={(props) => <LawForm {...props} />}
            ></ModalTrigger>
          </ButtonToolbar>
        </div>
        <Table height={800} data={laws.reverse()}>
          <Column width={360}>
            <HeaderCell>Гарчиг</HeaderCell>
            <Cell dataKey="title" />
          </Column>
          <Column width={360}>
            <HeaderCell>Огноо</HeaderCell>
            <Cell dataKey="date">{(rowData) => this.renderDate(rowData.date)}</Cell>
          </Column>
          <Column width={200}>
            <HeaderCell>Файл</HeaderCell>
            <Cell dataKey="file">{(rowData) => this.renderFile(rowData.file)}</Cell>
          </Column>
          <Column width={200}>
            <HeaderCell>Ангилал</HeaderCell>
            <Cell dataKey="category" />
          </Column>
          <Column width={140} fixed="right">
            <HeaderCell>Үйлдэл</HeaderCell>

            <Cell>{(rowData) => this.renderActions(rowData._id)}</Cell>
          </Column>
        </Table>
        <Pagination
          prev
          last
          next
          first
          size="lg"
          pages={1}
          activePage={parseInt(this.props.queryParams.page) || 1}
          onSelect={(e) => this.handleChangePage(e)}
        />
      </div>
    );
  }
}

export default Law;
