import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Alert } from 'rsuite';
import ImageList from '../components/ImageList';
import { mutations, queries } from '../graphql';
class ImageListContainer extends React.Component {
  imageRemove = (variables) => {
    const { imageRemoveMutation } = this.props;
    imageRemoveMutation({ variables: { _id: variables } })
      .then(() => {
        Alert.success('Амжилттай устгалаа');
      })
      .catch((err) => {
        Alert.error(err.message);
      });
  };

  render() {
    const { imageListQuery } = this.props;

    if (imageListQuery && imageListQuery.loading) {
      return null;
    }
    const images = imageListQuery.images || [];

    const updatedProps = {
      ...this.props,
      images,
      imageRemove: this.imageRemove,
    };

    return <ImageList {...updatedProps} />;
  }
}
const propTypes = {
  imageListQuery: PropTypes.object.isRequired,
};
ImageListContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.imageListQuery), {
      name: 'imageListQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            type: queryParams.type,
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        };
      },
    }),
    graphql(gql(mutations.imageRemove), {
      name: 'imageRemoveMutation',
      options: () => ({
        refetchQueries: ['images'],
      }),
    })
  )(ImageListContainer)
);
