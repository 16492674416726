import React, { useContext } from 'react';
import Footer from './Footer';
import Header from './Header';
import ThemeContext from '../../../ThemeContext';
const Layout = ({ children, currentUser, images, contact }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={`layout ${theme}`}>
      <Header currentUser={currentUser} />
      {children}
      <Footer images={images} currentUser={currentUser} contact={contact} />
    </div>
  );
};

export default Layout;
