import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Alert } from 'rsuite';
import Comment from '../components/Comment';
import { mutations, queries } from '../graphql';
class CommentContainer extends React.Component {
  constructor(props) {
    super(props);

    this.commentRemove = this.commentRemove.bind(this);
  }

  commentRemove(variables) {
    const { commentRemove, commentsQuery } = this.props;
    commentRemove({ variables })
      .then(() => {
        Alert.success('Амжилттай устгалаа');
        commentsQuery.refetch();
        // window.location.reload(false);
      })
      .catch(err => {
        Alert.error(err);
      });
  }
  render() {
    const { commentsQuery } = this.props;

    if (commentsQuery && commentsQuery.loading) {
      return <></>;
    }

    const comments = commentsQuery ? commentsQuery.comments || [] : [];
    const updatedProps = {
      ...this.props,
      commentRemove: this.commentRemove,
      comments
    };

    return <Comment {...updatedProps} />;
  }
}
const propTypes = {
  commentRemove: PropTypes.func,
  commentsQuery: PropTypes.object
};

CommentContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(mutations.commentRemove), {
      name: 'commentRemove'
    }),
    graphql(gql(queries.commentsQuery), {
      name: 'commentsQuery'
    })
  )(CommentContainer)
);
