import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Home from './containers/Home.js';
import queryString from 'query-string';

const routes = (currentUser) => [
  <Route
    path="/"
    exact
    key="/"
    component={({ location }) => {
      const queryParams = queryString.parse(location.search);
      return <Home queryParams={queryParams} currentUser={currentUser} />;
    }}
  />,
];

routes.propTypes = {
  currentUser: PropTypes.object,
};

export default routes;
