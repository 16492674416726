import React from 'react';
import {
  Button,
  ButtonToolbar,
  Modal,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Uploader,
  Icon,
  SelectPicker,
} from 'rsuite';

export default class DashboardMark extends React.Component {
  constructor(props) {
    super(props);

    const fakeData = [
      {
        id: 1,
        username: 'uuganbayar',
        lastName: 'Бат',
        firstName: 'Ууганаа',
        number: '12345678',
        email: 'ahrteherrher@egs.com',
        user: 'expert',
      },
      {
        id: 2,
        username: 'uuganbayar',
        lastName: 'Бат',
        firstName: 'Ууганаа',
        number: '12345678',
        email: 'ahrteherrher@egs.com',
        user: 'expert',
      },
      {
        id: 3,
        username: 'uuganbayar',
        lastName: 'Бат',
        firstName: 'Ууганаа',
        number: '12345678',
        email: 'ahrteherrher@egs.com',
        user: 'expert',
      },
    ];
    this.state = {
      data: fakeData,
      show: false,
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }

  render() {
    const data = [
      {
        label: 'Нэгж уртаар (см)',
        value: '1',
      },
      {
        label: 'Хувь хэмжилтээр (%)',
        value: '2',
      },
      {
        label: 'Хариултаар (Тийм/Үгүй)',
        value: '3',
      },
    ];
    return (
      <div>
        <h4 className='d-title'>Үнэлгээний шалгуурууд</h4>
        <div className='admin-bar'>
          <div className='admin-filter'></div>
          <ButtonToolbar>
            <Button color='blue' onClick={this.open} className='create-btn'>
              <i className='fas fa-plus'></i> Үнэлгээний шалгуур нэмэх
            </Button>
          </ButtonToolbar>
        </div>
        <Modal size='xs' show={this.state.show} onHide={this.close}>
          <Modal.Header>
            <Modal.Title>Ангилал нэмэх</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup>
                <ControlLabel>Таөйлбар</ControlLabel>
                <FormControl name='name' />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Шалгуурын хэмжигдэхүүн</ControlLabel>
                <FormControl name='name' />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Хэмжилтийн төрөл сонгох</ControlLabel>
                <SelectPicker data={data} style={{ width: 300 }} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Зураг</ControlLabel>
                <Uploader
                  multiple
                  listType='picture'
                  action='//jsonplaceholder.typicode.com/posts/'
                >
                  <button>
                    <Icon icon='camera-retro' size='lg' />
                  </button>
                </Uploader>
              </FormGroup>
              <FormGroup>
                <ButtonToolbar>
                  <Button appearance='primary'>Хадгалах</Button>
                  <Button appearance='default' onClick={this.close}>
                    Цуцлах
                  </Button>
                </ButtonToolbar>
              </FormGroup>
            </Form>
          </Modal.Body>
        </Modal>
        <ul className='mark-cats'>
          <li>
            <div className='cat-row'>
              <strong>Гарц</strong>
              <Button onClick={this.open} className='edit-btn'>
                <i className='fas fa-edit'></i>
              </Button>
            </div>
            <ul className='child-cats'>
              <li>
                <div className='cat-row'>
                  <strong>Гарцны налуу</strong>
                  <Button onClick={this.open} className='edit-btn'>
                    <i className='fas fa-edit'></i>
                  </Button>
                </div>
              </li>
              <li>
                <div className='cat-row'>
                  <strong>Анхааруулах товруу</strong>
                  <Button onClick={this.open} className='edit-btn'>
                    <i className='fas fa-edit'></i>
                  </Button>
                </div>
              </li>
            </ul>
          </li>
          <li>
            <div className='cat-row'>
              <strong>Гарц</strong>
              <Button onClick={this.open} className='edit-btn'>
                <i className='fas fa-edit'></i>
              </Button>
            </div>
            <ul className='child-cats'>
              <li>
                <div className='cat-row'>
                  <strong>Гарцны налуу</strong>
                  <Button onClick={this.open} className='edit-btn'>
                    <i className='fas fa-edit'></i>
                  </Button>
                </div>
              </li>
              <li>
                <div className='cat-row'>
                  <strong>Анхааруулах товруу</strong>
                  <Button onClick={this.open} className='edit-btn'>
                    <i className='fas fa-edit'></i>
                  </Button>
                </div>
                <ul className='child-cats'>
                  <li>
                    <div className='cat-row'>
                      <strong>Гарцны налуу</strong>
                      <Button onClick={this.open} className='edit-btn'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </div>
                  </li>
                  <li>
                    <div className='cat-row'>
                      <strong>Анхааруулах товруу</strong>
                      <Button onClick={this.open} className='edit-btn'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}
