import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import DashboardMark from './modules/mark/containers/DashboardMark';
import UserList from './modules/users/containers/UserList';
import ContentList from './modules/content/containers/ContentList';
import PageHeads from './modules/pageheads/containers/ContentList';
import queryString from 'query-string';
import MarkCategories from './modules/markConfig/containers/MarkCategories';
import MarkHistory from './modules/mark/containers/MarkHistory';
import FaqList from './modules/faq/containers/FaqList';
import Comment from './modules/comment/containers/Comment';
import ImageList from './modules/images/containers/ImageList';
import FeedbackList from './modules/feedback/containers/FeedbackList';
import MarkTest from './modules/mark/containers/MarkTest';
import DashboardLayout from './modules/layout/DashboardLayout';
import Laws from './modules/law/containers/Laws';
const renderComponent = (component) => {
  return <DashboardLayout>{component}</DashboardLayout>;
};

const index = () => {
  return <Redirect to={`/dashboard/mark`} />;
};

const routes = () => [
  <Route exact={true} path="/dashboard" key="root" render={index} />,
  <Route
    path="/dashboard/mark"
    exact
    key="/dashboard/mark"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return renderComponent(<DashboardMark queryParams={queryParams} history={history} />);
    }}
  />,
  <Route
    path="/dashboard/users"
    exact
    key="/dashboard/users"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return renderComponent(<UserList queryParams={queryParams} history={history} />);
    }}
  />,
  <Route
    path="/dashboard/mark-categories"
    exact
    key="/dashboard/mark-categories"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return renderComponent(<MarkCategories queryParams={queryParams} history={history} />);
    }}
  />,
  <Route
    path="/dashboard/content"
    exact
    key="/dashboard/content"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return renderComponent(<ContentList queryParams={queryParams} history={history} />);
    }}
  />,
  <Route
    path="/dashboard/mark-history"
    exact
    key="/dashboard/mark-history"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return renderComponent(<MarkHistory queryParams={queryParams} history={history} />);
    }}
  />,
  <Route
    path="/dashboard/mark-test"
    exact
    key="/dashboard/mark-test"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return renderComponent(<MarkTest queryParams={queryParams} history={history} />);
    }}
  />,
  <Route
    path="/dashboard/faq"
    exact
    key="/dashboard/faq"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return renderComponent(<FaqList queryParams={queryParams} history={history} />);
    }}
  />,
  <Route
    path="/dashboard/feedback"
    exact
    key="/dashboard/feedback"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return renderComponent(<FeedbackList queryParams={queryParams} history={history} />);
    }}
  />,
  <Route
    path="/dashboard/images"
    exact
    key="/dashboard/images"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return renderComponent(<ImageList queryParams={queryParams} history={history} />);
    }}
  />,
  <Route
    path="/dashboard/comments"
    exact
    key="/dashboard/comments"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return renderComponent(<Comment queryParams={queryParams} history={history} />);
    }}
  />,
  <Route
    path="/dashboard/pageheads"
    exact
    key="/dashboard/pageheads"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return renderComponent(<PageHeads queryParams={queryParams} history={history} />);
    }}
  />,
  <Route
    path="/dashboard/law"
    exact
    key="/dashboard/law"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return renderComponent(<Laws queryParams={queryParams} history={history} />);
    }}
  />,
];

export default routes;
