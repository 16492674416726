import React from 'react';
const Notice = () => (
  <div className='notice'>
    <h5>Санамж</h5>
    <p>
      Зарыг зарлуулсан хувь хүн болон албан байгуулллага бүрэн хариуцна. Зар
      дээр ёс бус үг болон контент оруулахгүй байна уу. Мөн таны оруулсан зар 1
      сарын дараа устах болно.
    </p>
  </div>
);
export default Notice;
