const contentListQuery = `
 query contents($title: String, $category: String, $importand: String, $page: String, $perPage: String) {
    contents(title: $title, category: $category, importand: $importand, page: $page, perPage: $perPage) {
      _id
      title
      category
      createdAt
      importand
      pictures
      pin
      content
    }
  }
`;
const contentDetailQuery = `
  query contentDetail($_id: String) {
    contentDetail(_id: $_id) {
      _id
      title
      date
      content
      category
      createdAt
      pictures
      description
      imageNote
      pin
      importand
      showCount
    }
  }
`;
const contentsCountQuery = `
  query contentsCount($beginDate: String, $endDate: String, $title: String, $importand: String){
    contentsCount(beginDate: $beginDate, endDate: $endDate, title: $title, importand: $importand)
  }
`;
const commentsQuery = `
  query comments($contentId: String){
    comments(contentId: $contentId){
      _id
      content
      contentId
      userName
    }
  }
`;
const faqListQuery = `
  query faqs {
    faqs {
      _id
      question
    }
  }
`;

const getLawsListQuery = `
 query laws($beginDate: String, $endDate: String, $category: String, $title: String, $importand: String) {
    laws(beginDate: $beginDate, endDate: $endDate, category: $category, title: $title, importand: $importand) {
      _id
      title
      date
      category
      content
      file
    }
  }
`;
const lawDetailQuery = `
  query lawDetail($_id: String) {
    lawDetail(_id: $_id) {
      _id
      title
      date
      category
      content
      file
    }
  }
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  contentListQuery,
  contentDetailQuery,
  contentsCountQuery,
  faqListQuery,
  commentsQuery,
  getLawsListQuery,
  lawDetailQuery,
};
