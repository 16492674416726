import React from 'react';
import { Button, Input, SelectPicker } from 'rsuite';
import routerUtils from '../../../common/routerUtils';
// import { categories } from '../../constants';

export default class Filter extends React.Component {
  constructor(props) {
    super(props);

    const qp = props.queryParams;

    this.filter = {
      category: qp && qp.category ? qp.category : '',
      title: qp && qp.title ? qp.title : '',
      author: qp && qp.author ? qp.author : '',
    };

    this.state = {
      title: '',
    };
  }

  onFilterChange = (type, value) => {
    this.filter[type] = value;
    this.setState({ [type]: value });
  };

  onSearchClick = () => {
    const { historyProps } = this.props;

    routerUtils.setParams(historyProps, this.filter);
  };

  render() {
    const { title, category } = this.filter;
    const { categories } = this.props;
    return (
      <div className="admin-filter">
        <Input placeholder="title" value={title ? title : ''} onChange={this.onFilterChange.bind(this, 'title')} />
        {/* <SelectPicker
          placeholder='Author'
          data={categories}
          defaultValue={author ? author : ''}
          onChange={this.onFilterChange.bind(this, 'author')}
        /> */}
        <SelectPicker placeholder="Category" data={categories} defaultValue={category ? category : undefined} onChange={this.onFilterChange.bind(this, 'category')} />
        <Button color="blue" appearance="ghost" type="submit" onClick={() => this.onSearchClick()}>
          {' '}
          <i className="fas fa-search"></i>
        </Button>
      </div>
    );
  }
}
