import React from 'react';
import { Button } from 'rsuite';
import ModalTrigger from '../../../../common/components/ModalTrigger';
import ConfigForm from '../containers/ConfigForm';
import { confirmAlert } from 'react-confirm-alert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  paddingTop: grid,
  paddingBottom: grid,
});

export default class MarkCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configs: props.configs,
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(this.state.configs, result.source.index, result.destination.index);

    this.setState({
      configs: items,
    });
  }

  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }

  onFormChange = (formValue) => {
    this.setState({ formValue });
  };

  onRemoveConfig = (_id) => {
    confirmAlert({
      title: 'Та устгахдаа итгэлтэй байна уу?',
      buttons: [
        {
          label: 'Устгах',
          onClick: (e) => this.props.configRemove(_id),
        },
        {
          label: 'Болих',
        },
      ],
    });
  };

  renderItem(config, index) {
    return (
      <Draggable key={config._id} draggableId={config._id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <li>
              <div className="cat-row">
                <strong className="grabbable">{config.name}</strong>
                <ModalTrigger
                  iconClassName={'fas fa-edit'}
                  size="xs"
                  btnColor={'cyan'}
                  content={(props) => <ConfigForm {...props} id={config._id} />}
                ></ModalTrigger>
                <Button color="red" appearance="ghost" onClick={() => this.onRemoveConfig(config._id)}>
                  <i className="fas fa-trash"></i>
                </Button>
              </div>
            </li>
          </div>
        )}
      </Draggable>
    );
  }

  render() {
    const { configs } = this.state;
    console.log(configs);
    return (
      configs && (
        <ul className="child-cats child-configs">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {configs.map((config, index) => this.renderItem(config, index))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ul>
      )
    );
  }
}
