import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
import { mutations, queries } from '../graphql';
import { Alert } from 'rsuite';
import ConfigForm from '../components/ConfigForm';

class ConfigFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.configAdd = this.configAdd.bind(this);
    this.configEdit = this.configEdit.bind(this);
  }

  configAdd(variables) {
    const { configAdd } = this.props;
    configAdd({ variables })
      .then(() => {
        this.props.onCancel();
        Alert.success('Амжилттай нэмлээ');
      })
      .catch((err) => {
        console.log(err);
        Alert.error(err);
      });
  }

  configEdit(variables) {
    const { configEdit } = this.props;

    configEdit({ variables })
      .then(() => {
        this.props.onCancel();
        Alert.success('Амжилттай заслаа');
      })
      .catch((err) => {
        console.log(err);
        Alert.error(err);
      });
  }

  render() {
    const { configDetailsQuery, configChildCategoriesQuery, configListQuery } = this.props;

    if (configDetailsQuery && configDetailsQuery.loading) {
      return <></>;
    }

    if (configListQuery && configListQuery.loading) {
      return <></>;
    }

    if (configChildCategoriesQuery && configChildCategoriesQuery.loading) {
      return <></>;
    }

    const configList = configListQuery.configs || [];

    const detail = configDetailsQuery ? configDetailsQuery.configDetails || {} : {};

    const categoriesList = configChildCategoriesQuery.configChildCategories || [];

    const updatedProps = {
      ...this.props,
      configAdd: this.configAdd,
      configEdit: this.configEdit,
      categoriesList,
      detail,
      configList,
    };
    return <ConfigForm {...updatedProps} />;
  }
}

ConfigFormContainer.propTypes = {
  id: PropTypes.string,
  configCategoryDetailsQuery: PropTypes.object,
  configChildCategoriesQuery: PropTypes.object,
  configListQuery: PropTypes.object,
  configAdd: PropTypes.func,
  configEdit: PropTypes.func,
};

export default compose(
  graphql(gql(queries.configChildCategories), {
    name: 'configChildCategoriesQuery',
    options: (props) => {
      return {
        variables: {
          categoryId: props.id,
        },
      };
    },
  }),

  graphql(gql(queries.configDetails), {
    name: 'configDetailsQuery',
    skip: (props) => !props.id,
    options: (props) => {
      return {
        variables: {
          _id: props.id,
        },
      };
    },
  }),

  graphql(gql(queries.configList), {
    name: 'configListQuery',
  }),

  graphql(gql(mutations.configAdd), {
    name: 'configAdd',
    options: ({ doc }) => ({
      variables: {
        doc,
      },
    }),
  }),

  graphql(gql(mutations.configEdit), {
    name: 'configEdit',
    options: ({ _id, doc }) => ({
      variables: {
        _id,
        doc,
      },
    }),
  })
)(ConfigFormContainer);
