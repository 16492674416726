const companyListQuery = `
 query companies {
    companies {
      _id
      name
      placeId
      lat
      lng
      score
      category
      markScore
    }
  }
`;
const companyDetailQuery = `
  query companyDetail($_id: String) {
    companyDetail(_id: $_id) {
      _id
      name
      placeId
      lat
      lng
      score
    }
  }
`;

const configParentCategories = `
  query configParentCategories {
    configParentCategories {
        _id
        name
        categoryId
        image
        childCategories {
          _id
          name
          categoryId
          configs {
            _id
            name
          }
        }
    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  companyListQuery,
  companyDetailQuery,
  configParentCategories,
};
