import { Wrapper } from '@googlemaps/react-wrapper';
import React from 'react';
import { Col, Container, Message, Row } from 'rsuite';
import Head from '../../common/components/Head';
import Map from '../../mark/components/Map';
import Marker from '../../mark/components/Marker';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: {},
      name: '',
      email: '',
      feedback: '',
      sent: false,
    };
  }

  onChange = (state, value) => {
    this.setState({ [state]: value });
  };

  handleChange = (event) => {
    const name = event.target.name;
    this.setState({ [name]: event.target.value });
  };

  onSubmit = (e) => {
    const { feedbackAdd } = this.props;
    const { name, email, feedback } = this.state;
    const params = {
      doc: {
        name: name,
        email: email,
        feedback: feedback,
      },
    };
    this.setState({ sent: true });
    e.preventDefault();
    return feedbackAdd(params);
  };

  render() {
    const contact = this.props.contents[0];

    return (
      <div className='contact-page'>
        <Head title='Холбоо барих' />
        <section>
          <Container>
            <h2>{contact && contact.title}</h2>
            <Row className='contact-info'>
              <Col md={10}>
                <div className='contact-item'>
                  {contact && (
                    <div
                      dangerouslySetInnerHTML={{ __html: contact.content }}
                    />
                  )}
                </div>
              </Col>
              <Col md={14}>
                <div className='contact-item'>
                  <h5>Санал хүсэлт</h5>
                  {this.state.sent ? (
                    <Message
                      showIcon
                      type='success'
                      title='Таны хүсэлт амжилттай илгээгдлээ.'
                    />
                  ) : (
                    <form onSubmit={this.onSubmit}>
                      <div>
                        <label>Нэр</label>
                        <input
                          name='name'
                          placeholder='Нэр'
                          required
                          onChange={this.handleChange}
                        />
                      </div>
                      <div>
                        <label>Мэйл хаяг</label>
                        <input
                          name='email'
                          placeholder='Мэйл хаяг'
                          required
                          onChange={this.handleChange}
                        />
                      </div>
                      <div>
                        <label>Санал хүсэлт бичнэ үү</label>
                        <textarea
                          name='feedback'
                          placeholder='Текст'
                          required
                          onChange={this.handleChange}
                        />
                      </div>
                      <button type='submit'>Илгээх</button>
                    </form>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Wrapper apiKey={'AIzaSyCMBgbYGwJ0fvr2FYpdbzt7YI4ao_AAfQ8'}>
          <Map
            center={{ lat: 47.9163222, lng: 106.8807639 }}
            zoom={17}
            style={{ flexGrow: '1', height: '50vh' }}
          >
            <Marker position={{ lat: 47.9163222, lng: 106.8807639 }} />
          </Map>
        </Wrapper>
      </div>
    );
  }
}

export default Contact;
