const contentAdd = `
  mutation contentAdd($doc: ContentInput){
    contentAdd(doc: $doc){
      _id
    }
  }
`;

const contentEdit = `
  mutation contentEdit($doc: ContentInput){
    contentEdit(doc: $doc){
      _id
    }
  }
`;

const contentRemove = `
  mutation contentRemove($_id: String) {
    contentRemove(_id: $_id)
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  contentRemove,
  contentAdd,
  contentEdit,
};
