const configCategoryAdd = `
mutation configCategoryAdd($doc: ConfigCategoryInput){
    configCategoryAdd(doc: $doc){
        _id
    }
}
`;

const configCategoryEdit = `
mutation configCategoryEdit($doc: ConfigCategoryInput){
    configCategoryEdit(doc: $doc){
        _id
    }
}
`;

const configCategoryRemove = `
  mutation configCategoryRemove($_id: String) {
    configCategoryRemove(_id: $_id){
        _id
    }
  }
`;

const configAdd = `
mutation configAdd($doc: ConfigInput){
    configAdd(doc: $doc){
        _id
    }
}
`;

const configEdit = `
mutation configEdit($doc: ConfigInput){
   configEdit(doc: $doc){
        _id
    }
}
`;

const configRemove = `
  mutation configRemove($_id: String) {
    configRemove(_id: $_id){
        _id
    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  configCategoryAdd,
  configCategoryEdit,
  configCategoryRemove,
  configAdd,
  configEdit,
  configRemove
};
