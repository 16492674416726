import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'rsuite';
import SideNav from './SideNav';

class DashboardLayout extends React.Component {
  render() {
    return (
      <div className='dashboard flex'>
        <Helmet>
          <title>Удирдах самбар - Хүртээмж</title>
        </Helmet>
        <SideNav />
        <Container>
          <div className='main-dashboard'>{this.props.children}</div>
        </Container>
      </div>
    );
  }
}

export default DashboardLayout;
