const careerAdd = `
  mutation careerAdd($doc: CareerInput){
    careerAdd(doc: $doc){
      _id
    }
  }
`;

const careerEdit = `
  mutation careerEdit($doc: CareerInput){
    careerEdit(doc: $doc){
      _id
    }
  }
`;

const careerRemove = `
  mutation careerRemove($_id: String) {
    careerRemove(_id: $_id)
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  careerRemove,
  careerAdd,
  careerEdit,
};
