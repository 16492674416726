import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Alert } from 'rsuite';
import ContentList from '../components/ContentList';
import { mutations, queries } from '../graphql';
class ContentListContainer extends React.Component {
  contentRemove = (variables) => {
    const { contentRemoveMutation } = this.props;
    contentRemoveMutation({ variables: { _id: variables } })
      .then(() => {
        Alert.success('Амжилттай устгалаа');
      })
      .catch((err) => {
        Alert.error(err.message);
      });
  };

  render() {
    const { contentListQuery, contentsCountQuery } = this.props;

    if (contentListQuery && contentListQuery.loading) {
      return null;
    }
    const contents = contentListQuery.contents || [];
    const count = contentsCountQuery.contentsCount;

    const updatedProps = {
      ...this.props,
      contents,
      count,
      contentRemove: this.contentRemove,
    };

    return <ContentList {...updatedProps} />;
  }
}
const propTypes = {
  contentListQuery: PropTypes.object.isRequired,
};
ContentListContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.contentListQuery), {
      name: 'contentListQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            title: queryParams.title,
            category: queryParams.category,
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        };
      },
    }),
    graphql(gql(mutations.contentRemove), {
      name: 'contentRemoveMutation',
      options: () => ({
        refetchQueries: ['contents'],
      }),
    }),
    graphql(gql(queries.contentsCountQuery), {
      name: 'contentsCountQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            beginDate: queryParams.beginDate,
            endDate: queryParams.endDate,
            title: queryParams.title,
            importand: queryParams.importand,
          },
        };
      },
    })
  )(ContentListContainer)
);
