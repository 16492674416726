import React from 'react';
import { Route } from 'react-router-dom';
import Advice from './containers/Advice';
import Law from './containers/Law';
import Independence from './containers/Independence';
import Infrastructure from './containers/Infrastructure';
import Employment from './containers/Employment';
import Education from './containers/Education';
import queryString from 'query-string';
const routes = () => [
  <Route
    path='/advice'
    exact
    key='/advice'
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <Advice queryParams={queryParams} history={history} />;
    }}
  />,
  <Route
    path='/law'
    exact
    key='/law'
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <Law queryParams={queryParams} history={history} />;
    }}
  />,
  <Route
    path="/independence"
    exact
    key="/independence"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <Independence queryParams={queryParams} history={history} />;
    }}
  />,
  <Route
    path="/infrastructure"
    exact
    key="/infrastructure"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <Infrastructure queryParams={queryParams} history={history} />;
    }}
  />,
  <Route
    path="/employment"
    exact
    key="/employment"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <Employment queryParams={queryParams} history={history} />;
    }}
  />,
  <Route
    path="/education"
    exact
    key="/education"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <Education queryParams={queryParams} history={history} />;
    }}
  />,
];

export default routes;
