import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { Alert } from 'rsuite';
import FaqForm from '../components/FaqForm';
import { mutations, queries } from '../graphql';
class FaqFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.faqAdd = this.faqAdd.bind(this);
    this.faqEdit = this.faqEdit.bind(this);
  }

  faqAdd(variables) {
    const { faqAdd } = this.props;

    faqAdd({ variables })
      .then(() => {
        this.props.onCancel();
        Alert.success('Амжилттай нэмлээ');
      })
      .catch((err) => {
        Alert.error(err.message);
      });
  }

  faqEdit(variables) {
    const { faqEdit } = this.props;

    faqEdit({ variables })
      .then(() => {
        this.props.onCancel();
        Alert.success('Амжилттай хадгаллаа');
      })
      .catch((err) => {
        Alert.error(err.message);
      });
  }

  render() {
    const { faqDetailQuery } = this.props;
    if (faqDetailQuery && faqDetailQuery.loading) {
      return <></>;
    }

    const detail = faqDetailQuery ? faqDetailQuery.faqDetail || {} : {};

    const updatedProps = {
      ...this.props,
      faqAdd: this.faqAdd,
      faqEdit: this.faqEdit,
      detail,
    };

    return <FaqForm {...updatedProps} />;
  }
}

FaqFormContainer.propTypes = {
  id: PropTypes.string,
  faqDetailQuery: PropTypes.object,
  faqAdd: PropTypes.func,
  faqEdit: PropTypes.func,
};

export default compose(
  graphql(gql(queries.faqDetailQuery), {
    name: 'faqDetailQuery',
    skip: (props) => !props.id,
    options: (props) => {
      return {
        variables: {
          _id: props.id,
        },
      };
    },
  }),

  graphql(gql(mutations.faqAdd), {
    name: 'faqAdd',
    options: ({ doc }) => ({
      variables: {
        doc,
      },
    }),
  }),

  graphql(gql(mutations.faqEdit), {
    name: 'faqEdit',
    options: ({ _id, doc }) => ({
      variables: {
        _id,
        doc,
      },
    }),
  })
)(FaqFormContainer);
