import React from 'react';
import { Container, Panel } from 'rsuite';
import Head from '../../common/components/Head';
import { PageHeader } from '../../common/components/PageHeader';
class Faq extends React.Component {
  render() {
    const { head } = this.props;
    return (
      <div className="contact-page">
        <Head title="Түгээмэл асуулт хариулт" />
        <PageHeader bg={head && head.pictures[0]} title={head && head.title} />
        <section>
          <Container>
            <div className="faq-panels">
              {this.props.faqs.map((faq) => (
                <Panel header={faq.question} collapsible bordered>
                  <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                </Panel>
              ))}
            </div>
          </Container>
        </section>
      </div>
    );
  }
}

export default Faq;
