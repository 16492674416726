import React from 'react';
import { Col, Row } from 'rsuite';
import ReadMore from './ReadMore';
import sImg from '../../../assets/img/section.png';
export const FlexSection = (props) => {
  return (
    <div className='flex-section'>
      <Row gutter={0} className={`flex-row ${props.reversed && 'reversed'}`}>
        <Col xs={24} md={12} className='flex-center'>
          <div className='caption '>
            <h3>{props.title || 'Хууль, Эрх зүй'}</h3>
            <p>
              {props.description ||
                'Бидний алсын хараа, эрхэм зориолго бол хүртээмжтэй эрүүл, аюулгүй, тогтвортой хөгжлийг бүтээх юм'}
            </p>
            <ReadMore to={props.url || '/'} />
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className='img-wrapper'>
            <img src={props.imgs || sImg} alt='section' />
          </div>
        </Col>
      </Row>
    </div>
  );
};
