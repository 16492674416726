import React from 'react';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
    };
  }

  handleSearch = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  handleSubmit = (e) => {
    const { searchValue } = this.state;
    e.preventDefault();
    this.props.history.push('/search?title=' + searchValue);
  };

  render() {
    const { searchValue } = this.state;

    return (
      <div className="search">
        <form onSubmit={this.handleSubmit}>
          <input placeholder="Хайх" onChange={this.handleSearch} value={searchValue} />
          <i onClick={this.handleSubmit} className="fas fa-search"></i>
        </form>
      </div>
    );
  }
}

export default Search;
