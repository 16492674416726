import React from 'react';
import { withRouter } from 'react-router';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { queries, mutations } from '../graphql';
import UserList from '../components/UserList';

const UsersContainer = props => {
  const {
    usersRemoveMutation,
    getUsersQuery,
    currentUser,
    queryParams,
    history
  } = props;

  if (getUsersQuery.loading) {
    return <></>;
  }

  const usersRemove = variables => {
    usersRemoveMutation({ variables: { _id: variables } })
      .then(() => {
        window.alert('Амжилттай устгалаа');
      })
      .catch(err => {
        console.log(err);
      });
  };

  const users = getUsersQuery.users || {};

  const updatedProps = {
    usersRemove,
    users,
    currentUser,
    history,
    queryParams
  };

  return <UserList {...updatedProps} />;
};

export default withRouter(
  compose(
    graphql(gql(queries.usersListQuery), {
      name: 'getUsersQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            username: queryParams.username,
            phone: queryParams.phone,
            email: queryParams.email
          }
        };
      }
    }),

    graphql(gql(mutations.userRemove), {
      name: 'usersRemoveMutation'
    })
  )(UsersContainer)
);
