import React from 'react';
import { Container } from 'rsuite';
import { Link } from 'react-router-dom';
class Search extends React.Component {
  constructor(props) {
    super(props);

    const qp = props.queryParams;

    this.filter = {
      title: qp && qp.title ? qp.title : '',
    };
  }

  render() {
    const { contents } = this.props;
    return (
      <Container>
        <div className="search-page">
          {contents && contents.length ? (
            <h3>Хайлтын илэрц: {this.filter.title}</h3>
          ) : (
            <h3>Уучлаарай. "{this.filter.title}" - д тохирох илэрц олдсонгүй</h3>
          )}
          <ul className="search-results">
            {contents && contents.length
              ? contents.map((content) => (
                  <li key={content._id}>
                    <Link to={`/news-detail/${content._id}`}>
                      <h4>{content.title}</h4>
                    </Link>
                  </li>
                ))
              : null}
          </ul>
        </div>
      </Container>
    );
  }
}

export default Search;
