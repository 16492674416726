import React from 'react';
import {
  Button,
  ButtonToolbar,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
} from 'rsuite';
import Editor from '../../../../common/components/Editor';
export default class UserCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: props.detail || {},
      content: props.detail.answer,
      editor: '',
    };
  }

  onEditorChange = (content) => {
    this.setState({ editor: content });
  };
  onFormChange = (formValue) => {
    this.setState({ formValue });
  };

  onSubmit = () => {
    const { formValue, editor } = this.state;
    const { detail, faqEdit, faqAdd } = this.props;

    const params = {
      doc: {
        _id: formValue._id,
        question: formValue.question,
        answer: editor,
      },
    };

    if (detail._id) {
      return faqEdit(params);
    }

    return faqAdd(params);
  };

  render() {
    const { content } = this.state;
    return (
      <Form
        onChange={(formValue) => {
          this.onFormChange(formValue);
        }}
        formValue={this.state.formValue}
      >
        <FormGroup>
          <ControlLabel>Асуулт</ControlLabel>
          <FormControl name='question' />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Хариулт</ControlLabel>
          <Editor
            name='answer'
            onChange={this.onEditorChange}
            content={content}
          />
        </FormGroup>
        <FormGroup>
          <ButtonToolbar>
            <Button appearance='primary' onClick={this.onSubmit}>
              Submit
            </Button>
            <Button appearance='default' onClick={this.props.onCancel}>
              Cancel
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    );
  }
}
