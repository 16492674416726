import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
import { mutations, queries } from '../graphql';
import { Alert } from 'rsuite';
import MarkCategories from '../components/MarkCategories';

class MarkCategoriesContainer extends React.Component {
  configCategoryRemove = (variables) => {
    const { configCategoryRemoveMutation } = this.props;

    configCategoryRemoveMutation({ variables: { _id: variables } })
      .then(() => {
        Alert.success('Амжилттай устгалаа');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  configRemove = (variables) => {
    const { configRemoveMutation } = this.props;

    configRemoveMutation({ variables: { _id: variables } })
      .then(() => {
        Alert.success('Амжилттай устгалаа');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { configCategoriesQuery, configParentCategoriesQuery } = this.props;

    if ((configCategoriesQuery && configCategoriesQuery.loading) || configParentCategoriesQuery.loading) {
      return null;
    }

    const categoriesList = configParentCategoriesQuery.configParentCategories || [];

    const updatedProps = {
      ...this.props,
      categoriesList,
      configCategoryRemove: this.configCategoryRemove,
      configRemove: this.configRemove,
    };

    return <MarkCategories {...updatedProps} />;
  }
}

MarkCategoriesContainer.propTypes = {
  id: PropTypes.string,
  configCategoriesQuery: PropTypes.object,
  configParentCategoriesQuery: PropTypes.object,
  configCategoryAdd: PropTypes.func,
  configCategoryEdit: PropTypes.func,
};

export default compose(
  graphql(gql(queries.configCategories), {
    name: 'configCategoriesQuery',
    options: (props) => {
      return {
        variables: {
          categoryId: props.id,
        },
      };
    },
  }),
  graphql(gql(queries.configParentCategories), {
    name: 'configParentCategoriesQuery',
  }),
  graphql(gql(mutations.configCategoryRemove), {
    name: 'configCategoryRemoveMutation',
  }),
  graphql(gql(mutations.configRemove), {
    name: 'configRemoveMutation',
  })
)(MarkCategoriesContainer);
