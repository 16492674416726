const commentsQuery = `
  query comments{
    comments{
      _id
      content
      contentId
      userName
    }
  }
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  commentsQuery,
};
