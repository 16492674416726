const contentListQuery = `
 query contents($title: String, $category: String, $importand: String) {
    contents(title: $title, category: $category, importand: $importand) {
      _id
      title
      category
      createdAt
      importand
      pictures
    }
  }
`;
const contentDetailQuery = `
  query contentDetail($_id: String) {
    contentDetail(_id: $_id) {
      _id
      title
      date
      content
      category
      createdAt
      pictures
      description
      imageNote
      pin
      importand
    }
  }
`;
const contentsCountQuery = `
  query contentsCount($beginDate: String, $endDate: String, $title: String, $importand: String){
    contentsCount(beginDate: $beginDate, endDate: $endDate, title: $title, importand: $importand)
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  contentListQuery,
  contentDetailQuery,
  contentsCountQuery,
};
