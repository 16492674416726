const categories = [
  {
    label: 'Танилцуулга',
    value: 'intro'
  },
  {
    label: 'Мэдээ, Мэдээлэл',
    value: 'news'
  },
  {
    label: 'Влог & Блог',
    value: 'vlog'
  },
  {
    label: 'Түүхэн замнал',
    value: 'timeline'
  },
  {
    label: 'Зөвлөгөө хуудас',
    value: 'law'
  },
  {
    label: 'Хууль, эрх зүй',
    value: 'legal'
  },
  {
    label: 'Бие даан амьдрах',
    value: 'advice'
  },
  {
    label: 'Дэд бүтэц, барилга байгууламжийн хүртээмж',
    value: 'infrastructure'
  },
  {
    label: 'Хөдөлмөр эрхлэлт',
    value: 'employment'
  },
  {
    label: 'Тэгш хамран сургах боловсрол',
    value: 'education'
  },
  {
    label: 'Холбоо барих',
    value: 'contact'
  },
  {
    label: 'Үйл ажиллагаа',
    value: 'activity'
  },
  {
    label: 'Бие даан амьдрахад дэмжих хөтөлбөр',
    value: 'activity1'
  },
  {
    label: 'Нийгмийн оролцоог дэмжих хөтөлбөр',
    value: 'activity2'
  },
  {
    label: 'Боловсролын хөтөлбөр',
    value: 'activity3'
  }
];

const pageHeaderCategories = [
  {
    label: 'Мэндчилгээ',
    value: 'greeting-head'
  },
  {
    label: 'Бидний тухай',
    value: 'about-head'
  },
  {
    label: 'Үйл ажиилагаа',
    value: 'activity-head'
  },
  {
    label: 'FAQ',
    value: 'faq-head'
  },
  {
    label: 'Хууль, Эрх зүй',
    value: 'law-head'
  },
  {
    label: 'Ажлын зар',
    value: 'career-head'
  }
];

const lawCategories = [
  {
    label: 'Олон улсын бичиг баримт',
    value: 'Олон улсын бичиг баримт'
  },
  {
    label: 'Хууль',
    value: 'Хууль'
  },
  {
    label: 'Үндэсний хөтөлбөр',
    value: 'Үндэсний хөтөлбөр'
  },
  {
    label: 'Үйл ажиллагааны тайлан',
    value: 'activity-reports'
  }
];

const imgTypes = [
  { label: 'Танилцуулга', value: 'intro' },
  { label: 'Хамтрагч байгууллагууд', value: 'partners' },
  { label: 'Бидний тухай', value: 'about' }
];
const locations = [
  { value: 'Баянгол дүүрэг', label: 'Баянгол дүүрэг' },
  { value: 'Баянзүрх дүүрэг', label: 'Баянзүрх дүүрэг' },
  { value: 'Сүхбаатар дүүрэг', label: 'Сүхбаатар дүүрэг' },
  { value: 'Сонгинохайрхан дүүрэг', label: 'Сонгинохайрхан дүүрэг' },
  { value: 'Чингэлтэй дүүрэг', label: 'Чингэлтэй дүүрэг' },
  { value: 'Хан-Уул  дүүрэг', label: 'Хан-Уул  дүүрэг' },
  { value: 'Налайх дүүрэг', label: 'Налайх дүүрэг' },
  { value: 'Багахангай дүүрэг', label: 'Багахангай дүүрэг' },
  { value: 'Багануур дүүрэг', label: 'Багануур дүүрэг' },
  { value: 'Орон нутаг', label: 'Орон нутаг' }
];
const jobTypes = [
  { value: 'Маркетинг, борлуулалт', label: 'Маркетинг, борлуулалт' },
  { value: 'Нягтлан, санхүүч, нярав', label: 'Нягтлан, санхүүч, нярав' },
  { value: 'Инженер, технологи', label: 'Инженер, технологи' },
  {
    value: 'Компьютер, мэдээллийн технологи',
    label: 'Компьютер, мэдээллийн технологи'
  },
  { value: 'Харилцаа холбоо', label: 'Харилцаа холбоо' },
  { value: 'График, мультимедиа', label: 'График, мультимедиа' },
  { value: 'Гүйцэтгэх удирдлага', label: 'Гүйцэтгэх удирдлага' },
  { value: 'Барилгын инженер, ажилтан', label: 'Барилгын инженер, ажилтан' },
  { value: 'Хүний нөөц, багш', label: 'Хүний нөөц, багш' },
  { value: 'Эмч, эрүүл мэндийн ажилтан', label: 'Эмч, эрүүл мэндийн ажилтан' },
  { value: 'Уул уурхай, геологи', label: 'Уул уурхай, геологи' },
  {
    value: 'Сэтгүүлч, нийтлэгч, орчуулагч',
    label: 'Сэтгүүлч, нийтлэгч, орчуулагч'
  },
  { value: 'Хуульч, өмгөөлөгч', label: 'Хуульч, өмгөөлөгч' },
  { value: 'Эрдэмтэн, судлаач', label: 'Эрдэмтэн, судлаач' },
  { value: 'Менежер, оффисын ажил', label: 'Менежер, оффисын ажил' },
  { value: 'Бичиг хэрэг, архив', label: 'Бичиг хэрэг, архив' },
  { value: 'Мэдээллтийн ажилтан', label: 'Мэдээллтийн ажилтан' },
  { value: 'Мэргэжилтэй  ажилтан', label: 'Мэргэжилтэй  ажилтан' },
  { value: 'Зөөгч, тогооч, бэлтгэгч', label: 'Зөөгч, тогооч, бэлтгэгч' },
  { value: 'Үсчин, гоо сайханч', label: 'Үсчин, гоо сайханч' },
  {
    value: 'Харуул, хамгаалалтын ажилтан',
    label: 'Харуул, хамгаалалтын ажилтан'
  },
  { value: 'Үйлчлэгч, хүүхэд асрагч', label: 'Үйлчлэгч, хүүхэд асрагч' },
  { value: 'Жолооч, авто засварчин', label: 'Жолооч, авто засварчин' },
  { value: 'Худалдагч касс', label: 'Худалдагч касс' },
  { value: 'Туслах ажилтан', label: 'Туслах ажилтан' },
  { value: 'Бусад', label: 'Бусад' }
];

const types = [
  { value: 'Ажилд авна', label: 'Ажилд авна' },
  { value: 'Ажил хайж байна', label: 'Ажил хайж байна' }
];

export const disablityTypes = [
  {
    value: 'Тулах хөдлөх эрхтэний бэрхшээл/тэргэнцэртэй/',
    label: 'Тулах хөдлөх эрхтэний бэрхшээл/тэргэнцэртэй/'
  },
  { value: 'Харааны бэрхшээл', label: 'Харааны бэрхшээл' },
  { value: 'Сонсголын бэрхшээл', label: 'Сонсголын бэрхшээл' },
  { value: 'Оюун ухааны бэрхшээл', label: 'Оюун ухааны бэрхшээл' },
  { value: 'Эплилеси', label: 'Эплилеси' },
  { value: 'Тархины саажилт', label: 'Тархины саажилт' },
  { value: 'Аутизм', label: 'Аутизм' },
  { value: 'Дауны хам шинж', label: 'Дауны хам шинж' },
  { value: 'Бусад', label: 'Бусад' }
];

const workingHoursData = [
  { value: 'Гэрээт', label: 'Гэрээт' },
  { value: 'Бүтэн цагийн', label: 'Бүтэн цагийн' },
  { value: 'Цагийн', label: 'Цагийн' }
];

export {
  categories,
  lawCategories,
  types,
  pageHeaderCategories,
  jobTypes,
  workingHoursData,
  imgTypes,
  locations
};
