import React from 'react';
import { Route } from 'react-router-dom';
import MarkList from './containers/MarkList';
import MarkMap from './containers/MarkMap';
import queryString from 'query-string';
import MarkReport from './containers/MarkReport';
const routes = () => [
  <Route
    path="/mark-list"
    exact
    key="/mark-list"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <MarkList queryParams={queryParams} history={history} />;
    }}
  />,
  <Route
    path="/mark-map"
    exact
    key="/mark-map"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <MarkMap queryParams={queryParams} history={history} />;
    }}
  />,
  <Route
    path="/mark-report"
    exact
    key="/mark-report"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <MarkReport queryParams={queryParams} history={history} />;
    }}
  />,
];

export default routes;
