import React from 'react';
import NewsDetail from '../components/NewsDetail';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { queries } from '../graphql';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
class NewsDetailContainer extends React.Component {
  render() {
    const { contentDetailQuery } = this.props;

    if (contentDetailQuery.loading) {
      return null;
    }
    const news = contentDetailQuery.contentDetail || {};
    const updatedProps = { ...this.props, news };

    return <NewsDetail {...updatedProps} />;
  }
}
const propTypes = {
  contentDetailQuery: PropTypes.object.isRequired,
};

NewsDetailContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.contentDetailQuery), {
      name: 'contentDetailQuery',
      options: ({ id }) => ({
        variables: {
          _id: id,
        },
      }),
    })
  )(NewsDetailContainer)
);
