import React from "react";
import { Container } from "rsuite";
import { FlexSection } from "../../common/components/FlexSection";
import Head from "../../common/components/Head";
class Intro extends React.Component {
  render() {
    const intros = this.props.contents;
    if (!intros) {
      return null;
    }

    return (
      <section className="page">
        <Head title="Танилцуулга" />
        <Container>
          <FlexSection
            title={intros[0].title}
            description={intros[0].description}
            imgs={intros[0].pictures}
            reversed
            url={`/about`}

            // url={`/about-detail/${intros[0]._id}`}
          />
          <FlexSection
            title={intros[1].title}
            description={intros[1].description}
            imgs={intros[1].pictures}
            url={`/timeline`}

            // url={`/about-detail/${intros[1]._id}`}
          />
          <FlexSection
            title={intros[2].title}
            description={intros[2].description}
            imgs={intros[2].pictures}
            reversed
            url={`/greeting`}
            // url={`/about-detail/${intros[2]._id}`}
          />

          <FlexSection
            title={intros[3].title}
            description={intros[3].description}
            imgs={intros[3].pictures}
            url={`/activity`}

            // url={`/about-detail/${intros[3]._id}`}
          />
        </Container>
      </section>
    );
  }
}

export default Intro;
