import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { Alert } from 'rsuite';
import ImageForm from '../components/ImageForm';
import { mutations, queries } from '../graphql';

class ImageFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.imageAdd = this.imageAdd.bind(this);
    this.imageEdit = this.imageEdit.bind(this);
  }

  imageAdd(variables) {
    const { imageAdd } = this.props;

    imageAdd({ variables })
      .then(() => {
        this.props.onCancel();
        Alert.success('Амжилттай нэмлээ');
      })
      .catch((err) => {
        Alert.error(err);
      });
  }

  imageEdit(variables) {
    const { imageEdit } = this.props;

    imageEdit({ variables })
      .then(() => {
        this.props.onCancel();
        Alert.success('Амжилттай заслаа');
      })
      .catch((err) => {
        Alert.error(err);
      });
  }

  render() {
    const { imageDetailQuery } = this.props;

    if (imageDetailQuery && imageDetailQuery.loading) {
      return <></>;
    }

    const detail = imageDetailQuery ? imageDetailQuery.imageDetail || {} : {};

    const updatedProps = {
      ...this.props,
      imageAdd: this.imageAdd,
      imageEdit: this.imageEdit,
      detail,
    };

    return <ImageForm {...updatedProps} />;
  }
}

ImageFormContainer.propTypes = {
  id: PropTypes.string,
  imageDetailQuery: PropTypes.object,
  imageAdd: PropTypes.func,
  imageEdit: PropTypes.func,
};

export default compose(
  graphql(gql(queries.imageDetailQuery), {
    name: 'imageDetailQuery',
    skip: (props) => !props.id,
    options: (props) => {
      return {
        variables: {
          _id: props.id,
        },
      };
    },
  }),

  graphql(gql(mutations.imageAdd), {
    name: 'imageAdd',
    options: ({ doc }) => ({
      variables: {
        doc,
      },
    }),
  }),

  graphql(gql(mutations.imageEdit), {
    name: 'imageEdit',
    options: ({ _id, doc }) => ({
      variables: {
        _id,
        doc,
      },
    }),
  })
)(ImageFormContainer);
