import React from "react";
import {
  Button,
  ButtonToolbar,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  SelectPicker,
} from "rsuite";
import Uploader from "../../../../common/components/Uploader";
import { Avatar } from "antd";

export default class ConfigForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: props.detail.categoryId || "",
      measureType: props.detail.measureType || "",
      show: false,
      formValue: props.detail || {},
      images: props.detail.images || [],
      dependsOn: props.detail.dependsOn || "",
    };

    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }

  onFormChange = (formValue) => {
    this.setState({ formValue });
  };

  onCategoryChange = (value) => {
    this.setState({ category: value });
  };

  onDependsOnChange = (value) => {
    this.setState({ dependsOn: value });
  };

  onMeasureChange = (value) => {
    this.setState({ measureType: value });
  };

  handleChange(name, files) {
    this.setState({ [name]: files });
  }

  renderFileUpload(name, label) {
    const files = this.state[name];

    const fileList = files || [];

    return (
      <div>
        <Uploader
          defaultFileList={fileList}
          onChange={(e) => this.handleChange(name, e)}
          disabled={false}
          label={label}
        />
        {fileList.length > 0 && fileList[0].url && (
          <Avatar shape="square" size={64} src={fileList[0].url} />
        )}
      </div>
    );
  }

  onSubmit = () => {
    const { formValue, category, images, measureType, dependsOn } = this.state;
    const { detail, configEdit, configAdd } = this.props;

    const params = {
      doc: {
        _id: formValue._id,
        name: formValue.name,
        description: formValue.description,
        maxValue: formValue.maxValue,
        minValue: formValue.minValue,
        categoryId: category,
        measureType: measureType,
        dependsOn: dependsOn,
        images: images?.length > 0 ? images[0].url : [],
      },
    };

    if (detail._id) {
      return configEdit(params);
    }

    return configAdd(params);
  };

  render() {
    const { formValue, category, images, measureType, dependsOn } = this.state;
    const { categoriesList } = this.props;

    const currentConfigs = categoriesList.filter(
      (config) => config._id === category
    );

    const configs =
      currentConfigs.length > 0
        ? currentConfigs[0].configs.map((config) => ({
            label: config.name,
            value: config._id,
          }))
        : [];

    const categories = categoriesList.map((cat) => ({
      label: cat.name,
      value: cat._id,
    }));

    const data = [
      {
        label: "Нэгж уртаар (см)",
        value: "1",
      },
      {
        label: "Хувь хэмжилтээр (%)",
        value: "2",
      },
      {
        label: "Хариултаар (Тийм/Үгүй)",
        value: "3",
      },
    ];

    return (
      <Form
        onChange={(formValue) => {
          this.onFormChange(formValue);
        }}
        formValue={formValue}
      >
        <FormGroup>
          <ControlLabel>Нэр</ControlLabel>
          <FormControl name="name" />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Тайлбар</ControlLabel>
          <FormControl name="description" />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Дээд утга</ControlLabel>
          <FormControl name="maxValue" />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Доод утга</ControlLabel>
          <FormControl name="minValue" />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Ангилал</ControlLabel>
          <SelectPicker
            data={categories}
            style={{ width: 300 }}
            onChange={(value) => this.onCategoryChange(value)}
            defaultValue={category ? category : undefined}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Хамаарал</ControlLabel>
          <SelectPicker
            data={configs}
            style={{ width: 300 }}
            onChange={(value) => this.onDependsOnChange(value)}
            defaultValue={dependsOn ? dependsOn : undefined}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Хэмжилтийн төрөл сонгох</ControlLabel>
          <SelectPicker
            data={data}
            style={{ width: 300 }}
            defaultValue={measureType ? measureType : undefined}
            onChange={(value) => this.onMeasureChange(value)}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>Зураг</ControlLabel>
          {this.renderFileUpload("images", "Зураг оруулах")}
        </FormGroup>

        {images && (
          <div className="ant-avatar">
            <img src={images} alt="" />
          </div>
        )}

        <FormGroup>
          <ButtonToolbar>
            <Button appearance="primary" onClick={this.onSubmit}>
              Хадгалах
            </Button>
            <Button appearance="default" onClick={this.props.onCancel}>
              Цуцлах
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    );
  }
}
