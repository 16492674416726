const careerListQuery = `
 query careers($title: String, $location: String, $jobType: String, $workingHours: String, $page: String, $perPage: String,$type: String){
    careers(title: $title, location: $location, jobType: $jobType, workingHours: $workingHours, page: $page, perPage: $perPage,type: $type) {
      _id
      title
      description
      location
      jobType
      images
      workingHours
      salary
      phone
      company
      type
    }
  }
`;
const careerDetailQuery = `
  query careerDetail($_id: String) {
    careerDetail(_id: $_id) {
      _id
      title
      description
      location
      jobType
      images
      workingHours
      salary
      phone
      company
      type
    }
  }
`;

const contentListQuery = `
 query contents($title: String, $category: String, $importand: String) {
    contents(title: $title, category: $category, importand: $importand) {
      _id
      title
      category
      importand
      pictures
      description
      content
    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  careerListQuery,
  careerDetailQuery,
  contentListQuery,
};
