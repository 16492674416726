import React from 'react';
import MarkReport from '../components/MarkReport';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
import { queries } from '../graphql';

class MarkReportContainer extends React.Component {
  render() {
    const { companyListQuery, configParentCategoriesQuery } = this.props;

    if (companyListQuery && companyListQuery.loading) {
      return <></>;
    }

    if (configParentCategoriesQuery && configParentCategoriesQuery.loading) {
      return <></>;
    }

    const companyList = companyListQuery ? companyListQuery.companies || [] : [];

    const categoryList = configParentCategoriesQuery ? configParentCategoriesQuery.configParentCategories || [] : [];

    const updatedProps = {
      companyList,
      categoryList,
    };
    return <MarkReport {...updatedProps} />;
  }
}

MarkReportContainer.propTypes = {
  companyListQuery: PropTypes.object,
};

export default compose(
  graphql(gql(queries.companyListQuery), {
    name: 'companyListQuery',
  }),
  graphql(gql(queries.configParentCategories), {
    name: 'configParentCategoriesQuery',
  })
)(MarkReportContainer);
