import { Wrapper } from "@googlemaps/react-wrapper";
import React from "react";
import mk1 from "../../../assets/img/m1.png";
import mk2 from "../../../assets/img/m2.png";
import mk3 from "../../../assets/img/m3.png";
import mk1m from "../../../assets/img/mk1m.png";
import mk2m from "../../../assets/img/mk2m.png";
import mk3m from "../../../assets/img/mk3m.png";
import desc1 from "../../../assets/img/mk1.png";
import desc2 from "../../../assets/img/mk2.png";
import desc3 from "../../../assets/img/mk3.png";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, EmailIcon } from "react-share";
import { SEO } from "../../common/components/SEO";
import { Input, Slider, Button } from "rsuite";
import Map from "./Map";
import Marker from "./Marker";
import Geocode from "react-geocode";
import { useLocation } from "react-router-dom";

const apikey = "AIzaSyCMBgbYGwJ0fvr2FYpdbzt7YI4ao_AAfQ8";
Geocode.setApiKey(apikey);
// Geocode.setLocationType('ROOFTOP');
Geocode.setRegion("mn");

export default function MarkMap(props) {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  const [clicks, setClicks] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [showActive, setShowActive] = React.useState(false);
  const [companyName, setCompanyName] = React.useState("");
  const [companyData] = React.useState({});
  const [sliderValue, setSliderValue] = React.useState(0);

  let lat = clicks ? JSON.stringify(clicks.toJSON().lat, null, 2) : 47.9182564;
  let lng = clicks ? JSON.stringify(clicks.toJSON().lng, null, 2) : 106.9215738;

  const onClick = (e) => {
    setClicks(e.latLng);
    setShowActive(false);
    setShow(true);
  };

  const onClickMarker = (e) => {
    setShow(false);
    setShowActive(true);
  };

  const icon = (markScore, score) => {
    if (markScore === 1) {
      if (score >= 7.5) {
        return mk1m;
      }
      if (score >= 2.5) {
        return mk2m;
      } else {
        return mk3m;
      }
    }
    if (score >= 7.5) {
      return mk1;
    }
    if (score >= 2.5) {
      return mk2;
    } else {
      return mk3;
    }
  };

  const addCompany = () => {
    const { companyAdd } = props;

    const params = {
      doc: {
        score: sliderValue,
        name: companyName,
        lat: JSON.stringify(clicks.toJSON().lat, null, 2).toString(),
        lng: JSON.stringify(clicks.toJSON().lng, null, 2).toString(),
      },
    };
    return companyAdd(params);
  };

  const rateCompany = () => {
    const { companyRate } = props;

    if (id) {
      return companyRate({ _id: id, rate: sliderValue });
    }
    return;
  };

  const renderBox = (_id) => {
    if (show) {
      const shareUrl = `https://hurteemj.edu.mn/mark-map?&lat=${lat}&lng=${lng}`;
      return (
        <div style={{ height: "auto" }} className="data-container">
          <Input
            style={{
              marginBottom: "1rem",
              fontSize: "18px",
              fontWeight: "bold",
            }}
            placeholder="Байгууллагын нэр"
            defaultValue=""
            // value={companyName}
            onChange={(value) => setCompanyName(value)}
          />
          <p>
            Таны хувьд энэ газар хэр хүртээмжтэй байсан бэ? <br />{" "}
            <strong>0 - 10</strong> хооронд үнэлгээ өгнө үү.
          </p>
          <i className="fas fa-times" onClick={() => setShow(false)}></i>
          <Slider
            progress
            max={10}
            min={1}
            step={0.1}
            value={parseFloat(sliderValue)}
            onChange={(value) => setSliderValue(value)}
          />
          <h2>{sliderValue.toFixed(2)}</h2>
          <div style={{ textAlign: "center" }}>
            <Button onClick={addCompany}>Үнэлгээ өгөх</Button>
          </div>
          <SEO
            title={companyData.name}
            description="Таны хувьд энэ газар хэр хүртээмжтэй байсан бэ?"
            url={shareUrl}
          />
          <div className="mark-desc">
            <div className="desc-item">
              <img alt="img" src={desc1} />
              <p>Зөвхөн хүртээмжтэй байгууллагууд /10 - 7.5/</p>
            </div>
            <div className="desc-item">
              <img alt="img" src={desc2} />
              <p>Зөвхөн хагас хүртээмжтэй байгууллагууд /7.4 - 2.5/</p>
            </div>
            <div className="desc-item">
              <img alt="img" src={desc3} />
              <p>Зөвхөн хүртээмжгүй байгууллагууд /2.4 - 0/</p>
            </div>
          </div>
          <div className="share-map">
            <span>Хуваалцах: </span>
            <FacebookShareButton
              url={shareUrl}
              description="Таны хувьд энэ газар хэр хүртээмжтэй байсан бэ?"
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <br />
            <TwitterShareButton title={companyData.name} url={shareUrl}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <EmailShareButton subject={companyData.name} url={shareUrl}>
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
        </div>
      );
    }
  };

  const renderActiveBox = (data) => {
    if (showActive) {
      const shareUrl = `https://hurteemj.edu.mn/mark-map?&lat=${lat}&lng=${lng}`;
      const isMrkScore = data.markScore === 0 ? true : false;
      return (
        <div style={{ height: "auto" }} className="data-container">
          <h5>{data.name}</h5>

          {isMrkScore && (
            <p>
              Таны хувьд энэ газар хэр хүртээмжтэй байсан бэ? <br />{" "}
              <strong>0 - 10</strong> хооронд үнэлгээ өгнө үү.
            </p>
          )}
          <i className="fas fa-times" onClick={() => setShowActive(false)}></i>
          {isMrkScore && (
            <Slider
              progress
              max={10}
              min={1}
              step={0.1}
              defaultValue={parseFloat(data.score)}
              onChange={(value) => setSliderValue(value)}
            />
          )}
          <h2>{data.score.toFixed(2)}</h2>
          <span
            style={{ display: "block", width: "100%", textAlign: "center" }}
          >
            Үнэлгээний оноо
          </span>
          {data.markScore > 0 && (
            <h6
              style={{
                background: "#fcdfbd",
                padding: "1rem",
                borderRadius: "6px",
                textAlign: "center",
                marginTop: "2rem",
              }}
            >
              Энэ газарт мэргэжилтэн үнэлгээ өгсөн тул дахин үнэлгээ өгөх
              боломжгүй байна.
            </h6>
          )}
          {isMrkScore && (
            <div style={{ textAlign: "center" }}>
              <Button onClick={rateCompany}>Үнэлгээ өгөх</Button>
            </div>
          )}
          <SEO
            title={companyData.name}
            description="Таны хувьд энэ газар хэр хүртээмжтэй байсан бэ?"
            url={shareUrl}
          />
          {
            <div className="mark-desc">
              <div className="desc-item">
                <img alt="img" src={desc1} />
                <p>
                  Хүртээмжтэй ариун цэврийн өрөөтэй, Стандартын налуу замтай
                  (1:12), Орох хаалга өндөр босгогүй (2.5 см{">"}), дотор орчинд
                  тэргэнцэртэй хүн чөлөөтэй зорчих орон зайтай, жижиг шат
                  босгогүй
                </p>
              </div>
              <div className="desc-item">
                <img alt="img" src={desc1} />
                <p>
                  Стандартын налуу замтай (1:12),Орох хаалга өндөр босгогүй (2.5
                  см{">"}), дотор орчинд тэргэнцэй хүн чөлөөтэй зорчих орон
                  зайтай, жижиг шат босгогүй
                </p>
              </div>
              <div className="desc-item">
                <img alt="img" src={desc2} />
                <p>Стандартын налуу замтай (1:12)</p>
              </div>
              <div className="desc-item">
                <img alt="img" src={desc3} />
                <p>Тэргэнцэртэй хүний хэрэгцээнд тохиромжгүй</p>
              </div>
            </div>
          }
          <div className="share-map">
            <span>Хуваалцах: </span>
            <FacebookShareButton
              url={shareUrl}
              description="Таны хувьд энэ газар хэр хүртээмжтэй байсан бэ?"
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <br />
            <TwitterShareButton title={companyData.name} url={shareUrl}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <EmailShareButton subject={companyData.name} url={shareUrl}>
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
        </div>
      );
    }
  };

  const activeCompany = id
    ? Object.values(props.companyList).filter((company) => company._id === id)
    : null;
  if (id) {
    lat = parseFloat(activeCompany[0].lat);
    lng = parseFloat(activeCompany[0].lng);
  }

  return (
    <div style={{ height: "80vh", width: "100vw" }}>
      {renderBox()}
      {id && renderActiveBox(activeCompany[0])}
      <Wrapper apiKey={apikey}>
        <Map
          onClick={onClick}
          center={{ lat: lat, lng: lng }}
          zoom={17}
          style={{ flexGrow: "1", height: "100%" }}
        >
          {props.companyList.map((company) => {
            return (
              <Marker
                icon={icon(company.markScore, company.score)}
                position={{
                  lat: parseFloat(company.lat),
                  lng: parseFloat(company.lng),
                }}
                onClick={onClickMarker}
                data={company}
                key={company._id}
              />
            );
          })}
        </Map>
      </Wrapper>
    </div>
  );
}
