import { Loader } from 'rsuite';

const Loading = () => {
  return (
    <div className="loading">
      <Loader size="md" speed="slow" center content="Уншиж байна." />
    </div>
  );
};

export default Loading;
