import gql from 'graphql-tag';
import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
import { queries } from '../graphql';

const withCurrentUser = Component => {
  const Container = props => {
    const { currentUserQuery } = props;
    if (currentUserQuery.loading) {
      return null;
    }
    const updatedProps = {
      currentUser: currentUserQuery.currentUser
    };

    return <Component {...updatedProps} />;
  };
  return compose(
    graphql(gql(queries.currentUser), {
      name: 'currentUserQuery'
    })
  )(Container);
};
export default withCurrentUser;
