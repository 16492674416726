import React from 'react';
import Home from '../components/Home';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import { queries } from '../graphql';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
class HomeContainer extends React.Component {
  render() {
    const { faqListQuery, sliderNewsListQuery, imageListQuery, vlogListQuery, companiesTopQuery } = this.props;

    if (imageListQuery && imageListQuery.loading) {
      return null;
    }

    if (companiesTopQuery && companiesTopQuery.loading) {
      return null;
    }

    if (faqListQuery && faqListQuery.loading) {
      return null;
    }

    if (sliderNewsListQuery && sliderNewsListQuery.loading) {
      return null;
    }

    if (vlogListQuery && vlogListQuery.loading) {
      return null;
    }

    const contents = sliderNewsListQuery.contents || [];
    const vlogs = vlogListQuery.contents || [];
    const faqs = faqListQuery.faqs || null;
    const images = imageListQuery.images || [];
    const companies = companiesTopQuery.companiesTop || [];
    const updatedProps = {
      faqs,
      images,
      contents,
      vlogs,
      companies,
    };

    return <Home {...updatedProps} />;
  }
}
const propTypes = {
  faqListQuery: PropTypes.object.isRequired,
  contentListQuery: PropTypes.object.isRequired,
  imageListQuery: PropTypes.object.isRequired,
  vlogsListQuery: PropTypes.object.isRequired,
};
HomeContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.faqListQuery), {
      name: 'faqListQuery',
    }),
    graphql(gql(queries.contentListQuery), {
      name: 'sliderNewsListQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            page: '1',
            perPage: '5',
            importand: 'true',
            category: 'news',
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        };
      },
    }),
    graphql(gql(queries.contentListQuery), {
      name: 'vlogListQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            page: '1',
            perPage: '5',
            category: 'vlog',
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        };
      },
    }),
    graphql(gql(queries.imageListQuery), {
      name: 'imageListQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            type: 'intro',
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        };
      },
    }),
    graphql(gql(queries.companiesTopQuery), {
      name: 'companiesTopQuery',
    })
  )(HomeContainer)
);
