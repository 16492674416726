import React from 'react';
import { Container, Row, Col } from 'rsuite';
import { SRLWrapper } from 'simple-react-lightbox';
import bgImg from '../../../assets/img/page-header-bg.png';
import Head from '../../common/components/Head';
class Greeting extends React.Component {
  render() {
    const { contents, head } = this.props;
    const greeting = contents[0];
    return (
      <div>
        <section className="greeting-page">
          <Head title={greeting.title} />
          <Container className="min">
            <h3 className="section-title">{greeting.title}</h3>
            <div className="img-wrapper">
              <SRLWrapper>
                <img src={head ? head.pictures[0] : bgImg} alt="bg" />
              </SRLWrapper>
            </div>
            <section className="greeting-content">
              <Row gutter={60}>
                <Col md={6} sm={24}>
                  <h6 dangerouslySetInnerHTML={{ __html: greeting.description }} />
                </Col>
                <Col md={18} sm={24}>
                  <p dangerouslySetInnerHTML={{ __html: greeting.content }} />
                </Col>
              </Row>
            </section>
          </Container>
        </section>
      </div>
    );
  }
}

export default Greeting;
