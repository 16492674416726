import React from 'react';
import { Route } from 'react-router-dom';
import Contact from './containers/Contact';
import queryString from 'query-string';
import Faq from './containers/Faq';

const routes = () => [
  <Route
    path="/contact"
    exact
    key="/contact"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <Contact queryParams={queryParams} history={history} />;
    }}
  />,

  <Route
    path="/faq"
    exact
    key="/faq"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <Faq queryParams={queryParams} history={history} />;
    }}
  />
];

export default routes;
