import React from "react";
import {
  Button,
  ButtonToolbar,
  Form,
  FormGroup,
  HelpBlock,
  FormControl,
  ControlLabel,
  SelectPicker,
  DatePicker,
  Checkbox,
  Alert,
} from "rsuite";
import Editor from "../../../../common/components/Editor";
import Uploader from "../../../../common/components/Uploader";
import { Avatar } from "antd";
import moment from "moment";
import { categories } from "../../../constants";
export default class UserCreate extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props.detail);
    this.state = {
      formValue: props.detail || {},
      category: props.detail.category || "",
      content: props.detail.content || "",
      editor: props.detail.content || "",
      date: props.detail.createdAt || Date.now().toString(),
      pictures: props.detail.pictures || "",
      isSlider: props.detail.importand || false,
      isFeatured: props.detail.pin || false,
      titleValidate: false,
      pictureValidate: false,
      categoryValidate: false,
      contentValidate: false,
    };
  }

  errorStyles = (errorVisible) => {
    return {
      display: errorVisible ? "block" : "none",
      color: "red",
      marginTop: 6,
    };
  };

  onEditorChange = (content) => {
    this.setState({ editor: content });
  };

  onDateChange = (date) => {
    this.setState({ date: date });
  };

  onFormChange = (formValue) => {
    this.setState({ formValue });
  };

  onCategoryChange = (value) => {
    this.setState({ category: value });
  };

  onFileChange = (files) => {
    this.setState({ pictures: files });
  };

  handleChange(name, files) {
    this.setState({ [name]: files });
  }

  handleSliderChange = () => {
    this.setState({
      isSlider: !this.state.isSlider,
    });
  };

  handleFeaturedChange = () => {
    this.setState({
      isFeatured: !this.state.isFeatured,
    });
  };

  onSubmit = () => {
    const {
      formValue,
      category,
      editor,
      date,
      pictures,
      isFeatured,
      isSlider,
    } = this.state;
    const { detail, contentEdit, contentAdd } = this.props;
    if (!formValue.title) {
      return Alert.error("Та Гарчиг талбарыг заавал бөглөх шаардлагатай.");
    }

    if (!pictures) {
      return Alert.error("Та Thumbnail талбарыг заавал бөглөх шаардлагатай.");
    }

    if (!category) {
      return Alert.error("Та Төрөл талбарыг заавал бөглөх шаардлагатай.");
    }

    if (!editor) {
      return Alert.error("Та Агуулга талбарыг заавал бөглөх шаардлагатай.");
    }

    const params = {
      doc: {
        ...formValue,
        category: category,
        content: editor,
        createdAt: date,
        pictures: pictures ? pictures[0].url : "",
        pin: isFeatured,
        importand: isSlider,
      },
    };

    if (detail._id) {
      return contentEdit(params);
    }

    return contentAdd(params);
  };

  renderFileUpload(name, label) {
    const files = this.state[name];
    const fileList = files || [];

    // const disabled = fileList.length === 1;

    return (
      <div>
        <Uploader
          defaultFileList={fileList}
          onChange={(e) => this.handleChange(name, e)}
          disabled={false}
          label={label}
        />
        {fileList.length > 0 && fileList[0].url && (
          <Avatar shape="square" size={64} src={fileList[0].url} />
        )}
      </div>
    );
  }

  render() {
    const {
      content,
      category,
      formValue,
      date,
      pictures,
      isSlider,
      isFeatured,
    } = this.state;
    return (
      <Form
        onChange={(formValue) => {
          this.onFormChange(formValue);
        }}
        formValue={formValue}
      >
        <FormGroup>
          <ControlLabel>Гарчиг</ControlLabel>
          <FormControl name="title" style={{ width: 926 }} />
          <HelpBlock>Заавал бөглөх</HelpBlock>
        </FormGroup>
        <FormGroup>
          <ControlLabel>Огноо</ControlLabel>
          <DatePicker
            name="createdAt"
            style={{ width: 280 }}
            value={moment(date, "x").format("YYYY-MM-DD")}
            onChange={this.onDateChange}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Thumbnail</ControlLabel>
          {this.renderFileUpload("pictures", "Зураг оруулах")}
          <HelpBlock>Заавал бөглөх</HelpBlock>
        </FormGroup>
        {pictures && (
          <div className="ant-avatar">
            <img src={pictures} alt="" />
          </div>
        )}
        {/* <FormGroup>
          <ControlLabel>Зурагны тайлбар</ControlLabel>
          <FormControl name="imageNote" />
        </FormGroup> */}
        <FormGroup>
          <ControlLabel>Төрөл</ControlLabel>
          <FormControl
            accepter={SelectPicker}
            placement="topStart"
            name="category"
            data={categories}
            style={{ width: 224 }}
            searchable={false}
            onChange={(value) => this.onCategoryChange(value)}
            defaultValue={category ? category : ""}
          />
          <HelpBlock>Заавал бөглөх</HelpBlock>
        </FormGroup>
        <div>
          <Checkbox
            name="isSlider"
            checked={isSlider}
            onChange={(e) => this.handleSliderChange(e)}
          >
            Home Slider
          </Checkbox>
          <Checkbox
            name="isFeatured"
            checked={isFeatured}
            onChange={(e) => this.handleFeaturedChange(e)}
          >
            {" "}
            Featured
          </Checkbox>
        </div>
        {/* <FormGroup>
          <ControlLabel>Товч тайлбар</ControlLabel>
          <FormControl
            style={{ width: 926 }}
            componentClass="textarea"
            rows={5}
            name="description"
          />
        </FormGroup> */}
        <FormGroup>
          <ControlLabel>Агуулга</ControlLabel>
          <HelpBlock>Заавал бөглөх</HelpBlock>
          <Editor
            name="content"
            onChange={this.onEditorChange}
            content={content}
          />
        </FormGroup>
        <FormGroup>
          <ButtonToolbar>
            <Button appearance="primary" onClick={this.onSubmit}>
              Submit
            </Button>
            <Button appearance="default" onClick={this.props.onCancel}>
              Cancel
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    );
  }
}
