const lawAdd = `
 mutation lawAdd($doc: LawInput) {
    lawAdd(doc: $doc) {
      _id
    }
  }
`;

const lawEdit = `
 mutation lawEdit($doc: LawInput) {
  lawEdit(doc: $doc) {
      _id
    }
  }
`;

const lawRemove = `
  mutation lawRemove($_id: String) {
    lawRemove(_id: $_id)
  }
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  lawEdit,
  lawAdd,
  lawRemove,
};
