import React from "react";
import { Col, Container, Row } from "rsuite";
import { PageHeader } from "../../common/components/PageHeader";
import pLogo from "../../../assets/img/p-logo.png";
import { NewsCard } from "../../common/components/NewsCard";
class Activity extends React.Component {
  renderlogo() {
    return (
      <a href="/" target="_blank" rel="noopener">
        <img src={pLogo} alt="partner-logo" />
      </a>
    );
  }
  render() {
    const activity = this.props.contents.filter(
      (content) => content.category === "activity"
    );
    const activity1 = this.props.contents.filter(
      (content) => content.category === "activity1"
    );
    const activity2 = this.props.contents.filter(
      (content) => content.category === "activity2"
    );
    const activity3 = this.props.contents.filter(
      (content) => content.category === "activity3"
    );
    const { head } = this.props;
    return (
      <div className="activity-page">
        <PageHeader bg={head && head.pictures[0]} title={head && head.title} />
        <Container>
          <section>
            <div className="activity-intro">
              {activity[0] && (
                <Row gutter={60}>
                  <Col sm={24} md={7}>
                    <h3>{activity[0].title}</h3>
                  </Col>
                  <Col sm={24} md={17}>
                    <div
                      dangerouslySetInnerHTML={{ __html: activity[0].content }}
                    />
                  </Col>
                </Row>
              )}
            </div>
          </section>
          <div>
            <section className="activity-row">
              <h3>Бие даан амьдрахад дэмжих хөтөлбөр</h3>
              <Row gutter={50}>
                {activity1 &&
                  activity1.map((post) => (
                    <Col md={8} sm={12} xs={24} key={post._id}>
                      <NewsCard type="desc-card" nolink={true} data={post} />
                    </Col>
                  ))}
                {activity2 &&
                  activity2.map((post) => (
                    <Col md={8} sm={12} xs={24} key={post._id}>
                      <NewsCard nolink={true} type="desc-card" data={post} />
                    </Col>
                  ))}
                {activity3 &&
                  activity3.map((post) => (
                    <Col md={8} sm={12} xs={24} key={post._id}>
                      <NewsCard type="desc-card" nolink={true} data={post} />
                    </Col>
                  ))}
              </Row>
            </section>
            {/* <section className="activity-row">
              <h3>Нийгмийн оролцоог дэмжих хөтөлбөр</h3>

              <Row gutter={50}>
                {activity2 &&
                  activity2.map((post) => (
                    <Col md={8} sm={12} xs={24} key={post._id}>
                      <NewsCard nolink={true} type="desc-card" data={post} />
                    </Col>
                  ))}
              </Row>
            </section>
            <section className="activity-row">
              <h3>Боловсролын хөтөлбөр</h3>
              <Row gutter={50}>
                {activity3 &&
                  activity3.map((post) => (
                    <Col md={8} sm={12} xs={24} key={post._id}>
                      <NewsCard type="desc-card" nolink={true} data={post} />
                    </Col>
                  ))}
              </Row>
            </section> */}
          </div>
        </Container>
      </div>
    );
  }
}

export default Activity;
