import React from 'react';
import About from '../components/About';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import queries from '../graphql/queries';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
class AboutContainer extends React.Component {
  render() {
    const { imageListQuery, headQuery, aboutQuery, infoQuery } = this.props;

    if (imageListQuery && imageListQuery.loading) {
      return null;
    }

    if (headQuery && headQuery.loading) {
      return null;
    }

    if (aboutQuery && aboutQuery.loading) {
      return null;
    }

    if (infoQuery && infoQuery.loading) {
      return null;
    }

    const images = imageListQuery.images || [];
    const head = headQuery.contents && headQuery.contents ? headQuery.contents[0] : '';
    const about = aboutQuery.contents && aboutQuery.contents ? aboutQuery.contents[0] : '';
    const info = infoQuery.contents && infoQuery.contents ? infoQuery.contents : '';

    const updatedProps = {
      ...this.props,
      images,
      head,
      about,
      info,
    };
    return <About {...updatedProps} />;
  }
}

const propTypes = {
  imageListQuery: PropTypes.object.isRequired,
  headQuery: PropTypes.object,
  aboutQuery: PropTypes.object,
  infoQuery: PropTypes.object,
};
AboutContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.imageListQuery), {
      name: 'imageListQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            type: 'about',
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        };
      },
    }),
    graphql(gql(queries.contentListQuery), {
      name: 'headQuery',
      options: () => {
        return {
          variables: {
            category: 'about-head',
          },
        };
      },
    }),
    graphql(gql(queries.contentListQuery), {
      name: 'aboutQuery',
      options: () => {
        return {
          variables: {
            title: 'БИДНИЙ ТУХАЙ',
          },
        };
      },
    }),
    graphql(gql(queries.contentListQuery), {
      name: 'infoQuery',
      options: () => {
        return {
          variables: {
            category: 'intro',
            importand: 'true',
          },
        };
      },
    })
  )(AboutContainer)
);
