import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Alert } from 'rsuite';
import FaqList from '../components/FaqList';
import { mutations, queries } from '../graphql';
class FaqListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.faqRemove = this.faqRemove.bind(this);
  }

  faqRemove = (variables) => {
    const { faqRemoveMutation } = this.props;
    faqRemoveMutation({ variables: { _id: variables } })
      .then(() => {
        Alert.success('Амжилттай устгалаа');
      })
      .catch((err) => {
        Alert.error(err.message);
      });
  };

  render() {
    const { faqListQuery } = this.props;
    if (faqListQuery && faqListQuery.loading) {
      return null;
    }

    const faqs = faqListQuery.faqs || null;

    const updatedProps = {
      faqs,
      faqRemove: this.faqRemove,
    };
    return <FaqList {...updatedProps} />;
  }
}
const propTypes = {
  faqListQuery: PropTypes.object.isRequired,
  faqRemove: PropTypes.func,
};
FaqListContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.faqListQuery), {
      name: 'faqListQuery',
    }),
    graphql(gql(mutations.faqRemove), {
      name: 'faqRemoveMutation',
    })
  )(FaqListContainer)
);
