import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "rsuite";

const propTypes = {
  title: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  icon: PropTypes.string,
  btnText: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  content: PropTypes.func,
};

class ModalTrigger extends Component {
  state = {
    show: false,
    autoCompleteResult: [],
    formLayout: "",
    enabled: true,
    readOnly: false,
    value: "",
    events: [],
  };

  showModal = () => {
    this.setState({
      show: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      show: false,
    });
  };
  handleCancel = (e) => {
    this.setState({
      show: false,
    });
  };

  render() {
    const {
      title,
      btnText,
      btnClassName,
      iconClassName,
      btnColor,
      content,
      law,
      category,
      date,
      file,
    } = this.props;

    return (
      <Fragment>
        {law ? (
          <div className="law-item">
            <span>{category}</span>
            <h3>{title}</h3>
            <div className="action">
              <Button
                style={{ color: "#019266", border: "1px solid #019266" }}
                onClick={this.showModal}
              >
                Дэлгэрэнгүй
              </Button>
              {file && (
                <a
                  style={{ color: "#fff", background: "#019266" }}
                  className="rs-btn rs-btn-default"
                  href={file}
                  download
                >
                  Татах
                </a>
              )}
            </div>
            <time>{date}</time>
          </div>
        ) : (
          <Button
            color={btnColor}
            className={btnClassName}
            onClick={this.showModal}
          >
            {iconClassName ? <i className={iconClassName}></i> : null}
            &nbsp;
            {btnText}
          </Button>
        )}

        <Modal
          size={this.props.size}
          show={this.state.show}
          onHide={this.handleCancel}
        >
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          {content({ onCancel: this.handleCancel })}
        </Modal>
      </Fragment>
    );
  }
}

ModalTrigger.propTypes = propTypes;

export default ModalTrigger;
