import React from 'react';
import ReadMore from './ReadMore';
const FaqWidget = (props) => {
  return (
    <div className="h-item faq-home">
      <h3 className="common-title">Түгээмэл асуулт хариулт</h3>
      <div className="desc">Манай үйл ажиллагаатай холбоотой дэлгэрэнгүй….</div>
      <div className="faq-lists">
        <p>
          {props.data &&
            props.data.slice(0, 3).map((faq) => (
              <span key={faq._id}>
                {faq.question}
                <br />
              </span>
            ))}
        </p>
      </div>
      <ReadMore to="/faq" />
    </div>
  );
};

export default FaqWidget;
