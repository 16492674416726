import React from 'react';
import { withRouter } from 'react-router-dom';
import ActivityReport from '../components/ActivityReport';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { queries } from '../graphql';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
class ActivityReportContainer extends React.Component {
  render() {
    const { headQuery, getLawsQuery } = this.props;

    if (getLawsQuery && getLawsQuery.loading) {
      return null;
    }

    if (headQuery && headQuery.loading) {
      return null;
    }

    const laws = getLawsQuery.laws || [];
    const head = headQuery.contents && headQuery.contents ? headQuery.contents[0] : '';
    const updatedProps = {
      ...this.props,
      laws,
      head,
    };

    return <ActivityReport {...updatedProps} />;
  }
}
const propTypes = {
  headQuery: PropTypes.object,
  getLawsQuery: PropTypes.object,
};
ActivityReportContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.getLawsListQuery), {
      name: 'getLawsQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            beginDate: queryParams.beginDate,
            endDate: queryParams.endDate,
            category: 'activity-reports',
            title: queryParams.title,
            importand: queryParams.importand,
          },
        };
      },
    }),
    graphql(gql(queries.contentListQuery), {
      name: 'headQuery',
      options: () => {
        return {
          variables: {
            category: 'law-head',
          },
        };
      },
    })
  )(ActivityReportContainer)
);
