import React from 'react';
class NewsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      content: '',
    };
  }
  handleForm = (e) => {
    e.preventDefault();
    const { name, content } = this.state;
    const { id, commentAdd } = this.props;
    const params = {
      doc: {
        userName: name,
        content: content,
        contentId: id,
      },
    };
    return commentAdd(params);
  };
  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };
  handleContentChange = (e) => {
    this.setState({ content: e.target.value });
  };
  render() {
    const { comments } = this.props;
    const { name, content } = this.state;
    return (
      <div className="comment">
        <h3>Сэтгэгдэл бичих</h3>
        <form onSubmit={this.handleForm}>
          <div>
            <label>Нэр</label>
            <input name="name" placeholder="Нэр" value={name} onChange={this.handleNameChange} required />
          </div>
          <div>
            <label>Сэтгэгдэл бичнэ үү</label>
            <textarea name="content" placeholder="Текст" value={content} required onChange={this.handleContentChange} />
          </div>
          <button type="submit">Нийтлэх</button>
        </form>
        {comments && comments.length ? (
          <div className="comments">
            <h3>Сэтгэгдэлүүд</h3>
            {comments.map((comment) => (
              <div className="comment-item" key={comment._id}>
                <h6>{comment.userName}</h6>
                <p>{comment.content}</p>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}

export default NewsDetail;
