import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Button, ButtonToolbar, Table } from 'rsuite';
import ModalTrigger from '../../../../common/components/ModalTrigger';
import FaqForm from '../containers/FaqForm';
const { Column, HeaderCell, Cell } = Table;

export default class DashboardMark extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      data: props.faqs || {},
      show: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  close() {
    this.setState({ show: false });
  }

  open() {
    this.setState({ show: true });
  }

  handleChange(value) {
    this.setState({ text: value });
  }

  onRemove = (_id) => {
    confirmAlert({
      title: 'Та устгахдаа итгэлтэй байна уу?',
      buttons: [
        {
          label: 'Устгах',
          onClick: (e) => this.props.faqRemove(_id),
        },
        {
          label: 'Болих',
        },
      ],
    });
  };

  renderActions = (_id) => {
    return (
      <span className='action-wrapper'>
        <ModalTrigger
          iconClassName={'fas fa-edit'}
          size='lg'
          btnColor={'cyan'}
          content={(props) => <FaqForm {...props} id={_id} />}
        ></ModalTrigger>
        &nbsp;
        <Button
          color='red'
          appearance='ghost'
          onClick={() => this.onRemove(_id)}
        >
          <i className='fas fa-trash'></i>
        </Button>
      </span>
    );
  };

  render() {
    return (
      <div>
        <h4 className='d-title'>FAQ</h4>
        <div className='admin-bar'>
          <ButtonToolbar>
            <ModalTrigger
              btnText={'FAQ нэмэх'}
              btnClassName={'create - btn'}
              iconClassName={'fas fa-plus'}
              btnColor={'blue'}
              content={(props) => <FaqForm {...props} />}
            ></ModalTrigger>
          </ButtonToolbar>
        </div>
        <Table height={400} data={this.state.data} onRowClick={(data) => {}}>
          <Column width={660}>
            <HeaderCell>Асуулт</HeaderCell>
            <Cell dataKey='question' />
          </Column>
          <Column width={140} fixed='right'>
            <HeaderCell>Үйлдэл</HeaderCell>

            <Cell>{(rowData) => this.renderActions(rowData._id)}</Cell>
          </Column>
        </Table>
      </div>
    );
  }
}
