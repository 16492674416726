const feedbackRemove = `
  mutation feedbackRemove($_id: String) {
    feedbackRemove(_id: $_id)
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  feedbackRemove,
};
