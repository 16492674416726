import React from 'react';
import {
  Table,
  Button,
  ButtonToolbar,
  Modal,
  Input,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  DatePicker,
  SelectPicker,
} from 'rsuite';
import ReactQuill from 'react-quill';
const { Column, HeaderCell, Cell } = Table;

export default class MarkHistory extends React.Component {
  constructor(props) {
    super(props);

    const fakeData = [
      {
        id: 1,
        author: 'uuganbayar',
        title: 'Lorem ipsum',
        date: '2021-01-20',
      },
      {
        id: 2,
        author: 'uuganbayar',
        title: 'Lorem ipsum',
        date: '2021-01-20',
      },
      {
        id: 3,
        author: 'uuganbayar',
        title: 'Lorem ipsum',
        date: '2021-01-20',
      },
    ];
    this.state = {
      data: fakeData,
      show: false,
      text: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }
  handleChange(value) {
    this.setState({ text: value });
  }
  render() {
    const data = [
      {
        label: 'Admin',
        value: 'admin',
      },
      {
        label: 'Expert',
        value: 'expert',
      },
      {
        label: 'User',
        value: 'user',
      },
    ];
    return (
      <div>
        <h4 className='d-title'>Үнэлгээний түүх</h4>
        <div className='admin-bar'>
          <div className='admin-filter'>
            <Input placeholder='title' />
            <Input placeholder='date' />
            <SelectPicker placeholder='Author' data={data} />
            <SelectPicker placeholder='Category' data={data} />
            <Button color='blue' appearance='ghost'>
              {' '}
              <i className='fas fa-search'></i>
            </Button>
          </div>
          <ButtonToolbar>
            <Button color='blue' onClick={this.open} className='create-btn'>
              <i className='fas fa-plus'></i> Агуулга нэмэх
            </Button>
          </ButtonToolbar>
        </div>
        <Modal
          size='lg'
          className='d-content'
          show={this.state.show}
          onHide={this.close}
        >
          <Modal.Header>
            <Modal.Title>Агуулга нэмэх</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup>
                <ControlLabel>Гарчиг</ControlLabel>
                <FormControl name='title' />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Огноо</ControlLabel>
                <DatePicker />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Агуулга</ControlLabel>
                <ReactQuill
                  value={this.state.text}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <ButtonToolbar>
                  <Button appearance='primary'>Submit</Button>
                  <Button appearance='default' onClick={this.close}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </FormGroup>
            </Form>
          </Modal.Body>
        </Modal>
        <Table
          height={400}
          data={this.state.data}
          onRowClick={(data) => {
            console.log(data);
          }}
        >
          <Column width={70} align='center' fixed>
            <HeaderCell>Id</HeaderCell>
            <Cell dataKey='id' />
          </Column>
          <Column width={360}>
            <HeaderCell>Гарчиг</HeaderCell>
            <Cell dataKey='title' />
          </Column>
          <Column width={200}>
            <HeaderCell>Үнэлгээг хийсэн</HeaderCell>
            <Cell dataKey='author' />
          </Column>
          <Column width={120}>
            <HeaderCell>Огноо</HeaderCell>
            <Cell dataKey='date' />
          </Column>

          <Column width={140} fixed='right'>
            <HeaderCell>Үйлдэл</HeaderCell>

            <Cell>
              {(rowData) => {
                function handleAction() {
                  alert(`id:${rowData.id}`);
                }
                return (
                  <span className='action-wrapper'>
                    <Button color='cyan' onClick={this.open}>
                      <i className='fas fa-edit'></i>
                    </Button>
                    <Button
                      color='red'
                      appearance='ghost'
                      onClick={handleAction}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </span>
                );
              }}
            </Cell>
          </Column>
        </Table>
      </div>
    );
  }
}
