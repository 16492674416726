import React, { Fragment } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorHtml: this.props.content || '' };
  }

  handleChange = (html) => {
    this.setState({ editorHtml: html });
    this.props.onChange(html);
  };

  render() {
    return (
      <Fragment>
        <ReactQuill
          ref="editor"
          modules={Editor.modules}
          formats={Editor.formats}
          theme="snow"
          onChange={this.handleChange}
          value={this.state.editorHtml}
          placeholder="Энд бичнэ ..."
          name={this.props.name}
        />
      </Fragment>
    );
  }
}

export const toHTML = (Editor.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link', 'image', 'video'],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    [{ list: 'ordered' }, { list: 'bullet' }, { script: 'sub' }, { script: 'super' }, { indent: '-1' }, { indent: '+1' }],
    ['clean'],
    ['code-block'],
  ],
  imageResize: {
    parchment: Quill.import('parchment'),
  },
});
Editor.formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'script', 'indent', 'link', 'image', 'video', 'align', 'code-block'];

export default Editor;
