import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import ForgotPassword from '../components/ForgotPassword.js';
import { mutations } from '../graphql';
import { Alert } from 'rsuite';
const ForgotPasswordContainer = props => {
  const { forgotPasswordMutation } = props;

  const forgotPassword = variables => {
    forgotPasswordMutation({ variables })
      .then(() => {
        Alert.success('Амжилттай и-мэйл илгээгдлээ');
      })
      .catch(e => {
        Alert.error(e.message);
      });
  };

  const updatedProps = {
    ...props,
    forgotPassword
  };

  return <ForgotPassword {...updatedProps} />;
};

ForgotPasswordContainer.propTypes = {
  forgotPasswordMutation: PropTypes.func
};

export default withRouter(
  compose(
    graphql(gql(mutations.forgotPassword), {
      name: 'forgotPasswordMutation'
    })
  )(ForgotPasswordContainer)
);
