const companyAdd = `
    mutation companyAdd($doc: CompanyAddInput){
        companyAdd(doc: $doc){
            _id
        }
    }
`;

const companyEdit = `
    mutation companyEdit($doc: CompanyInput){
        companyEdit(doc: $doc){
            _id
        }
    }
`;

const companyRate = `
    mutation companyRate($_id: String, $rate: Float){
        companyRate(_id: $_id, rate: $rate)
    }
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  companyAdd,
  companyEdit,
  companyRate,
};
