import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Alert } from 'rsuite';
import JobList from '../components/JobList';
import { mutations, queries } from '../graphql';

class JobListContainer extends React.Component {
  careerRemove = (variables) => {
    const { careerRemoveMutation } = this.props;
    careerRemoveMutation({ variables: { _id: variables } })
      .then(() => {
        Alert.success('Амжилттай устгалаа');
      })
      .catch((err) => {
        Alert.error('Алдаа гарлаа');
      });
  };

  render() {
    const { careerListQuery, headQuery } = this.props;

    if (careerListQuery && careerListQuery.loading) {
      return null;
    }

    if (headQuery && headQuery.loading) {
      return null;
    }

    const careers = careerListQuery.careers || [];
    const head =
      headQuery.contents && headQuery.contents ? headQuery.contents[0] : '';

    const updatedProps = {
      ...this.props,
      careers,
      head,
      careerRemove: this.careerRemove,
    };
    return <JobList {...updatedProps} />;
  }
}

const propTypes = {
  CareerListQuery: PropTypes.object,
  headQuery: PropTypes.object,
};
JobListContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.careerListQuery), {
      name: 'careerListQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            jobType: queryParams.jobType,
            workingHours: queryParams.workingHours,
            location: queryParams.location,
            title: queryParams.title,
            page: queryParams.page,
            perPage: queryParams.perPage,
            type: queryParams.type,
          },
        };
      },
    }),
    graphql(gql(mutations.careerRemove), {
      name: 'careerRemoveMutation',
      options: () => ({
        refetchQueries: ['careers'],
      }),
    }),
    graphql(gql(queries.contentListQuery), {
      name: 'headQuery',
      options: () => {
        return {
          variables: {
            category: 'career-head',
          },
        };
      },
    })
  )(JobListContainer)
);
