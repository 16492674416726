import React from 'react';
import { Route } from 'react-router-dom';
import Login from './containers/Login';
import ForgotPasswordContainer from './containers/ForgotPassword';
import ResetPasswordContainer from './containers/ResetPassword';
import queryString from 'query-string';
const routes = () => [
  <Route
    path="/login"
    exact
    key="/login"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <Login queryParams={queryParams} history={history} />;
    }}
  />,

  <Route
    path="/forgotPassword"
    exact
    key="/forgotPassword"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return (
        <ForgotPasswordContainer queryParams={queryParams} history={history} />
      );
    }}
  />,

  <Route
    path="/reset-password"
    exact
    key="/reset-password"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return (
        <ResetPasswordContainer queryParams={queryParams} history={history} />
      );
    }}
  />
];

export default routes;
