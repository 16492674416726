import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { queries } from '../graphql';
import ActivityReportDetail from '../components/ActivityReportDetail';
import { flowRight as compose } from 'lodash';
import { Spin } from 'antd';

class ActivityReportDetailContainer extends React.Component {
  render() {
    const { id, lawDetailQuery } = this.props;

    if (lawDetailQuery.loading) {
      return <Spin size="large" />;
    }
    const lawDetail = lawDetailQuery.lawDetail || {};
    const updatedProps = {
      ...this.props,
      id,
      lawDetail,
    };

    return <ActivityReportDetail {...updatedProps} />;
  }
}

ActivityReportDetailContainer.propTypes = {
  id: PropTypes.string,
  lawDetailQuery: PropTypes.object.isRequired,
};

export default compose(
  graphql(gql(queries.lawDetailQuery), {
    name: 'lawDetailQuery',
    options: (props) => {
      return {
        variables: {
          _id: props.id,
        },
      };
    },
  })
)(ActivityReportDetailContainer);
