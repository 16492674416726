import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { Alert } from 'rsuite';
import CareerForm from '../components/CareerForm';
import { mutations } from '../graphql';
class CareerFormContainer extends React.Component {
  careerAdd = (variables) => {
    const { careerAdd } = this.props;
    careerAdd({ variables })
      .then(() => {
        Alert.success('Таны зар амжилттай нийтлэгдлээ.');
        this.props.history.push('/job-list');
      })
      .catch((err) => {
        Alert.error('Алдаа гарлаа.');
      });
  };

  render() {
    const updatedProps = {
      ...this.props,
      careerAdd: this.careerAdd,
    };
    return <CareerForm {...updatedProps} />;
  }
}

CareerFormContainer.propTypes = {
  id: PropTypes.string,
  careerAdd: PropTypes.func,
};
export default compose(
  graphql(gql(mutations.careerAdd), {
    name: 'careerAdd',
    options: ({ doc }) => ({
      variables: {
        doc,
      },
    }),
  })
)(CareerFormContainer);
