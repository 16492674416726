import React from 'react';
import { Row, Col, Container } from 'rsuite';
import Slider from 'react-slick';
class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const { contents } = this.props;
    const titles = contents.map((content) => content.title);

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      // appendDots: (dots) => (
      //   <div>
      //     <ul style={{ margin: '0px' }}> {dots} </ul>
      //   </div>
      // ),
      customPaging: function (i) {
        return (
          <div className="t-dot">
            <span>{titles[i]}</span>
            <div className="circle-wrapper">
              <div className="circle"></div>
            </div>
          </div>
        );
      },
    };

    const settingsDots = {
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 10,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="timeline-page">
        <section>
          <Container className="min">
            <h3 className="section-title">Түүхэн замнал</h3>

            <Slider asNavFor={this.state.nav2} ref={(slider) => (this.slider1 = slider)} {...settings}>
              {contents.map((timeline) => (
                <div className="timeline-item" key={timeline._id}>
                  <Row gutter={50} className="flex-v-center">
                    <Col md={16} sm={12} xs={24}>
                      <img src={timeline.pictures} alt="bg" />
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <div className="caption">
                        <time>{timeline.title}</time>

                        <h6 dangerouslySetInnerHTML={{ __html: timeline.description }} />
                        <p dangerouslySetInnerHTML={{ __html: timeline.content }} />
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </Slider>
            <div className="sslick-dots">
              <ul>
                <Slider
                  asNavFor={this.state.nav1}
                  ref={(slider) => (this.slider2 = slider)}
                  slidesToShow={10}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  infinite={false}
                  {...settingsDots}
                >
                  {contents.map((timeline) => (
                    <li className="timeline-item" key={timeline._id}>
                      <div className="t-dot">
                        <span>{timeline.title}</span>
                        <div className="circle-wrapper">
                          <div className="circle"></div>
                        </div>
                      </div>
                    </li>
                  ))}
                </Slider>
              </ul>
            </div>
            <hr />
          </Container>
        </section>
      </div>
    );
  }
}

export default Timeline;
