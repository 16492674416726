import React from "react";
import { Container, Row, Col } from "rsuite";
import ReadMore from "../../common/components/ReadMore";
import FaqWidget from "../../common/components/FaqWidget";
import mapImg from "../../../assets/img/map.png";
import playImg from "../../../assets/img/play.png";
import orgImg from "../../../assets/img/org_img.png";
import { Link } from "react-router-dom";
import Guidance from "./Guidance";
import Head from "../../common/components/Head";
import Slider from "react-slick";
class Home extends React.Component {
  render() {
    const { contents, vlogs, images, companies } = this.props;
    console.log(companies);
    const sliderNews = contents;
    const sliderSettings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 6000,
    };
    return (
      <div className="home">
        <Head title="Нүүр хуудас" />
        <section className="home-banner">
          <Container>
            <Row className="flex-center">
              <Col md={10} xs={24}>
                <div className="flex-h-center">
                  <div className="caption">
                    <h2>Танилцуулга</h2>
                    <p>
                      Хөгжлийн бэрхшээлтэй хүмүүсийн хөдөлмөр эрхлэлт, хүртээмж,
                      тэгш хамран сургах зэрэг асуудлаарх олон улсын гэрээ,
                      зөвлөмж, Монгол улсын хууль, тогтоол, тушаал, хөтөлбөр
                    </p>
                    <ReadMore to="/intro" />
                  </div>
                </div>
              </Col>
              <Col md={14} xs={24}>
                <div className="grid-images">
                  <div
                    id="grid1"
                    alt={images[0] && images[0].description}
                    style={{
                      background: `url(${images[0] && images[0].images})`,
                    }}
                  ></div>
                  <div
                    id="grid2"
                    alt={images[1] && images[1].description}
                    style={{
                      background: `url(${images[1] && images[1].images})`,
                    }}
                  ></div>
                  <div id="grid3" className="shape yellow"></div>
                  <div id="grid4" className="blank"></div>
                  <div
                    id="grid5"
                    alt={images[2] && images[2].description}
                    style={{
                      background: `url(${images[2] && images[2].images})`,
                    }}
                  ></div>
                  <div id="grid6" className="shape teal"></div>
                  <div id="grid7" className="shape purple"></div>
                  <div
                    id="grid8"
                    alt={images[3] && images[3].description}
                    style={{
                      background: `url(${images[3] && images[3].images})`,
                    }}
                  ></div>
                  <div
                    id="grid9"
                    alt={images[4] && images[4].description}
                    style={{
                      background: `url(${images[4] && images[4].images})`,
                    }}
                  ></div>
                  <div id="grid10" className="shape pink"></div>
                  <div id="grid11" className="blank"></div>
                  <div id="grid12" className="blank"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Row gutter={60}>
              <Col md={8} xs={24}>
                <div className="h-item">
                  <h3 className="common-title">Хүртээмжтэй орчин</h3>
                  <div className="home-env">
                    <img alt="home" src={mapImg} />
                    <div className="caption">
                      <p>
                        Бидний алсын хараа, эрхэм зориолго бол хүртээмжтэй
                        эрүүл, аюулгүй, тогтвортой хөгжлийг бүтээх юм
                      </p>
                      <ReadMore to="/mark-map" />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={16} xs={24}>
                <div className="h-item">
                  <h3 className="common-title">Мэдээ мэдээлэл</h3>
                  <Slider {...sliderSettings} className="home-slider">
                    {sliderNews.map((slide) => (
                      <div key={slide._id}>
                        <div
                          role="img"
                          alt={slide.imageNote}
                          className="bg-image"
                          style={{
                            background: `linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url(${slide.pictures})`,
                          }}
                        >
                          <Link to={`/news-detail/${slide._id}`}>
                            <h4>{slide.title}</h4>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Row gutter={60}>
              <Col md={8} xs={24}>
                <FaqWidget data={this.props.faqs} />
              </Col>
              <Col md={8} xs={24}>
                <div className="h-item vlog-home">
                  <h3 className="common-title vlog-title">Влог & Блог</h3>
                  <Slider
                    {...sliderSettings}
                    className="home-slider vlog-slider"
                  >
                    {vlogs.map((vlog) => (
                      <div>
                        <Link to={`/news-detail/${vlog._id}`}>
                          <div
                            className="bg-image"
                            style={{
                              background: `linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url(${vlog.pictures})`,
                            }}
                          >
                            <img src={playImg} alt="play" />
                          </div>

                          <h4 className="vlog-caption">{vlog.title}</h4>
                        </Link>
                        <ReadMore to={`/news-detail/${vlog._id}`} />
                      </div>
                    ))}
                  </Slider>
                </div>
              </Col>
              <Col md={8} xs={24}>
                <div className="h-item best-companies">
                  <h3 className="common-title vlog-title">
                    Хамгийн хүртээмжтэй байгууллагууд
                  </h3>
                  {companies.length > 0 ? (
                    <ul className="org-lists">
                      {companies.map((company, i) => (
                        <li key={company._id}>
                          <span>{i + 1}</span>
                          <h5>{company.name}</h5>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <ul className="org-lists">
                      <li>
                        <span>1</span>
                        <img src={orgImg} alt="org-img" />
                        <h5>Монгол Улсын Их Сургууль</h5>
                      </li>
                      <li>
                        <span>2</span>
                        <img src={orgImg} alt="org-img" />
                        <h5>Монгол Улсын Их Сургууль</h5>
                      </li>
                      <li>
                        <span>3</span>
                        <img src={orgImg} alt="org-img" />
                        <h5>Монгол Улсын Их Сургууль</h5>
                      </li>
                      <li>
                        <span>3</span>
                        <img src={orgImg} alt="org-img" />
                        <h5>Монгол Улсын Их Сургууль</h5>
                      </li>
                      <li>
                        <span>3</span>
                        <img src={orgImg} alt="org-img" />
                        <h5>Монгол Улсын Их Сургууль</h5>
                      </li>
                      <li>
                        <span>3</span>
                        <img src={orgImg} alt="org-img" />
                        <h5>Монгол Улсын Их Сургууль</h5>
                      </li>
                    </ul>
                  )}

                  <ReadMore to="/mark-map" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Guidance />
      </div>
    );
  }
}

export default Home;
