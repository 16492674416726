import React from 'react';
import { Container, Row, Col } from 'rsuite';
import { PageHeader } from '../../common/components/PageHeader';
import { SRLWrapper } from 'simple-react-lightbox';
import Head from '../../common/components/Head';
class About extends React.Component {
  render() {
    const { images, head, about, info } = this.props;
    return (
      <div>
        <Head title="Бидний тухай" />
        <PageHeader bg={head && head.pictures[0]} title={head && head.title} />
        <section>
          <Container>
            <div className="about-quotes">
              <div dangerouslySetInnerHTML={{ __html: about.content }} />
              {/* <div>
                <blockquote className="quote1">
                  <p>“Түгээмэл Хөгжил бие даан амьдрах” төв 2010 оны 3-р сард байгуулагдсан бөгөөд Монголын анхны бие даан амьдрах төв юм.</p>
                </blockquote>
              </div>
              <div>
                <blockquote className="quote2">
                  <p>Азийн болон Дэлхийн бие даан амьдрах төвүүдийн нэгдсэн сүлжээний гишүүн</p>
                  <p>Азийн 8 орон хамтарсан Кокорозаши сүлжээний гишүүн</p>
                  <p>Монголын Бие Даан амьдрах төвүүдийн үндэсний холбооны гишүүн байгууллага</p>
                </blockquote>
              </div>
              <div>
                <blockquote className="quote3">
                  <h4>Дараах 3 чиглэлээр үйл ажиллагаагаа явуулдаг:</h4>
                  <p>1. Бие даан амьдрахад дэмжин хөтөлбөр</p>
                  <p> 2. Нийгмийн оролцоог дэмжих хөтөлбөр</p>
                  <p> 3. Тэгш хамран сургах боловсролын хөтөлбөр</p>
                </blockquote>
              </div> */}
            </div>
            <div className="about-count">
              <Row gutter={50}>
                {info && info.length
                  ? info.map((item) => (
                      <Col key={item._id} sm={8} xs={24}>
                        <div className="count-item">
                          <div>
                            <img src={item.pictures[0]} alt="img" />
                          </div>
                          <div>
                            <h2>{item.description}</h2>
                            <span>{item.title}</span>
                          </div>
                        </div>
                      </Col>
                    ))
                  : null}
              </Row>
            </div>
            <div className="about-gallery">
              <SRLWrapper>
                <Row gutter={50}>
                  {images &&
                    images.map((image) => (
                      <Col xs={12} md={8}>
                        <img src={image.images} alt={image.description} />
                      </Col>
                    ))}
                </Row>
              </SRLWrapper>
            </div>
          </Container>
        </section>
      </div>
    );
  }
}

export default About;
