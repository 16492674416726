const getLawsListQuery = `
 query laws($beginDate: String, $endDate: String, $category: String, $title: String, $importand: String) {
    laws(beginDate: $beginDate, endDate: $endDate, category: $category, title: $title, importand: $importand) {
      _id
      title
      date
      category
      content
      file
    }
  }
`;
const lawDetailQuery = `
  query lawDetail($_id: String) {
    lawDetail(_id: $_id) {
      _id
      title
      date
      category
      content
      file
    }
  }
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getLawsListQuery,
  lawDetailQuery,
};
