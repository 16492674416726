import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';

const { REACT_APP_API_URL } = process.env;

// Create an http link:
const httpLink = createHttpLink({
  uri: `${REACT_APP_API_URL}/graphql`
});

// Attach user credentials
const middlewareLink = setContext(() => ({
  headers: {
    'x-refresh-token': localStorage.getItem('refreshtoken'),
    'x-token': localStorage.getItem('token'),
     'x-jwt': localStorage.getItem("jwt") || ""
  }
}));

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const {
      response: { headers }
    } = context;

    if (headers) {
      const token = headers.get('x-token');
      const refreshToken = headers.get('x-refresh-token');
      if (token) {
        localStorage.setItem('token', token);
      }
      if (refreshToken) {
        localStorage.setItem('refreshtoken', refreshToken);
      }
    }

    return response;
  });
});

// Creating Apollo-client
const client = new ApolloClient({
  link: afterwareLink.concat(middlewareLink.concat(httpLink)),
  cache: new InMemoryCache({ addTypename: false })
});

export default client;
