import React from "react";

export const LinedTitle = (props) => {
  return (
    <div className="lined-title blog-title">
      <h3>{props.title}</h3>
      <h3>{props.subtitle}</h3>

      <hr />
    </div>
  );
};
