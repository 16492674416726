import React from 'react';
import { Route } from 'react-router-dom';
import BlogList from './containers/BlogList';
import NewsList from './containers/NewsList';
import News from './containers/News';
import ActivityReport from './containers/ActivityReport';
import NewsDetail from './containers/NewsDetail';
import queryString from 'query-string';
const routes = () => [
  <Route
    path="/blogs"
    exact
    key="/blogs"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <BlogList queryParams={queryParams} history={history} />;
    }}
  />,
  <Route
    path="/news"
    exact
    key="/news"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <NewsList queryParams={queryParams} history={history} />;
    }}
  />,
  <Route
    path="/activity-reports"
    exact
    key="/activity-reports"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <ActivityReport queryParams={queryParams} history={history} />;
    }}
  />,
  <Route
    path="/news-main"
    exact
    key="/news-main"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <News queryParams={queryParams} history={history} />;
    }}
  />,
  <Route
    path="/news-detail/:id"
    exact
    key="/news-detail/:id"
    component={({ location, history, match }) => {
      const queryParams = queryString.parse(location.search);
      const id = match.params.id;
      return <NewsDetail queryParams={queryParams} id={id} history={history} />;
    }}
  />,
];

export default routes;
