import React from "react";
import { Container } from "rsuite";
import { Row, Col } from "react-bootstrap";
import { LinedTitle } from "../../common/components/LinedTitle";
import { NewsCard } from "../../common/components/NewsCard";
class BlogList extends React.Component {
  render() {
    const vlogs = this.props.contents.filter(
      (content) => content.category === "vlog"
    );
    const news = this.props.contents.filter(
      (content) => content.category === "news"
    );
    return (
      <div className="blog-lists">
        <section>
          <Container>
            <LinedTitle title="Влог" subtitle="Блог" />
            <Row gutter={50}>
              <Col md={8} sm={12}>
                <Row gutter={50}>
                  {vlogs.reverse().map((vlog) => (
                    <Col xs={12} sm={6} md={6}>
                      <NewsCard data={vlog} type="vlog-card" />
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col md={4}>
                <ul className="blog-list" style={{ height: "auto" }}>
                  {news.slice(0, 8).map((vlog) => (
                    <NewsCard type="title-card" data={vlog} />
                  ))}
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default BlogList;
