import React from 'react';
import { Link } from 'react-router-dom';
const ReadMore = (props) => {
  return (
    <Link className={`read-more ${props.className}`} to={props.to}>
      Дэлгэрэнгүй
      <i className='fas fa-long-arrow-alt-right'></i>
    </Link>
  );
};

export default ReadMore;
