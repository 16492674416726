import React from 'react';
import { Container } from 'rsuite';
import { Row, Col } from 'react-bootstrap';
import { LinedTitle } from '../../common/components/LinedTitle';
import { NewsCard } from '../../common/components/NewsCard';
class NewsList extends React.Component {
  renderNewsList() {
    return <NewsCard type="title-card" />;
  }
  render() {
    return (
      <div className="blog-lists">
        <section>
          <Container>
            <LinedTitle title="Хөдөлмөр эрхлэлт" />
            <Row gutter={50}>
              {this.props.contents.reverse().map((vlog) => (
                <Col xs={12} sm={6} md={4}>
                  <NewsCard data={vlog} type="news-card" />
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default NewsList;
