const contentListQuery = `
 query contents($title: String, $category: String, $importand: String) {
    contents(title: $title, category: $category, importand: $importand) {
      _id
      title
      category
      createdAt
      importand
      pictures
      pin
      content
    }
  }
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  contentListQuery,
};
