import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Timeline from '../components/Timeline';
import { queries } from '../graphql';
class TimelineContainer extends React.Component {
  render() {
    const { contentListQuery } = this.props;

    if (contentListQuery && contentListQuery.loading) {
      return null;
    }

    const contents = contentListQuery.contents || [];
    const updatedProps = {
      ...this.props,
      contents,
    };

    return <Timeline {...updatedProps} />;
  }
}
const propTypes = {
  contentListQuery: PropTypes.object.isRequired,
};
TimelineContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.contentListQuery), {
      name: 'contentListQuery',
      options: ({ queryParams, props }) => {
        return {
          variables: {
            title: queryParams.title,
            category: 'timeline',
          },
        };
      },
    })
  )(TimelineContainer)
);
