const login = `
  mutation login($password: String!, $email: String!) {
    login(password: $password, email: $email) {
      token
      refreshToken
    }
  }
`;

const userCreate = `
  mutation userCreate($username: String!, $email: String!, $role: String!, $password: String!, $firstName: String, $phone: String, $lastName: String){
    userCreate(username: $username, email: $email, role: $role, password: $password, firstName: $firstName, phone: $phone, lastName: $lastName){
      _id
      username
      email
      role
      phone
      position
      avatar
      firstName
      lastName
    }
  }
`;

const userUpdate = `
  mutation userUpdate($id: String!, $username: String!, $email: String!, $role: String!, $password: String!, $firstName: String, $phone: String, $lastName: String){
    userUpdate(_id: $id, username: $username, email: $email, role: $role, password: $password, firstName: $firstName, phone: $phone, lastName: $lastName){
      _id
      username
      email
      role
      phone
      position
      avatar
      firstName
      lastName
    }
  }
`;

const usersChangePassword = `
  mutation usersChangePassword($currentPassword: String, $newPassword: String) {
    usersChangePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      _id
    }
  }
`;

const resetUsernameAndPassword = `
  mutation resetUsernameAndPassword(
    $token: String!,
    $newUsername: String!,
    $newPassword: String!
  )
  {
    resetUsernameAndPassword (
      token:$token,
      newUsername:$newUsername,
      newPassword:$newPassword
    ) {
      _id
      username
      firstName
      email
      role
      roleTypeDetail
    }
  }
`;

const forgotPassword = `
  mutation forgotPassword($email: String!){
    forgotPassword(email: $email)
  }
`;

const resetPassword = `
  mutation resetPassword($token: String!, $newPassword: String!){
    resetPassword(token: $token, newPassword: $newPassword){
      _id
      username
      firstName
      email
    }
  }
`;

const userRemove = `
  mutation userRemove($_id: String) {
    userRemove(_id: $_id){
      _id

    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userRemove,
  resetPassword,
  login,
  userCreate,
  userUpdate,
  usersChangePassword,
  resetUsernameAndPassword,
  forgotPassword
};
