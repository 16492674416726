import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
import { mutations, queries } from '../graphql';
import UserCreate from '../components/UserCreate';

class UserCreateContainer extends React.Component {
  constructor(props) {
    super(props);

    this.userAdd = this.userAdd.bind(this);
    this.userEdit = this.userEdit.bind(this);
  }

  userAdd(variables) {
    const { userAdd } = this.props;

    userAdd({ variables })
      .then(() => {
        this.props.onCancel();
      })
      .catch(err => {
        console.log(err);
      });
  }

  userEdit(variables) {
    const { userEdit } = this.props;

    userEdit({ variables })
      .then(() => {
        this.props.onCancel();
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { userDetailQuery } = this.props;

    if (userDetailQuery && userDetailQuery.loading) {
      return <></>;
    }

    const detail = userDetailQuery ? userDetailQuery.userDetail || {} : {};

    const updatedProps = {
      ...this.props,
      userAdd: this.userAdd,
      userEdit: this.userEdit,
      detail
    };

    return <UserCreate {...updatedProps} />;
  }
}

UserCreateContainer.propTypes = {
  id: PropTypes.string,
  userDetailQuery: PropTypes.object,
  userAdd: PropTypes.func,
  userEdit: PropTypes.func
};

export default compose(
  graphql(gql(queries.userDetailQuery), {
    name: 'userDetailQuery',
    skip: props => !props.id,
    options: props => {
      return {
        variables: {
          _id: props.id
        }
      };
    }
  }),

  graphql(gql(mutations.userCreate), {
    name: 'userAdd',
    options: ({ doc }) => ({
      variables: {
        doc
      }
    })
  }),

  graphql(gql(mutations.userUpdate), {
    name: 'userEdit',
    options: ({ _id, doc }) => ({
      variables: {
        _id,
        doc
      }
    })
  })
)(UserCreateContainer);
