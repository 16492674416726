import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import ResetPassword from '../components/ResetPassword.js';
import { mutations } from '../graphql';
import { Alert } from 'rsuite';

const ResetPasswordContainer = props => {
  const { resetPasswordMutation, queryParams } = props;

  const { token } = queryParams;

  const resetPassword = password => {
    resetPasswordMutation({ variables: { token, newPassword: password } })
      .then(() => {
        Alert.success('Амжилттай');
        window.location.href = '/login';
      })
      .catch(e => {
        Alert.error(e.message);
      });
  };

  const updatedProps = {
    ...props,
    resetPassword
  };

  return <ResetPassword {...updatedProps} />;
};

ResetPasswordContainer.propTypes = {
  resetPasswordMutation: PropTypes.func
};

export default withRouter(
  compose(
    graphql(gql(mutations.resetPassword), {
      name: 'resetPasswordMutation'
    })
  )(ResetPasswordContainer)
);
