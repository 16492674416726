const faqListQuery = `
  query faqs {
    faqs {
      _id
      question
      answer
    }
  }
`;
const faqDetailQuery = `
  query faqDetail($_id: String) {
    faqDetail(_id: $_id) {
      _id
      question
      answer
    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  faqListQuery,
  faqDetailQuery,
};
