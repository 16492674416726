import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Alert } from 'rsuite';
import FeedbackList from '../components/FeedbackList';
import { mutations, queries } from '../graphql';
class FeedbackListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.feedbackRemove = this.feedbackRemove.bind(this);
  }

  feedbackRemove = (variables) => {
    const { feedbackRemoveMutation } = this.props;
    feedbackRemoveMutation({ variables: { _id: variables } })
      .then(() => {
        Alert.success('Амжилттай устгалаа');
      })
      .catch((err) => {
        Alert.error(err.message);
      });
  };

  render() {
    const { feedbackListQuery } = this.props;

    if (feedbackListQuery && feedbackListQuery.loading) {
      return null;
    }

    const feedbacks = feedbackListQuery.feedbacks || null;

    const updatedProps = {
      feedbacks,
      feedbackRemove: this.feedbackRemove,
    };
    return <FeedbackList {...updatedProps} />;
  }
}
const propTypes = {
  feedbackListQuery: PropTypes.object.isRequired,
  feedbackRemove: PropTypes.func,
};
FeedbackListContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.feedbackQuery), {
      name: 'feedbackListQuery',
    }),
    graphql(gql(queries.feedbackDetailQuery), {
      name: 'feedbackDetailQuery',
    }),
    graphql(gql(mutations.feedbackRemove), {
      name: 'feedbackRemoveMutation',
    })
  )(FeedbackListContainer)
);
