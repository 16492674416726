import React, { Component, Fragment } from 'react';
import { Button } from 'rsuite';

class ContentDetail extends Component {
  render() {
    const { contentDetail, onCancel } = this.props;
    return (
      <Fragment>
        <h1>{contentDetail.title}</h1>
        <div style={{ display: 'flex', justifyContent: 'center' }}>{contentDetail.pictures && contentDetail.pictures.map((picture, i) => <img src={picture} alt={i} key={i} />)}</div>
        <div className="content">
          <div className="main-content">
            <div
              className="law-detail-content"
              dangerouslySetInnerHTML={{
                __html: contentDetail.content,
              }}
            />
          </div>
        </div>

        <div className="modal-actions">
          <Button onClick={onCancel}>Буцах</Button>
        </div>
      </Fragment>
    );
  }
}

export default ContentDetail;
