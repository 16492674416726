import queryString from 'query-string';
import React from 'react';
import { Route } from 'react-router-dom';
import CareerForm from './containers/CareerForm';
import JobList from './containers/JobList';
const routes = ({ currentUser }) => [
  <Route
    path='/job-list'
    exact
    key='/job-list'
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return (
        <JobList
          queryParams={queryParams}
          history={history}
          currentUser={currentUser}
        />
      );
    }}
  />,
  <Route
    path='/job-form'
    exact
    key='/job-form'
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <CareerForm queryParams={queryParams} history={history} />;
    }}
  />,
];

export default routes;
