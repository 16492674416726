import React from 'react';
import Search from '../components/Search';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
class SearchContainer extends React.Component {
  render() {
    const updatedProps = {
      ...this.props,
    };
    return <Search {...updatedProps} />;
  }
}

const propTypes = {
  contentListQuery: PropTypes.object.isRequired,
};
SearchContainer.propTypes = propTypes;

export default withRouter(SearchContainer);
