import React from "react";
import { Link } from "react-router-dom";
import ReadMore from "../../common/components/ReadMore";
import playImg from "../../../assets/img/play.png";
import { Col, Row } from "rsuite";
import moment from "moment";
export const NewsCard = (props) => {
  if (!props.data) {
    return null;
  }

  const { data } = props;
  if (props.type === "vlog-card") {
    return (
      <div className="vlog-card">
        <Link to={`/news-detail/${data._id}`}>
          <div
            className="bg-image thumbnail"
            style={{
              background: `linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.25) 50%), url(${data.pictures})`,
            }}
          >
            <img src={playImg} alt="play" />
          </div>
          <h5>{data.title}</h5>
          {/* <ReadMore to={`/news-detail/${data._id}`} className="no-border" /> */}
          <ReadMore to={`/news-detail/${data._id}`} className="no-border" />
        </Link>
      </div>
    );
  } else if (props.type === "title-card") {
    return (
      <li>
        <Link to={`/news-detail/${data._id}`}>
          <h6>{data.title}</h6>
          <div className="details">
            <span>{moment(data.createdAt, "x").format("YYYY-MM-DD")}</span>
          </div>
        </Link>
      </li>
    );
  } else if (props.type === "side-card") {
    return (
      <div className="news-card side-card">
        <Link to={`/news-detail/${data._id}`}>
          <Row gutter={20}>
            <Col xs={11}>
              <div
                className="bg-image thumbnail"
                style={{
                  background: `url(${data.pictures})`,
                }}
              />
            </Col>
            <Col xs={13}>
              <h5>{data.title}</h5>
            </Col>
          </Row>
        </Link>
      </div>
    );
  } else if (props.type === "desc-card") {
    return (
      <div className="news-card desc-card">
        {!props.nolink ? (
          <Link to={`/news-detail/${data._id}`}>
            <div
              className="bg-image thumbnail"
              style={{
                background: `linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.25) 50%), url(${data.pictures})`,
              }}
            ></div>
            <h5>{data.title}</h5>
            <p>{data.description}</p>
          </Link>
        ) : (
          <>
            <div
              className="bg-image thumbnail"
              style={{
                background: `linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.25) 50%), url(${data.pictures})`,
              }}
            ></div>
            <h5>{data.title}</h5>
            <p>{data.description}</p>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="news-card">
        <Link to={`/news-detail/${data._id}`}>
          <div
            className="bg-image thumbnail"
            style={{
              background: `linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.25) 50%), url(${data.pictures})`,
            }}
          ></div>
          <h5>{data.title}</h5>
          <ReadMore to={`/news-detail/${data._id}`} className="no-border" />
        </Link>
      </div>
    );
  }
};
