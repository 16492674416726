const imageListQuery = `
 query images($type: String) {
    images(type: $type) {
      _id
      description
      type
      images
      url
    }
  }
`;
const imageDetailQuery = `
  query imageDetail($_id: String){
    imageDetail(_id: $_id){
      _id
      description
      type
      images
      url
    }
  }
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  imageListQuery,
  imageDetailQuery
};
