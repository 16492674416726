import React, { Component, Fragment } from 'react';
import { Button, Col, Card, Row } from 'antd';

class LawDetail extends Component {
  renderGender(gender) {
    if (gender === 'male') {
      return 'Эрэгтэй';
    }
    return 'Эмэгтэй';
  }

  render() {
    return (
      <Fragment>
        <Card bordered={false} className="schoolCard teacherDetail">
          <Row gutter={24}>
            <Col span={24}>
              <h1 Style="display:none">{this.props.lawDetail.title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.lawDetail.content,
                }}
              />
            </Col>
          </Row>
        </Card>

        <div className="modal-actions">
          <Button onClick={this.props.onCancel}>Буцах</Button>
        </div>
      </Fragment>
    );
  }
}

export default LawDetail;
