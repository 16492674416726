import React from 'react';
import { Container } from 'rsuite';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import ActivityReportDetail from '../containers/ActivityReportDetail';
import ModalTrigger from '../../common/components/ModalTrigger';
import { PageHeader } from '../../common/components/PageHeader';
class ActivityReport extends React.Component {
  constructor(props) {
    super(props);

    const qp = props.queryParams;

    this.filter = {
      title: qp && qp.title ? qp.title : '',
      category: qp && qp.category ? qp.category : '',
    };
    this.state = {
      searchValue: '',
      selectedCategory: '',
      show: false,
    };
  }

  showModal = () => {
    this.setState({
      show: true,
    });
  };

  handleCancel = (e) => {
    this.setState({
      show: false,
    });
  };

  renderLaw(data, index) {
    return (
      <Col md={4} sm={6} xs={12} key={index}>
        <ModalTrigger title={data.title} date={moment(data.date || data.createdAt, 'x').format('YYYY/MM/DD')} category={data.category} law={true} size="lg" content={(props) => <ActivityReportDetail {...props} id={data._id} />} />
      </Col>
    );
  }

  handleSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };

  handleCategory = (value) => {
    this.setState({
      selectedCategory: value,
    });
  };

  handleSubmit = (e) => {
    const { searchValue, selectedCategory } = this.state;
    e.preventDefault();
    this.props.history.push('/law?title=' + searchValue + '&category=' + selectedCategory);
  };
  render() {
    const { laws } = this.props;
    return (
      <div className="job-list">
        <PageHeader className="no-bg" title="Үйл ажиллагааны тайлан" />
        <section>
          <Container className="min">
            <Row gutter={50}>{laws.reverse().map((law, i) => this.renderLaw(law, i))}</Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default ActivityReport;
