import React from 'react';
import { Helmet } from 'react-helmet';
import mapImg from '../../../assets/img/map2.png';
export const SEO = ({ title, description, url, image, app_id }) => {
  return (
    <div>
      <Helmet
        title={title}
        htmlAttributes={{ lang: 'en' }}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: 'og:url',
            content: url,
          },
          {
            property: 'og:type',
            content: 'article',
          },
          {
            property: 'og:title',
            content: title,
          },
          {
            property: 'og:description',
            content: description,
          },
          {
            property: 'og:image',
            content: image ? image : mapImg,
          },
          {
            property: 'fb:app_id',
            content: app_id,
          },
        ]}
      />
    </div>
  );
};
