const contentListQuery = `
 query contents($title: String, $category: String, $importand: String) {
    contents(title: $title, category: $category, importand: $importand) {
      _id
      title
      category
      importand
      pictures
      description
      content
    }
  }
`;

const imageListQuery = `
 query images($type: String) {
    images(type: $type) {
      _id
      description
      type
      url
      images
    }
  }
`;
const contentDetailQuery = `
  query contentDetail($_id: String) {
    contentDetail(_id: $_id) {
      _id
      title
      date
      content
      category
      createdAt
      pictures
      description
      imageNote
      pin
      importand
      showCount
    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  contentListQuery, imageListQuery, contentDetailQuery
};
