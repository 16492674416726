import React from 'react';
import { Container } from 'rsuite';
import Head from '../../common/components/Head';
import { PageHeader } from '../../common/components/PageHeader';
class Partners extends React.Component {
  render() {
    const { images } = this.props;
    const i1 = images.filter((image) => image.description.includes(' 1'));
    const i2 = images.filter((image) => image.description.includes(' 2'));
    const i3 = images.filter((image) => image.description.includes(' 3'));
    const i4 = images.filter((image) => image.description.includes(' 4'));
    return (
      <div className="partners-page">
        <Head title="Хамтрагч байгууллагууд" />
        <PageHeader className="no-bg" title="Хамтрагч байгууллагууд" />

        <Container>
          <section>
            <h4>Олон улсын байгууллага</h4>
            <div className="logo-wrapper">
              {i1.map((image) => (
                <a
                  href={image.url}
                  target="_blank"
                  title={image.description.slice(0, -2)}
                  rel="noreferrer"
                >
                  <img
                    src={image.images}
                    alt={image.description.slice(0, -2)}
                  />
                </a>
              ))}
            </div>
          </section>
          <section>
            <h4>Гадаад хамтрагч байгууллага</h4>
            <div className="logo-wrapper">
              {i2.map((image) => (
                <a
                  href={image.url}
                  target="_blank"
                  title={image.description.slice(0, -2)}
                  rel="noreferrer"
                >
                  <img
                    src={image.images}
                    alt={image.description.slice(0, -2)}
                  />
                </a>
              ))}
            </div>
          </section>
          <section>
            <h4>Дотоодын байгууллага</h4>
            <div className="logo-wrapper">
              {i3.map((image) => (
                <a
                  href={image.url}
                  target="_blank"
                  title={image.description.slice(0, -2)}
                  rel="noreferrer"
                >
                  <img
                    src={image.images}
                    alt={image.description.slice(0, -2)}
                  />
                </a>
              ))}
            </div>
          </section>
          <section>
            <h4>Дотоодын төрийн бус байгууллага</h4>
            <div className="logo-wrapper">
              {i4.map((image) => (
                <a
                  href={image.url}
                  target="_blank"
                  title={image.description.slice(0, -2)}
                  rel="noreferrer"
                >
                  <img
                    src={image.images}
                    alt={image.description.slice(0, -2)}
                  />
                </a>
              ))}
            </div>
          </section>
        </Container>
      </div>
    );
  }
}

export default Partners;
