import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { queries } from '../graphql';
import ContentDetail from '../components/ContentDetail';
import { flowRight as compose } from 'lodash';
import { Spin } from 'antd';

class ContentDetailContainer extends React.Component {
  render() {
    const { id, contentDetailQuery } = this.props;

    if (contentDetailQuery.loading) {
      return <Spin size="large" />;
    }
    const contentDetail = contentDetailQuery.contentDetail || {};
    const updatedProps = {
      ...this.props,
      id,
      contentDetail,
    };

    return <ContentDetail {...updatedProps} />;
  }
}

ContentDetailContainer.propTypes = {
  id: PropTypes.string,
  contentDetailQuery: PropTypes.object.isRequired,
};

export default compose(
  graphql(gql(queries.contentDetailQuery), {
    name: 'contentDetailQuery',
    options: (props) => {
      return {
        variables: {
          _id: props.id,
        },
      };
    },
  })
)(ContentDetailContainer);
