import React from 'react';
import {
  Button,
  ButtonToolbar,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  SelectPicker,
} from 'rsuite';

export default class UserCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: props.detail || {},
    };
  }

  onFormChange = (formValue) => {
    this.setState({ formValue });
  };

  onSubmit = () => {
    const { formValue } = this.state;
    const { detail, userEdit, userAdd } = this.props;

    if (detail._id) {
      formValue.id = this.props.detail._id;

      return userEdit(formValue);
    }

    return userAdd(formValue);
  };

  render() {
    const data = [
      {
        label: 'Админ',
        value: 'admin',
      },
      {
        label: 'Эксперт',
        value: 'expert',
      },
    ];

    return (
      <Form
        // ref={ref => (this.form = ref)}
        onChange={(formValue) => {
          this.onFormChange(formValue);
        }}
        formValue={this.state.formValue}
      >
        <FormGroup>
          <ControlLabel>Нэвтрэх нэр</ControlLabel>
          <FormControl name='username' />
          <HelpBlock>Required</HelpBlock>
        </FormGroup>
        <FormGroup>
          <ControlLabel>Овог</ControlLabel>
          <FormControl name='lastName' />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Нэр</ControlLabel>
          <FormControl name='firstName' />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Утасны дугаар</ControlLabel>
          <FormControl name='phone' type='phone' />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Email</ControlLabel>
          <FormControl name='email' type='email' />
          <HelpBlock tooltip>Required</HelpBlock>
        </FormGroup>
        <FormGroup>
          <ControlLabel>Хэргэлэгчийн эрх</ControlLabel>
          <FormControl
            accepter={SelectPicker}
            name='role'
            data={data}
            style={{ width: 300 }}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Password</ControlLabel>
          <FormControl name='password' type='password' />
        </FormGroup>
        <FormGroup>
          <ButtonToolbar>
            <Button appearance='primary' onClick={this.onSubmit}>
              Submit
            </Button>
            <Button appearance='default' onClick={this.props.onCancel}>
              Cancel
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    );
  }
}
