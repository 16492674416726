import React from 'react';
import { Container, Row, Col, Input, Button } from 'rsuite';
import { Link } from 'react-router-dom';
class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: '',
      password: ''
    };
  }

  handleInputChange = value => {
    this.setState({
      input: value
    });
  };

  handlePasswordChange = value => {
    this.setState({
      password: value
    });
  };

  login = () => {
    const { input, password } = this.state;

    if (input && password) {
      this.props.login({ input, password });
    }
  };

  renderForm() {
    return (
      <form className="login-form job-form">
        <Row gutter={100}>
          <Col sm={24}>
            <div className="form-item">
              <label>Нэвтрэх нэр</label>
              <Input
                placeholder="Нэвтрэх нэр"
                onChange={this.handleInputChange}
              />
            </div>
          </Col>
          <Col sm={24}>
            <div className="form-item">
              <label>Нууц үг</label>
              <Input
                type="password"
                placeholder="Нууц үг"
                onChange={this.handlePasswordChange}
              />
            </div>
            <Link to={'/forgotPassword'}>Нууц үг сэргээх</Link>
          </Col>

          <Col sm={24}>
            <div className="form-item">
              <Button
                className="add login"
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  this.login(e);
                }}
              >
                <span>Нэвтрэх</span>
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    );
  }

  render() {
    return (
      <div>
        <Container className="min">
          <section>
            <h3 className="section-title" style={{ fontSize: '46px' }}>
              Нэвтрэх
            </h3>
            <hr />
            <Row style={{ margin: '4rem 0' }} gutter={80}>
              <Col md={16}>{this.renderForm()}</Col>
              <Col md={8}></Col>
            </Row>
          </section>
        </Container>
      </div>
    );
  }
}

export default Login;
