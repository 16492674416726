import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { Alert } from 'rsuite';
import queries from '../../news/graphql/queries';
import Contact from '../components/Contact';
import mutations from '../graphql/mutations';
class ContactContainer extends React.Component {
  constructor(props) {
    super(props);

    this.feedbackAdd = this.feedbackAdd.bind(this);
  }
  feedbackAdd(variables) {
    const { feedbackAdd } = this.props;
    feedbackAdd({ variables })
      .then(() => {
        Alert.success('Таны санал хүсэлт амжилттай илгээгдлээ.');
      })
      .catch((err) => {
        Alert.error('Алдаа гарлаа');
      });
  }

  render() {
    const { contentListQuery } = this.props;

    if (contentListQuery && contentListQuery.loading) {
      return null;
    }

    const contents = contentListQuery.contents || [];
    const updatedProps = {
      feedbackAdd: this.feedbackAdd,
      contents,
    };
    return <Contact {...updatedProps} />;
  }
}

ContactContainer.propTypes = {
  id: PropTypes.string,
  feedbackAdd: PropTypes.func,
  contentListQuery: PropTypes.object.isRequired,
};

export default compose(
  graphql(gql(mutations.feedbackAdd), {
    name: 'feedbackAdd',
    options: ({ doc }) => ({
      variables: {
        doc,
      },
    }),
  }),
  graphql(gql(queries.contentListQuery), {
    name: 'contentListQuery',
    options: ({ queryParams }) => {
      return {
        variables: {
          category: 'contact',
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
      };
    },
  })
)(ContactContainer);
