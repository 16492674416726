import React from 'react';
import { Button, Input } from 'rsuite';
import routerUtils from '../../../common/routerUtils';

export default class FilterUser extends React.Component {
  constructor(props) {
    super(props);

    const qp = props.queryParams;

    this.filter = {
      username: qp && qp.username ? qp.username : '',
      email: qp && qp.email ? qp.email : '',
    };
  }

  onFilterChange = (type, value) => {
    this.filter[type] = value;
    this.setState({ [type]: value });
  };

  onSearchClick = () => {
    const { historyProps } = this.props;

    routerUtils.setParams(historyProps, this.filter);
  };

  render() {
    const { username, email } = this.filter;
    return (
      <div className='admin-filter'>
        <Input
          placeholder='username'
          value={username ? username : ''}
          onChange={this.onFilterChange.bind(this, 'username')}
        />
        <Input
          placeholder='email'
          value={email ? email : ''}
          onChange={this.onFilterChange.bind(this, 'email')}
        />
        <Button
          color='blue'
          appearance='ghost'
          type='submit'
          onClick={() => this.onSearchClick()}
        >
          {' '}
          <i className='fas fa-search'></i>
        </Button>
      </div>
    );
  }
}
