import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
import { mutations, queries } from '../graphql';
import { Alert } from 'rsuite';
import CategoryForm from '../components/CategoryForm';

class CategoryFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.configCategoryAdd = this.configCategoryAdd.bind(this);
    this.configCategoryEdit = this.configCategoryEdit.bind(this);
  }

  configCategoryAdd(variables) {
    const { configCategoryAdd } = this.props;
    configCategoryAdd({ variables })
      .then(() => {
        this.props.onCancel();
        Alert.success('Амжилттай нэмлээ');
      })
      .catch(err => {
        console.log(err);
        Alert.error(err);
      });
  }

  configCategoryEdit(variables) {
    const { configCategoryEdit } = this.props;

    configCategoryEdit({ variables })
      .then(() => {
        this.props.onCancel();
        Alert.success('Амжилттай заслаа');
      })
      .catch(err => {
        console.log(err);
        Alert.error(err);
      });
  }

  render() {
    const { configCategoryDetailsQuery } = this.props;

    if (configCategoryDetailsQuery && configCategoryDetailsQuery.loading) {
      return <></>;
    }

    const detail = configCategoryDetailsQuery
      ? configCategoryDetailsQuery.configCategoryDetails || {}
      : {};

    const updatedProps = {
      ...this.props,
      configCategoryAdd: this.configCategoryAdd,
      configCategoryEdit: this.configCategoryEdit,
      detail
    };
    return <CategoryForm {...updatedProps} />;
  }
}

CategoryFormContainer.propTypes = {
  id: PropTypes.string,
  configCategoryDetailsQuery: PropTypes.object,
  configCategoryAdd: PropTypes.func,
  configCategoryEdit: PropTypes.func
};

export default compose(
  graphql(gql(queries.configCategoryDetails), {
    name: 'configCategoryDetailsQuery',
    skip: props => !props.id,
    options: props => {
      return {
        variables: {
          _id: props.id
        }
      };
    }
  }),

  graphql(gql(mutations.configCategoryAdd), {
    name: 'configCategoryAdd',
    options: ({ doc }) => ({
      variables: {
        doc
      }
    })
  }),

  graphql(gql(mutations.configCategoryEdit), {
    name: 'configCategoryEdit',
    options: ({ _id, doc }) => ({
      variables: {
        _id,
        doc
      }
    })
  })
)(CategoryFormContainer);
