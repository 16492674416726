const login = `
  mutation login($password: String!, $input: String!) {
    login(password: $password, input: $input) {
      token
      refreshToken
    }
  }
`;

const usersChangePassword = `
  mutation usersChangePassword($currentPassword: String, $newPassword: String) {
    usersChangePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      _id
    }
  }
`;

const forgotPassword = `
  mutation forgotPassword($email: String!){
    forgotPassword(email: $email)
  }
`;

const resetPassword = `
  mutation resetPassword($token: String!, $newPassword: String!){
    resetPassword(token: $token, newPassword: $newPassword){
      _id
    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default { usersChangePassword, login, forgotPassword, resetPassword };
