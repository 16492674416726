import React, { useContext } from 'react';
import ThemeContext from '../../../ThemeContext';
import moon from '../../../assets/img/moon.png';
import sun from '../../../assets/img/sun.png';
function ThemeSwitcher() {
  const { theme, setTheme } = useContext(ThemeContext);

  return (
    <div
      onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
      className='dark-mode'
    >
      <img src={theme === 'dark' ? sun : moon} alt='moon' />
      <span>{theme === 'dark' ? 'light mode' : 'dark mode'}</span>
    </div>
  );
}

export default ThemeSwitcher;
