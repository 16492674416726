import React from 'react';
import { Col, Container, Row } from 'rsuite';
import ReadMore from '../../common/components/ReadMore';
import imgCard from '../../../assets/img/cat.png';
import imgCard1 from '../../../assets/img/cat1.png';
import imgCard2 from '../../../assets/img/cat2.png';
import imgCard3 from '../../../assets/img/cat3.png';
import { Link } from 'react-router-dom';
const Guidance = () => {
  return (
    <section className='home-guidance'>
      <Container className='fluid'>
        <Row>
          <Col md={12} xs={24}>
            <div className='guidance-wrapper flex-center'>
              <div className='caption'>
                <h3>Зөвлөмж</h3>
                <p>
                  Бидний алсын хараа, эрхэм зориолго бол хүртээмжтэй эрүүл,
                  аюулгүй, тогтвортой хөгжлийг бүтээх юм
                </p>
                <ReadMore className='white' to='/advice' />
              </div>
            </div>
          </Col>
          <Col md={12} xs={24}>
            <div className='guidance-categories'>
              <Row gutter={60}>
                <Col md={11} xs={24}>
                  <div className='g-wrapper'>
                    <Link to='/law'>
                      <div className='g-card'>
                        <img src={imgCard} alt='cat' />
                        <h3>Хууль, Эрх зүй</h3>
                        <p>
                          Хөгжлийн бэрхшээлтэй хүмүүсийн хөдөлмөр эрхлэлт,
                          хүртээмж, тэгш хамран сургах зэрэг асуудлаарх олон
                          улсын гэрээ, зөвлөмж, Монгол улсын хууль, тогтоол,
                          тушаал, хөтөлбөр
                        </p>
                      </div>
                    </Link>
                  </div>
                </Col>
                <Col md={11} xs={24}>
                  <div className='g-wrapper'>
                    <Link to="/independence">
                      <div className='g-card'>
                        <img src={imgCard1} alt='cat' />
                        <h3>Бие даан амьдрах</h3>
                        <p>
                          Хөгжлийн бэрхшээлтэй хүмүүсийн хөдөлмөр эрхлэлт,
                          хүртээмж, тэгш хамран сургах зэрэг асуудлаарх олон улсын
                          гэрээ, зөвлөмж, Монгол улсын хууль, тогтоол, тушаал,
                          хөтөлбөр
                        </p>
                      </div>
                    </Link>
                  </div>
                </Col>
                <Col md={11} xs={24}>
                  <div className='g-wrapper'>
                    <Link to="/infrastructure">
                      <div className='g-card'>
                        <img src={imgCard2} alt='cat' />
                        <h3>Дэд бүтэц, барилга байгууламжийн хүртээмж</h3>
                        <p>
                          Хөгжлийн бэрхшээлтэй хүмүүсийн хөдөлмөр эрхлэлт,
                          хүртээмж, тэгш хамран сургах зэрэг асуудлаарх олон улсын
                          гэрээ, зөвлөмж, Монгол улсын хууль, тогтоол, тушаал,
                          хөтөлбөр
                        </p>
                      </div>
                    </Link>
                    
                  </div>
                </Col>
                <Col md={11} xs={24}>
                  <div className='g-wrapper'>
                    <Link to="/employment">
                      <div className='g-card'>
                        <img src={imgCard3} alt='cat' />
                        <h3>Хөдөлмөр эрхлэлт</h3>
                        <p>
                          Хөгжлийн бэрхшээлтэй хүмүүсийн хөдөлмөр эрхлэлт,
                          хүртээмж, тэгш хамран сургах зэрэг асуудлаарх олон улсын
                          гэрээ, зөвлөмж, Монгол улсын хууль, тогтоол, тушаал,
                          хөтөлбөр
                        </p>
                      </div>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Guidance;
