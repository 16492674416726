import React from 'react';
import { Table, Button, ButtonToolbar, Pagination } from 'rsuite';
import ContentForm from '../containers/ContentForm';
import ModalTrigger from '../../../../common/components/ModalTrigger';
import moment from 'moment';
import Filter from '../../common/Filter';
import routerUtils from '../../../../common/routerUtils';
import { confirmAlert } from 'react-confirm-alert';
import { pageHeaderCategories as categories } from '../../../constants';
const { Column, HeaderCell, Cell } = Table;

export default class DashboardMark extends React.Component {
  constructor(props) {
    super(props);

    const qp = props.queryParams;

    this.filter = {
      page: qp && qp.page ? qp.page : '',
      perPage: qp && qp.perPage ? qp.perPage : '',
    };

    this.state = {
      text: '',
      data: props.contents || {},
      show: false,
      date: props.contents.createdAt || {},
    };
  }

  renderDate = (createdAt) => {
    return moment(createdAt, 'x').format('YYYY-MM-DD');
  };

  close = () => {
    this.setState({ show: false });
  };

  open = () => {
    this.setState({ show: true });
  };

  handleChangePage = (e) => {
    const { history } = this.props;
    this.filter.page = e;
    this.filter.perPage = (e - 1) * 1;

    routerUtils.setParams(history, this.filter);
  };

  handleChange = (value) => {
    this.setState({ text: value });
  };

  onRemove = (_id) => {
    confirmAlert({
      title: 'Та устгахдаа итгэлтэй байна уу?',
      buttons: [
        {
          label: 'Устгах',
          onClick: (e) => this.props.contentRemove(_id),
        },
        {
          label: 'Болих',
        },
      ],
    });
  };

  renderActions = (_id) => {
    return (
      <span className="action-wrapper">
        <ModalTrigger iconClassName={'fas fa-edit'} btnColor={'cyan'} size="lg" content={(props) => <ContentForm {...props} id={_id} />}></ModalTrigger>
        &nbsp;
        <Button color="red" appearance="ghost" onClick={() => this.onRemove(_id)}>
          <i className="fas fa-trash"></i>
        </Button>
      </span>
    );
  };

  render() {
    console.log(this.state.data);
    return (
      <div>
        <h4 className="d-title">Page Headers</h4>
        <div className="admin-bar">
          <Filter categories={categories} historyProps={this.props.history} queryParams={this.props.queryParams} />
          <ButtonToolbar>
            <ModalTrigger
              btnText={'Агуулга нэмэх'}
              btnClassName={'create - btn'}
              iconClassName={'fas fa-plus'}
              size="lg"
              btnColor={'blue'}
              content={(props) => <ContentForm {...props} />}
            ></ModalTrigger>
          </ButtonToolbar>
        </div>
        <Table height={800} data={this.state.data.reverse()}>
          <Column width={360}>
            <HeaderCell>Гарчиг</HeaderCell>
            <Cell dataKey="title" />
          </Column>
          <Column width={120}>
            <HeaderCell>Огноо</HeaderCell>
            <Cell dataKey="createdAt">{(rowData) => this.renderDate(rowData.createdAt)}</Cell>
          </Column>
          {/* <Column width={200}>
            <HeaderCell>Ангилал</HeaderCell>
            <Cell dataKey="category" />
          </Column> */}
          <Column width={200}>
            <HeaderCell>Author</HeaderCell>
            <Cell dataKey="author" />
          </Column>
          <Column width={140} fixed="right">
            <HeaderCell>Үйлдэл</HeaderCell>

            <Cell>{(rowData) => this.renderActions(rowData._id)}</Cell>
          </Column>
        </Table>
        <Pagination prev last next first size="lg" pages={1} activePage={parseInt(this.props.queryParams.page) || 1} onSelect={(e) => this.handleChangePage(e)} />
      </div>
    );
  }
}
