import React from 'react';
import Layout from '../components/Layout';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import queries from '../graphql/queries';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
class LayoutContainer extends React.Component {
  render() {
    const { imageListQuery, contactQuery } = this.props;

    if (imageListQuery && imageListQuery.loading) {
      return null;
    }

    if (contactQuery && contactQuery.loading) {
      return null;
    }
    const images = imageListQuery.images || [];
    const contact = contactQuery.contents || [];
    const updatedProps = {
      ...this.props,
      images,
      contact,
    };
    return <Layout {...updatedProps} />;
  }
}

const propTypes = {
  imageListQuery: PropTypes.object.isRequired,
  contactQuery: PropTypes.object.isRequired,
};
LayoutContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.imageListQuery), {
      name: 'imageListQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            type: 'partners',
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        };
      },
    }),
    graphql(gql(queries.contentListQuery), {
      name: 'contactQuery',
      options: () => {
        return {
          variables: {
            category: 'contact',
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        };
      },
    })
  )(LayoutContainer)
);
