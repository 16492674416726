import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { queries, mutations } from '../graphql';
import { Laws } from '../component';
import { Alert } from 'rsuite';
const propTypes = {
  getLawsQuery: PropTypes.object.isRequired,
  queryParams: PropTypes.object,
  history: PropTypes.object,
};

const LawsContainer = (props) => {
  const { lawRemoveMutation, getLawsQuery, queryParams, history } = props;

  if (getLawsQuery.loading) {
    return <Spin size="large" />;
  }

  const lawRemove = (variables) => {
    lawRemoveMutation({ variables: { _id: variables } })
      .then(() => {
        Alert.success('Амжилттай устгалаа');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const laws = getLawsQuery.laws || [];
  const updatedProps = {
    lawRemove,
    laws,
    history,
    queryParams,
  };
  return <Laws {...updatedProps} />;
};

LawsContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.getLawsListQuery), {
      name: 'getLawsQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            beginDate: queryParams.beginDate,
            endDate: queryParams.endDate,
            category: queryParams.category,
            title: queryParams.title,
            importand: queryParams.importand,
          },
        };
      },
    }),

    graphql(gql(mutations.lawRemove), {
      name: 'lawRemoveMutation',
    })
  )(LawsContainer)
);
