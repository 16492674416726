import React, { useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { BrowserRouter as Router } from 'react-router-dom';
import 'rsuite/dist/styles/rsuite-default.css';
import './assets/css/App.scss';
import './assets/css/gerege/gerege.css';
import AboutRouter from './modules/about/routes';
import AdviceRouter from './modules/advice/routes';
import WithCurrentUser from './modules/auth/containers/WithCurrentUser.js';
import AuthRouter from './modules/auth/routes';
import CareerRouter from './modules/career/routes';
import ScrollToTop from './modules/common/components/ScrollToTop';
import ContactRouter from './modules/contact/routes';
import DashboardRouter from './modules/dashboard/routes';
import HomeRouter from './modules/home/routes';
import Layout from './modules/layouts/containers/Layout';
import MarkRouter from './modules/mark/routes';
import NewsRouter from './modules/news/routes';
import SearchRouter from './modules/search/routes';
import ThemeContext from './ThemeContext';
const renderRoutes = (currentUser) => {
  return (
    <>
      <HomeRouter currentUser={currentUser} />
      <ContactRouter currentUser={currentUser} />
      <AdviceRouter currentUser={currentUser} />
      <NewsRouter currentUser={currentUser} />
      <AboutRouter currentUser={currentUser} />
      <CareerRouter currentUser={currentUser} />
      <MarkRouter currentUser={currentUser} />
      <AuthRouter currentUser={currentUser} />
      <SearchRouter currentUser={currentUser} />
    </>
  );
};

const Routes = (props, state) => {
  const [theme, setTheme] = useState('light');
  const value = { theme, setTheme };
  return (
    <ThemeContext.Provider value={value}>
      <Router>
        <ScrollToTop />
        {props.currentUser &&
        props.currentUser.role === 'admin' &&
        window.location.pathname.includes('dashboard') ? (
          <DashboardRouter currentUser={props.currentUser} />
        ) : props.currentUser === null ? (
          <Layout {...state} currentUser={props.currentUser}>
            {renderRoutes(props.currentUser)}
          </Layout>
        ) : (
          <Layout {...state} currentUser={props.currentUser}>
            {renderRoutes(props.currentUser)}
          </Layout>
        )}
      </Router>
    </ThemeContext.Provider>
  );
};

export default WithCurrentUser(Routes);
