import React from "react";
import { Container } from "rsuite";
import { FlexSection } from "../../common/components/FlexSection";
import sImg from "../../../assets/img/map2.png";
class MarkList extends React.Component {
  render() {
    return (
      <section className="page">
        <Container>
          <FlexSection
            title="Хүртээмжтэй газар хайх"
            imgs={sImg}
            url="/mark-map"
          />
          {/* <FlexSection title='Хүртээмжийн үнэлгээ хийх' reversed /> */}
          <FlexSection title="Үнэлгээний тайлан" reversed url="/mark-report" />
        </Container>
      </section>
    );
  }
}

export default MarkList;
