import React from "react";
import { Col, Container, Row } from "rsuite";
import { LinedTitle } from "../../common/components/LinedTitle";
import ReadMore from "../../common/components/ReadMore";
import { NewsCard } from "../../common/components/NewsCard";
import Head from "../../common/components/Head";
import FaqWidget from "../../common/components/FaqWidget";
import moment from "moment";

class News extends React.Component {
  render() {
    const { contents, faqs } = this.props;
    const featuredNews = contents.filter((content) => content.pin === true);
    const featuredVlog = contents.filter(
      (content) => content.pin === true && content.category === "vlog"
    );
    const vlogs = contents.filter((content) => content.category === "vlog");
    const news = contents.filter((content) => content.category === "news");
    return (
      <div className="blog-lists">
        <Head title="Мэдээ, мэдээлэл" />
        <section>
          <Container>
            <Row gutter={50}>
              <Col md={16} xs={24}>
                <LinedTitle title="Влог & блог" />
                <Row gutter={40}>
                  <Col md={10} sm={24}>
                    <NewsCard data={featuredVlog[0]} type="vlog-card" />
                  </Col>
                  <Col md={14} sm={24}>
                    <ul className="blog-list" style={{ height: "auto" }}>
                      {vlogs.slice(0, 4).map((vlog) => (
                        <div key={vlog._id}>
                          <NewsCard type="title-card" data={vlog} />
                        </div>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col md={8} xs={24}>
                <FaqWidget data={faqs} />
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <LinedTitle title="Мэдээ, мэдээлэл" />
            <Row gutter={40}>
              <Col md={8} xs={24}>
                <div>
                  <span>
                    <i className="fas fa-calendar" />
                    {moment(featuredNews[0].createdAt, "x").format(
                      "YYYY-MM-DD"
                    )}
                  </span>
                </div>
                <NewsCard data={featuredNews[0]} />
              </Col>
              <Col md={16} xs={24}>
                <Row gutter={40}>
                  {news.slice(0, 4).map((content) => (
                    <>
                      <Col md={12} sm={24} key={content._id}>
                        <div>
                          <span>
                            <i className="fas fa-calendar" />
                            {moment(content.createdAt, "x").format(
                              "YYYY-MM-DD"
                            )}
                          </span>
                        </div>
                        <NewsCard type="side-card" data={content} />
                      </Col>
                    </>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
          <div className="read-more-section">
            <ReadMore to="/news" />
          </div>
        </section>
        {/* <section>
          <Container>
            <LinedTitle title="Үйл ажиллагааны тайлан" />
            <Row gutter={40}>
              <Col md={8} xs={24}>
                <NewsCard />
              </Col>
              <Col md={8} xs={24}>
                <NewsCard />
              </Col>
              <Col md={8} xs={24}>
                <NewsCard />
              </Col>
            </Row>
          </Container>
          <div className="read-more-section">
            <ReadMore to="/" />
          </div>
        </section> */}
      </div>
    );
  }
}

export default News;
