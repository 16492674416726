const commentAdd = `
  mutation commentAdd($doc: CommentInput){
    commentAdd(doc: $doc){
      _id
    }
  }
`;

const commentRemove = `
  mutation commentRemove($_id: String) {
    commentRemove(_id: $_id)
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  commentRemove,
  commentAdd,
};
