import React from 'react';
import { Container, Row, Col, Input, Button } from 'rsuite';
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: ''
    };
  }

  handleInputChange = value => {
    this.setState({
      password: value
    });
  };

  submit = () => {
    const { password } = this.state;

    if (password) {
      this.props.resetPassword(password);
    }
  };

  renderForm() {
    return (
      <form className="login-form job-form">
        <Row gutter={100}>
          <Col sm={24}>
            <div className="form-item">
              <label>Шинэ нууц үг</label>
              <Input
                placeholder="Нууц үг"
                type="password"
                onChange={this.handleInputChange}
              />
            </div>
          </Col>
          <Col sm={24}>
            <div className="form-item">
              <Button
                className="add login"
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  this.submit(e);
                }}
              >
                <span>Батлах</span>
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    );
  }

  render() {
    return (
      <div>
        <Container className="min">
          <section>
            <h3 className="section-title" style={{ fontSize: '46px' }}>
              Нууц үг сэргээх
            </h3>
            <hr />
            <Row style={{ margin: '4rem 0' }} gutter={80}>
              <Col md={16}>{this.renderForm()}</Col>
              <Col md={8}></Col>
            </Row>
          </section>
        </Container>
      </div>
    );
  }
}

export default ResetPassword;
