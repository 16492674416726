const usersListQuery = `
 query users($email: String, $phone: String, $username: String) {
    users(email: $email, phone: $phone, username: $username) {
      _id
      username
      email
      role
      avatar
      firstName
      lastName
      phone
      position
      password
    }
  }
`;
const userDetailQuery = `
  query userDetail($_id: String) {
    userDetail(_id: $_id) {
      _id
      username
      email
      role
      avatar
      firstName
      lastName
      phone
      position
      password
    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  usersListQuery,
  userDetailQuery
};
