const faqListQuery = `
  query faqs {
    faqs {
      _id
      question
      answer
    }
  }
`;
const contentListQuery = `
 query contents($title: String, $category: String, $importand: String) {
    contents(title: $title, category: $category, importand: $importand) {
      _id
      title
      category
      importand
      pictures
      description
      content
    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  faqListQuery,
  contentListQuery,
};
