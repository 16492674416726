import React from 'react';
import { withRouter } from 'react-router-dom';
import Activity from '../components/Activity';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { queries } from '../graphql';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
class ActivityContainer extends React.Component {
  render() {
    const { contentListQuery, headQuery } = this.props;

    if (contentListQuery && contentListQuery.loading) {
      return null;
    }
    if (headQuery && headQuery.loading) {
      return null;
    }

    const contents = contentListQuery.contents || [];
    const head = headQuery.contents && headQuery.contents ? headQuery.contents[0] : '';

    const updatedProps = {
      ...this.props,
      contents,
      head,
    };

    return <Activity {...updatedProps} />;
  }
}
const propTypes = {
  contentListQuery: PropTypes.object.isRequired,
  headQuery: PropTypes.object,
};
ActivityContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.contentListQuery), {
      name: 'contentListQuery',
      options: ({ queryParams, props }) => {
        return {
          variables: {
            category: queryParams.category,
          },
        };
      },
    }),
    graphql(gql(queries.contentListQuery), {
      name: 'headQuery',
      options: () => {
        return {
          variables: {
            category: 'activity-head',
          },
        };
      },
    })
  )(ActivityContainer)
);
