const currentUser = `
  query currentUser {
    currentUser {
      _id
      username
      firstName
      lastName
      role
    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default { currentUser };
