import React from 'react';
import {
  Button,
  ButtonToolbar,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  SelectPicker
} from 'rsuite';
import Uploader from '../../../../common/components/Uploader';

export default class CategoryForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: props.detail.categoryId || '',
      show: false,
      formValue: props.detail || {},
      image: props.detail.image || ''
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }

  onFormChange = formValue => {
    this.setState({ formValue });
  };

  onCategoryChange = value => {
    this.setState({ category: value });
  };
  renderFileUpload(name, label) {
    const file = this.state[name];

    return (
      <div>
        <Uploader
          defaultFileList={[file]}
          onChange={e => {
            console.log('onchange');
            console.log(e);
            this.setState({ image: e ? e[0].url : null });
          }}
          disabled={false}
          label={label}
        />
        {/* {file && <Avatar shape="square" size={64} src={file} />} */}
      </div>
    );
  }

  onSubmit = () => {
    const { formValue, category, image } = this.state;
    const { detail, configCategoryEdit, configCategoryAdd } = this.props;
    const params = {
      doc: {
        _id: formValue._id,
        name: formValue.name,
        categoryId: category || null,
        image: image
      }
    };

    if (detail._id) {
      return configCategoryEdit(params);
    }

    return configCategoryAdd(params);
  };

  render() {
    const { category, image } = this.state;

    return (
      <Form
        onChange={formValue => {
          this.onFormChange(formValue);
        }}
        formValue={this.state.formValue}
      >
        <FormGroup>
          <ControlLabel>Нэр</ControlLabel>
          <FormControl name="name" />
          <HelpBlock>Required</HelpBlock>
        </FormGroup>
        <FormGroup>
          <ControlLabel>Эцэг ангилал</ControlLabel>
          <SelectPicker
            data={this.props.categories}
            style={{ width: 300 }}
            onChange={value => this.onCategoryChange(value)}
            defaultValue={category ? category : undefined}
          />
          <FormGroup>
            <ControlLabel>Зураг</ControlLabel>
            {this.renderFileUpload('image', 'Зураг оруулах')}
          </FormGroup>

          {image && (
            <div className="ant-avatar">
              <img src={image} alt="" />
            </div>
          )}
        </FormGroup>
        <FormGroup>
          <ButtonToolbar>
            <Button appearance="primary" onClick={this.onSubmit}>
              Хадгалах
            </Button>
            <Button appearance="default" onClick={this.props.onCancel}>
              Цуцлах
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    );
  }
}
