import React from 'react';
import MarkMap from '../components/MarkMap';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
import { mutations, queries } from '../graphql';
import { Alert } from 'rsuite';

class MarkMapContainer extends React.Component {
  constructor(props) {
    super(props);

    this.companyAdd = this.companyAdd.bind(this);
    this.companyEdit = this.companyEdit.bind(this);
    this.companyRate = this.companyRate.bind(this);
  }

  companyAdd(variables) {
    const { companyAdd } = this.props;
    companyAdd({ variables })
      .then(() => {
        Alert.success('Амжилттай үнэлгээ өглөө');
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        Alert.error(err);
      });
  }

  companyEdit(variables) {
    const { companyEdit } = this.props;

    companyEdit({ variables })
      .then(() => {
        // this.props.onCancel();
        Alert.success('Амжилттай үнэлгээ хуваалцлаа');
      })
      .catch((err) => {
        console.log(err);
        Alert.error(err);
      });
  }

  companyRate(variables) {
    const { companyRate } = this.props;
    companyRate({ variables })
      .then(() => {
        Alert.success('Амжилттай хадгаллаа');
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        Alert.error(err);
      });
  }

  render() {
    const { companyListQuery, companyDetailQuery } = this.props;

    if (companyDetailQuery && companyDetailQuery.loading) {
      return <></>;
    }

    if (companyListQuery && companyListQuery.loading) {
      return <></>;
    }

    const companyList = companyListQuery ? companyListQuery.companies || {} : {};

    const detail = companyDetailQuery ? companyDetailQuery.companyDetail || {} : {};

    const updatedProps = {
      ...this.props,
      companyAdd: this.companyAdd,
      companyEdit: this.companyEdit,
      companyRate: this.companyRate,
      companyList,
      detail,
    };
    return <MarkMap {...updatedProps} />;
  }
}

MarkMapContainer.propTypes = {
  id: PropTypes.string,
  companyListQuery: PropTypes.object,
  companyAdd: PropTypes.func,
  companyEdit: PropTypes.func,
  companyDetailQuery: PropTypes.object,
};

export default compose(
  graphql(gql(queries.companyListQuery), {
    name: 'companyListQuery',
  }),
  graphql(gql(queries.companyDetailQuery), {
    name: 'companyDetailQuery',
    skip: (props) => !props.id,
    options: (props) => {
      return {
        variables: {
          _id: props.id,
        },
      };
    },
  }),
  graphql(gql(mutations.companyAdd), {
    name: 'companyAdd',
    options: ({ doc }) => ({
      variables: {
        doc,
      },
    }),
  }),

  graphql(gql(mutations.companyEdit), {
    name: 'companyEdit',
    options: ({ _id, doc }) => ({
      variables: {
        _id,
        doc,
      },
    }),
  }),
  graphql(gql(mutations.companyRate), {
    name: 'companyRate',
  })
)(MarkMapContainer);
