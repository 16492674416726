import React from 'react';
import { Col, Container, Row } from 'rsuite';
import moment from 'moment';
import Head from '../../common/components/Head';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import RelatedNews from '../containers/RelatedNews';
import Comment from '../containers/Comment';
class NewsDetail extends React.Component {
  render() {
    const { news } = this.props;
    return (
      <div>
        <Head title={news.title} ogData={news} />
        <Container className="min">
          <section>
            <Row gutter={80}>
              <Col md={16} sm={24}>
                <div className="details-bar">
                  <div>
                    <span>
                      <i className="fas fa-calendar" />
                      {moment(news.createdAt, 'x').format('YYYY-MM-DD')}
                    </span>
                    <span>
                      <i className="fas fa-eye" />
                      {news.showCount}
                    </span>
                  </div>
                  <div className="share">
                    {/* <SEO title={news.name} description={news.title} url={shareUrl} /> */}
                    <FacebookShareButton url={`http://hurteemj.edu.mn/news-detail/${news._id}`}>
                      {' '}
                      <i className="fab fa-facebook-f" />
                    </FacebookShareButton>
                    <TwitterShareButton title={news.title} url={`http://hurteemj.edu.mn/news-detail/${news._id}`} via={'@hurteemj'}>
                      <i className="fab fa-twitter" />
                    </TwitterShareButton>
                  </div>
                </div>
                <div className="content">
                  <h3>{news.title}</h3>
                  <div className="main-content">
                    {/* <img src={news.pictures} alt={news.imageNote} /> */}
                    <div dangerouslySetInnerHTML={{ __html: news.content }} />
                  </div>
                </div>
                <Comment id={news._id} />
              </Col>
              <Col md={8} xs={24}>
                <RelatedNews category={news.category} />
              </Col>
            </Row>
          </section>
        </Container>
      </div>
    );
  }
}

export default NewsDetail;
