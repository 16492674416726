import React from 'react';
import { Route } from 'react-router-dom';
import Partners from './containers/Partners';
import Greeting from './containers/Greeting';
import About from './containers/About';
import Timeline from './containers/Timeline';
import Activity from './containers/Activity';
import Intro from './containers/Intro';
import queryString from 'query-string';
import AboutDetail from './containers/AboutDetail';

const routes = () => [
  <Route
    path="/partners"
    exact
    key="/partners"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <Partners queryParams={queryParams} history={history} />;
    }}
  />,
  <Route
    path="/about"
    exact
    key="/about"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <About queryParams={queryParams} history={history} type="about" />;
    }}
  />,
  <Route
    path="/activity"
    exact
    key="/activity"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <Activity queryParams={queryParams} history={history} type="activity" />;
    }}
  />,
  <Route
    path="/greeting"
    exact
    key="/greeting"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <Greeting queryParams={queryParams} history={history} type="greeting" />;
    }}
  />,
  <Route
    path="/timeline"
    exact
    key="/timeline"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <Timeline queryParams={queryParams} history={history} type="timeline" />;
    }}
  />,
  <Route
    path="/intro"
    exact
    key="/intro"
    component={({ location, history }) => {
      const queryParams = queryString.parse(location.search);
      return <Intro queryParams={queryParams} history={history} type="intro" />;
    }}
  />,
  <Route
    path="/about-detail/:id"
    exact
    key="/about-detail/:id"
    component={({ location, history, match }) => {
      const queryParams = queryString.parse(location.search);
      const id = match.params.id;
      return <AboutDetail queryParams={queryParams} id={id} history={history} />;
    }}
  />,
];

export default routes;
