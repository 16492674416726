import React from 'react';
import Search from '../components/Search';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import queries from '../graphql/queries';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
class SearchContainer extends React.Component {
  render() {
    console.log(`${this.props} helloe `)
    const { contentListQuery } = this.props;

    if (contentListQuery && contentListQuery.loading) {
      return null;
    }
    const contents = contentListQuery.contents || [];
    const updatedProps = {
      ...this.props,
      contents,
    };
    return <Search {...updatedProps} />;
  }
}

const propTypes = {
  contentListQuery: PropTypes.object.isRequired,
};
SearchContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.contentListQuery), {
      name: 'contentListQuery',
      options: ({ queryParams }) => {
        console.log(queryParams)
        return {
          variables: {
            title: queryParams.title,
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        };
      },
    })
  )(SearchContainer)
);
