import React from 'react';
import Comment from '../components/Comment';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { queries, mutations } from '../graphql';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import { Alert } from 'rsuite';
class CommentContainer extends React.Component {
  constructor(props) {
    super(props);

    this.commentAdd = this.commentAdd.bind(this);
  }

  commentAdd(variables) {
    const { commentAdd } = this.props;
    commentAdd({ variables })
      .then(() => {
        Alert.success('Амжилттай нэмлээ');
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
        Alert.error(err);
      });
  }
  render() {
    const { commentsQuery } = this.props;

    if (commentsQuery && commentsQuery.loading) {
      return <></>;
    }

    const comments = commentsQuery ? commentsQuery.comments || {} : {};
    const updatedProps = {
      ...this.props,
      commentAdd: this.commentAdd,
      comments,
    };

    return <Comment {...updatedProps} />;
  }
}
const propTypes = {
  commentAdd: PropTypes.func,
  commentsQuery: PropTypes.object,
};

CommentContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(mutations.commentAdd), {
      name: 'commentAdd',
      options: ({ doc }) => ({
        variables: {
          doc,
        },
      }),
    }),
    graphql(gql(queries.commentsQuery), {
      name: 'commentsQuery',
      skip: (props) => !props.id,
      options: (props) => {
        return {
          variables: {
            contentId: props.id,
          },
        };
      },
    })
  )(CommentContainer)
);
