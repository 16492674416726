import React from 'react';
import RelatedNews from '../components/RelatedNews';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { queries } from '../graphql';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
class RelatedNewsContainer extends React.Component {
  render() {
    const { relatedListQuery } = this.props;

    if (relatedListQuery && relatedListQuery.loading) {
      return null;
    }

    const related = relatedListQuery.contents || [];
    const updatedProps = { ...this.props, related };

    return <RelatedNews {...updatedProps} />;
  }
}
const propTypes = {
  contentDetailQuery: PropTypes.object.isRequired,
};
RelatedNewsContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.contentListQuery), {
      name: 'relatedListQuery',
      options: ({ category }) => {
        return {
          variables: {
            category: category,
            page: '1',
            perPage: '5',
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        };
      },
    })
  )(RelatedNewsContainer)
);
