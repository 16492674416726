import React from "react";
import { Container } from "rsuite";
import { FlexSection } from "../../common/components/FlexSection";
class Advice extends React.Component {
  render() {
    const { contents } = this.props;
    return (
      <section className="page">
        <Container>
          <FlexSection
            title={contents[0].title}
            description={contents[0].description}
            imgs={contents[0].pictures}
            url="/infrastructure"
          />
          <FlexSection
            title={contents[1].title}
            description={contents[1].description}
            imgs={contents[1].pictures}
            url="/education"
            reversed
          />
          <FlexSection
            title={contents[2].title}
            description={contents[2].description}
            imgs={contents[2].pictures}
            url="/employment"
          />
          <FlexSection
            title={contents[3].title}
            description={contents[3].description}
            imgs={contents[3].pictures}
            url="/independence"
            reversed
          />
          <FlexSection
            title={contents[4].title}
            description={contents[4].description}
            imgs={contents[4].pictures}
            url="/law"
          />
        </Container>
      </section>
    );
  }
}

export default Advice;
