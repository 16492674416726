import React from 'react';
import { Sidenav, Nav, Dropdown, Icon } from 'rsuite';
import { Link } from 'react-router-dom';
const NavLink = (props) => (
  <li className="rs-nav-item">
    <Link to={props.to} className="rs-nav-item-content">
      {props.children}
    </Link>
  </li>
);

const DropdownLink = (props) => (
  <li className="rs-dropdown-item">
    <Link to={props.to} className="rs-dropdown-item-content">
      {props.children}
    </Link>
  </li>
);

function logOut() {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  window.location.href = '/';
}
const SideNav = () => {
  return (
    <div style={{ width: 250 }}>
      <Sidenav appearance="inverse" defaultOpenKeys={['']} activeKey="1">
        <Sidenav.Body>
          <Nav>
            <Nav.Item eventKey="1" icon={<Icon icon="dashboard" />}>
              Dashboard
            </Nav.Item>
            <li className="rs-nav-item">
              <a href="/" target="_blank" className="rs-nav-item-content">
                <i className="fas fa-eye"></i> Сайт харах
              </a>
            </li>
            <NavLink to="/dashboard/users">Хэргэлэгчид</NavLink>
            <Dropdown eventKey="1" title="Танилцуулга" icon={<Icon icon="copy-o" />}>
              <DropdownLink to="/dashboard/content?category=intro" eventKey="1-1">
                Танилцуулга
              </DropdownLink>
              <DropdownLink to="/dashboard/content?category=timeline" eventKey="1-2">
                Түүхэн замнал
              </DropdownLink>
              <DropdownLink to="/dashboard/content?category=activity" eventKey="1-3">
                Үйл ажиллагаа
              </DropdownLink>
              <DropdownLink to="/dashboard/content?category=activity1" eventKey="1-4">
                Бие даан амьдрахад дэмжих хөтөлбөр
              </DropdownLink>
              <DropdownLink to="/dashboard/content?category=activity2" eventKey="1-5">
                Нийгмийн оролцоог дэмжих хөтөлбөр
              </DropdownLink>
              <DropdownLink to="/dashboard/content?category=activity3" eventKey="1-6">
                Боловсролын хөтөлбөр
              </DropdownLink>
            </Dropdown>
            <Dropdown eventKey="5" title="Хүртээмжтэй орчин" icon={<Icon icon="bar-chart" />}>
              {/* <DropdownLink to="/dashboard/mark-test" eventKey="5-1">
                Шалгуур
              </DropdownLink> */}
              <DropdownLink eventKey="5-3" to="/dashboard/mark-categories">
                Үнэлгээний тохиргоо
              </DropdownLink>
            </Dropdown>
            <Dropdown eventKey="2" title="Мэдээ & Блог" icon={<Icon icon="copy-o" />}>
              <DropdownLink to="/dashboard/content?category=news" eventKey="2-1">
                Мэдээ, Мэдээлэл
              </DropdownLink>
              <DropdownLink to="/dashboard/content?category=vlog" eventKey="2-2">
                Влог, Блог
              </DropdownLink>
              <DropdownLink to="/dashboard/law?category=activity-reports" eventKey="2-3">
                Үйл ажилагааны тайлан
              </DropdownLink>
              <DropdownLink to="/dashboard/faq" eventKey="2-4">
                FAQ
              </DropdownLink>
            </Dropdown>
            <Dropdown eventKey="3" title="Зөвлөгөө" icon={<Icon icon="copy-o" />}>
              <DropdownLink to="/dashboard/content?category=law" eventKey="3-0">
                Зөвлөгөө цэс
              </DropdownLink>
              <DropdownLink to="/dashboard/law" eventKey="3-1">
                Хууль, эрх зүй
              </DropdownLink>
              <DropdownLink to="/dashboard/content?category=advice" eventKey="3-2">
                Бие даан амьдрах
              </DropdownLink>
              <DropdownLink to="/dashboard/content?category=infrastructure" eventKey="3-3">
                Дэд бүтэц, барилга байгууламжийн хүртээмж
              </DropdownLink>
              <DropdownLink to="/dashboard/content?category=employment" eventKey="3-4">
                Хөдөлмөр эрхлэлт
              </DropdownLink>
              <DropdownLink to="/dashboard/content?category=education" eventKey="3-5">
                Тэгш хамран сургах боловсрол
              </DropdownLink>
            </Dropdown>
            <Dropdown eventKey="4" title="Холбоо барих" icon={<Icon icon="copy-o" />}>
              <DropdownLink to="/dashboard/feedback" eventKey="4-1">
                Санал хүсэлт
              </DropdownLink>
              <DropdownLink to="/dashboard/content?category=contact" eventKey="4-2">
                Холбоо барих
              </DropdownLink>
            </Dropdown>

            <Dropdown eventKey="6" title="Зурагнууд" icon={<Icon icon="file-image-o" />}>
              <DropdownLink to="/dashboard/images?type=intro" eventKey="5-1">
                Танилцуулга
              </DropdownLink>
              <DropdownLink to="/dashboard/images?type=partners" eventKey="5-2">
                Хамтрагч байгууллага
              </DropdownLink>
              <DropdownLink eventKey="5-3" to="/dashboard/images?type=about">
                Бидний тухай
              </DropdownLink>
            </Dropdown>
            <NavLink to="/dashboard/comments">Сэтгэгдэлүүд</NavLink>
            <Dropdown eventKey="7" title="Page Heads" icon={<Icon icon="file-image-o" />}>
              <DropdownLink to="/dashboard/pageheads?category=greeting-head" eventKey="7-1">
                Мэндчилгээ
              </DropdownLink>
              <DropdownLink to="/dashboard/pageheads?category=about-head" eventKey="7-2">
                Бидний тухай
              </DropdownLink>
              <DropdownLink to="/dashboard/pageheads?category=activity-head" eventKey="7-3">
                Үйл ажиллагаа
              </DropdownLink>
              <DropdownLink to="/dashboard/pageheads?category=faq-head" eventKey="7-4">
                FAQ
              </DropdownLink>
              <DropdownLink to="/dashboard/pageheads?category=law-head" eventKey="7-5">
                Хууль, Эрх зүй
              </DropdownLink>
              <DropdownLink to="/dashboard/pageheads?category=career-head" eventKey="7-6">
                Ажлын зар
              </DropdownLink>
            </Dropdown>
            <Nav.Item eventKey="7" icon={<Icon icon="sign-out" />} onClick={logOut}>
              Гарах
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </div>
  );
};

export default SideNav;
