const feedbackQuery = `
  query feedbacks {
    feedbacks {
      _id
      name
      email
      createdAt
      feedback
    }
  }
`;
const feedbackDetailQuery = `
  query feedbackDetail($_id: String) {
    feedbackDetail(_id: $_id) {
      _id
      name
      email
      feedback
      createdAt
    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  feedbackDetailQuery,
  feedbackQuery,
};
