import React from "react";
import { Container, Row, Col, Dropdown } from "rsuite";
import logo from "../../../assets/img/logo.png";
import Slider from "react-slick";
import english from "../../../assets/img/english.png";
import android from "../../../assets/img/android.png";
import ios from "../../../assets/img/ios.png";
import { Link } from "react-router-dom";
import ThemeSwitcher from "../../common/components/ThemeSwitcher";
class Footer extends React.Component {
  logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    window.location.href = "/";
  };

  renderLogin = () => {
    const { currentUser } = this.props;

    const content = currentUser ? (
      <Dropdown
        className="admin-dropdown"
        title={<i className="fas fa-user"></i>}
        activeKey="x"
      >
        {/* <DropdownLink to='/dashboard/mark-test'>Админ</DropdownLink> */}
        <li className="rs-dropdown-item">
          <a href="/dashboard/mark-test" className="rs-dropdown-item-content">
            Админ
          </a>
        </li>
        <Dropdown.Item onClick={this.logOut}>Гарах</Dropdown.Item>
      </Dropdown>
    ) : (
      <div className="user">
        <Link to="/login">
          <i className="fas fa-user"></i> <span>Нэвтрэх</span>
        </Link>
      </div>
    );

    return <>{content}</>;
  };

  render() {
    // const { contact } = this.props;

    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 7,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    };
    return (
      <div className="footer">
        <div className="partners">
          <Link to="/partners">
            <h3 className="common-title">Хамтрагч байгууллагууд</h3>
          </Link>
          <Container>
            <Slider {...settings}>
              {this.props.images.map((image) => (
                <div key={image._id}>
                  <a
                    className="partner-logo-wrapper"
                    href={image.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{ height: "60px" }}
                      src={image.images}
                      alt={image.description}
                    />
                  </a>
                </div>
              ))}
            </Slider>
          </Container>
        </div>
        <footer>
          <Container>
            <Row className="flex-v-center">
              <Col md={2} xs={12}>
                <Link to="/">
                  <img className="logo footer-logo" src={logo} alt="logo" />
                </Link>
              </Col>
              <Col md={12} xs={12}>
                <div className="site-desc">
                  <div className="h-text title">
                    <h1>UPMIL</h1>
                  </div>
                  <div className="h-text slogan-text">
                    <p>Таны эрхэм зэрэг, нандин чанарт …</p>
                  </div>
                </div>
              </Col>
              <Col md={10} xs={24}>
                <div className="control">
                  <div className="burger-menu d-hidden" onClick={this.openMenu}>
                    <i className="fas fa-bars" />
                  </div>
                  <ThemeSwitcher />

                  {this.renderLogin()}
                  <div
                    className="lang bg-image"
                    style={{ background: `url(${english})` }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Row className="footer-menu">
              <Col md={4} xs={12}>
                <div className="menu-col">
                  <h6>
                    <Link to="/intro">Танилцуулга</Link>
                  </h6>
                  <Link to="/greeting">Байгууллагын мэндчилгээ</Link>
                  <Link to="/timeline">Түүхэн замнал</Link>
                  <Link to="/about">Бидний тухай</Link>
                  <Link to="/activity">Үйл ажиллагаа</Link>
                </div>
              </Col>
              <Col md={4} xs={12}>
                <div className="menu-col">
                  <h6>Хүртээмжтэй орчин</h6>
                  <Link to="/mark-map">Хүртээмжтэй газар хайх</Link>
                  <Link to="/mark-report">Үнэлгээний тайлан</Link>
                </div>
              </Col>
              <Col md={4} xs={12}>
                <div className="menu-col">
                  <h6>
                    <Link to="/news-main">Мэдээ & Блог</Link>
                  </h6>
                  <Link to="/news">Mэдээ, мэдээлэл</Link>
                  <Link to="/blogs">Влог & Блог</Link>
                  <Link to="/activity-reports">Үйл ажиллагааны тайлан</Link>

                  <Link to="/faq">Түгээмэл асуулт хариулт</Link>
                </div>
              </Col>
              <Col md={4} xs={12}>
                <div className="menu-col">
                  <h6>
                    <Link to="/advice">Зөвлөгөө</Link>
                  </h6>
                  <Link to="/law">Хууль, эрх зүй</Link>
                  <Link to="/independence">Бие даан амьдрах</Link>
                  <Link to="/infrastructure">
                    Дэд бүтэц, барилга байгууламжийн хүртээмж
                  </Link>
                  <Link to="/employment">Хөдөлмөр эрхлэлт</Link>
                  <Link to="/education">Тэгш хамран сургах боловсрол</Link>
                </div>
              </Col>
              <Col md={4} xs={12}>
                <div className="menu-col">
                  <h6>Ажлын байр зуучлал</h6>
                  <Link to="/job-list">Ажлын зар</Link>
                  <Link to="/job-form">Зар нэмэх</Link>
                </div>
              </Col>
              <Col md={4} xs={12}>
                <div className="menu-col">
                  <h6>Холбоо барих</h6>
                  {/* <div dangerouslySetInnerHTML={{ __html: contact[0].content }} /> */}
                  <Link to="#">+976 76015800</Link>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/MILC.TugeemelKhugjil"
                  >
                    https://www.facebook.com/MILC.TugeemelKhugjil
                  </a>
                </div>
              </Col>
            </Row>
            <div className="app-icons">
              <div className="android">
                <a href="/">
                  <img src={android} alt="android" />
                </a>
              </div>
              <div className="ios">
                <a href="/">
                  <img src={ios} alt="ios" />
                </a>
              </div>
            </div>
            <div className="copyright">
              <div>
                <span>
                  © {new Date().getFullYear()} hurteemj.mn. All Rights Reserved
                </span>
              </div>
              <div className="gerege-agency float-right">
                <div className="gerege-info">
                  <span className="gerege-icon-code"></span>
                  by
                </div>
                <a
                  href="http://gerege.agency/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <span className="gerege-icon-char-g"></span>
                  <span className="gerege-icon-char-e"></span>
                  <span className="gerege-icon-char-r"></span>
                  <span className="gerege-icon-char-e"></span>
                  <span className="gerege-icon-char-g"></span>
                  <span className="gerege-icon-char-e"></span>
                </a>
              </div>
            </div>
          </Container>
        </footer>
      </div>
    );
  }
}

export default Footer;
