import React from 'react';
import { NewsCard } from '../../common/components/NewsCard';
class RelatedNews extends React.Component {
  render() {
    const { related } = this.props;
    return (
      <div className="related-news">
        {related.map((content) => (
          <NewsCard key={content._id} type="side-card" data={content} />
        ))}
      </div>
    );
  }
}

export default RelatedNews;
