import React from 'react';
import { ButtonToolbar, Button } from 'rsuite';
import ModalTrigger from '../../../../common/components/ModalTrigger';
import CategoryForm from '../containers/CategoryForm';
import ConfigForm from '../containers/ConfigForm';
import { confirmAlert } from 'react-confirm-alert';
import Configs from './Configs';

export default class MarkCategories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      formValue: {},
      configList: props.categoriesList || [],
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }

  onFormChange = (formValue) => {
    this.setState({ formValue });
  };

  onRemove = (_id) => {
    confirmAlert({
      title: 'Та устгахдаа итгэлтэй байна уу?',
      buttons: [
        {
          label: 'Устгах',
          onClick: (e) => this.props.configCategoryRemove(_id),
        },
        {
          label: 'Болих',
        },
      ],
    });
  };

  onRemoveConfig = (_id) => {
    confirmAlert({
      title: 'Та устгахдаа итгэлтэй байна уу?',
      buttons: [
        {
          label: 'Устгах',
          onClick: (e) => this.props.configRemove(_id),
        },
        {
          label: 'Болих',
        },
      ],
    });
  };

  render() {
    const { configList } = this.state;

    const categories = configList.map((cat) => ({
      label: cat.name,
      value: cat._id,
    }));

    return (
      <div>
        <h4 className="d-title">Үнэлгээ</h4>
        <div className="admin-bar">
          <ButtonToolbar>
            <ModalTrigger
              btnText={'Үнэлгээний ангилал нэмэх'}
              btnClassName={'create - btn'}
              iconClassName={'fas fa-plus'}
              size="xs"
              btnColor={'blue'}
              content={(props) => <CategoryForm {...props} categories={categories} />}
            ></ModalTrigger>
          </ButtonToolbar>

          <ButtonToolbar>
            <ModalTrigger
              btnText={'Үнэлгээ нэмэх'}
              btnClassName={'create - btn'}
              iconClassName={'fas fa-plus'}
              size="xs"
              btnColor={'blue'}
              content={(props) => <ConfigForm {...props} categories={categories} />}
            ></ModalTrigger>
          </ButtonToolbar>
        </div>
        <ul className="mark-cats">
          {configList.map((category) => (
            <li key={category._id}>
              <div className="cat-row">
                <strong>{category.name}</strong>
                <ModalTrigger
                  iconClassName={'fas fa-edit'}
                  size="xs"
                  btnColor={'cyan'}
                  content={(props) => <CategoryForm {...props} id={category._id} categories={categories} />}
                ></ModalTrigger>
                <Button color="red" appearance="ghost" onClick={() => this.onRemove(category._id)}>
                  <i className="fas fa-trash"></i>
                </Button>
              </div>
              {category.childCategories && (
                <ul className="child-cats">
                  {category.childCategories.map((child) => (
                    <li key={child._id}>
                      <div className="cat-row">
                        <strong>{child.name}</strong>
                        <ModalTrigger
                          iconClassName={'fas fa-edit'}
                          size="xs"
                          btnColor={'cyan'}
                          content={(props) => <CategoryForm {...props} id={child._id} categories={categories} />}
                        ></ModalTrigger>
                        <Button color="red" appearance="ghost" onClick={() => this.onRemove(child._id)}>
                          <i className="fas fa-trash"></i>
                        </Button>
                      </div>
                      <Configs configs={child.configs} />
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
