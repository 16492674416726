const contentListQuery = `
 query contents($title: String, $category: String, $importand: String) {
    contents(title: $title, category: $category, importand: $importand) {
      _id
      title
      category
      importand
      pictures
      description
      content
      createdAt
    }
  }
`;

const contentDetailQuery = `
  query contentDetail($_id: String) {
    contentDetail(_id: $_id) {
      _id
      category
      description
      title
      date
      content
      createdAt
      pictures
      importand
      pin
      imageNote
      showCount
    }
  }
`;

const contentsCountQuery = `
  query contentsCount($beginDate: String, $endDate: String, $title: String, $importand: String){
    contentsCount(beginDate: $beginDate, endDate: $endDate, title: $title, importand: $importand)
  }
`;

const getLawsListQuery = `
 query laws($beginDate: String, $endDate: String, $category: String, $title: String, $importand: String) {
    laws(beginDate: $beginDate, endDate: $endDate, category: $category, title: $title, importand: $importand) {
      _id
      title
      date
      category
      content
      file
    }
  }
`;
const lawDetailQuery = `
  query lawDetail($_id: String) {
    lawDetail(_id: $_id) {
      _id
      title
      date
      category
      content
      file
    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  contentListQuery,
  contentsCountQuery,
  getLawsListQuery,
  lawDetailQuery,
  contentDetailQuery,
};
