import React from 'react';
import { Button, Table } from 'rsuite';
// import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';

const { Column, HeaderCell, Cell } = Table;

export default class Comment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: ''
    };
  }

  onRemove = _id => {
    confirmAlert({
      title: 'Та устгахдаа итгэлтэй байна уу?',
      buttons: [
        {
          label: 'Устгах',
          onClick: () => this.props.commentRemove({ _id })
        },
        {
          label: 'Болих'
        }
      ]
    });
  };

  renderActions = (_id, contentId) => {
    return (
      <span className="action-wrapper">
        <Button color="red" appearance="ghost" onClick={() => this.onRemove(_id)}>
          <i className="fas fa-trash"></i>
        </Button>
        {contentId && (
          <a rel="noreferrer" href={`/news-detail/${contentId}`} target="_blank">
            <i className="fas fa-share"></i>
          </a>
        )}
      </span>
    );
  };

  render() {
    const { comments } = this.props;
    return (
      <div>
        <h4 className="d-title">Comments</h4>
        <Table height={400} data={comments}>
          <Column width={250}>
            <HeaderCell>Нэр</HeaderCell>
            <Cell dataKey="userName" />
          </Column>
          <Column width={500}>
            <HeaderCell>Сэтгэгдэл</HeaderCell>
            <Cell dataKey="content" />
          </Column>
          <Column width={140} fixed="right">
            <HeaderCell>Үйлдэл</HeaderCell>

            <Cell>{rowData => this.renderActions(rowData._id, rowData.contentId)}</Cell>
          </Column>
        </Table>
      </div>
    );
  }
}
