import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import Login from '../components/Login.js';
import { mutations } from '../graphql';
import { Alert } from 'rsuite';
const LoginContainer = props => {
  const { loginMutation } = props;

  const login = variables => {
    loginMutation({ variables })
      .then(({ data }) => {
        const { token, refreshToken } = data.login;
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        window.location.href = '/';
      })
      .catch(e => {
        Alert.error('Нэр эсвэл нууц үг буруу байна');
      });
  };

  const updatedProps = {
    ...props,
    login
  };

  return <Login {...updatedProps} />;
};

LoginContainer.propTypes = {
  loginMutation: PropTypes.func
};

export default withRouter(
  compose(
    graphql(gql(mutations.login), {
      name: 'loginMutation'
    })
  )(LoginContainer)
);
