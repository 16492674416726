import React from 'react';
import { Helmet } from 'react-helmet';
const Head = (props) => {
  return (
    <Helmet>
      <title>{props.title ? props.title + ' - Хүртээмж' : 'Хүртээмж'}</title>
      <meta property="og:title" content={props.title ? props.title + ' - Хүртээмж' : 'Хүртээмж'} />
      {/* <meta property="og:type" content="video.movie" /> */}
      <meta property="og:url" content={props.ogData && `http://hurteemj.edu.mn/news-detail/${props.ogData._id}`} />
      <meta property="og:image" content={props.ogData && props.ogData.pictures} />
      <meta property="og:site_name" content="Hurteemj" />
      <meta property="og:description" content={props.ogData && props.ogData.description} />
    </Helmet>
  );
};
export default Head;
