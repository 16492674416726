import React, { Component, Fragment } from 'react';
import { Button } from 'rsuite';

class LawDetail extends Component {
  render() {
    return (
      <Fragment>
        <h1>{this.props.lawDetail.title}</h1>

        <div className="content">
          <div className="main-content">
            <div
              className="law-detail-content"
              dangerouslySetInnerHTML={{
                __html: this.props.lawDetail.content,
              }}
            />
            {this.props.lawDetail?.file && (
              <div className="law-download">
                <a href={this.props.lawDetail.file[0]} title="Татаж авах">
                  <i className="fas fa-download" /> Татаж авах
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="modal-actions">
          <Button onClick={this.props.onCancel}>Буцах</Button>
        </div>
      </Fragment>
    );
  }
}

export default LawDetail;
