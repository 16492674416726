import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Loading from '../../common/components/Loading';
import NewsList from '../components/NewsList';
import { queries } from '../graphql';
class NewsListContainer extends React.Component {
  render() {
    const { contentListQuery, contentsCountQuery } = this.props;

    if (contentListQuery && contentListQuery.loading) {
      return <Loading />;
    }

    const contents = contentListQuery.contents || [];
    const count = contentsCountQuery.contentsCount;

    const updatedProps = {
      ...this.props,
      contents,
      count,
    };

    return <NewsList {...updatedProps} />;
  }
}
const propTypes = {
  contentListQuery: PropTypes.object.isRequired,
};
NewsListContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.contentListQuery), {
      name: 'contentListQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            title: queryParams.title,
            category: 'news',
            importand: queryParams.importand,
            pin: queryParams.pin,
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        };
      },
    }),
    graphql(gql(queries.contentsCountQuery), {
      name: 'contentsCountQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            category: 'news',
            beginDate: queryParams.beginDate,
            endDate: queryParams.endDate,
            title: queryParams.title,
            importand: queryParams.importand,
          },
        };
      },
    })
  )(NewsListContainer)
);
