import React from 'react';
import {
  Button,
  Col,
  Container,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Row,
  Schema,
  SelectPicker,
} from 'rsuite';
import JobUploader from '../../common/components/JobUploader';
import Notice from '../../common/components/Notice';
import { disablityTypes, jobTypes, types } from '../../dashboard/constants';
class CareerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formValue: {},
      jobType: '',
      type: '',
      pictures: [],
      errorVisible: false,
      disablityType: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  errorStyles = (errorVisible) => {
    return {
      display: errorVisible ? 'block' : 'none',
      color: 'red',
      marginTop: 6,
    };
  };

  onJobChange = (value) => {
    this.setState({ jobType: value });
  };

  onTypeChange = (value) => {
    this.setState({ type: value });
  };

  onDisablityChange = (value) => {
    this.setState({ disablityType: value });
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  handleFileChange = (name, files) => {
    this.setState({ [name]: files });
  };
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onFormChange = (formValue) => {
    this.setState({ formValue });
  };

  onSubmit = () => {
    const { formValue, jobType, pictures, type } = this.state;
    const { careerAdd } = this.props;

    const params = {
      doc: {
        ...formValue,
        jobType: jobType,
        type: type,
        images: pictures && pictures.length ? pictures[0].url : 'no-image',
      },
    };
    return careerAdd(params);
  };

  renderFileUpload(name, label) {
    const files = this.state[name];
    const fileList = files || [];

    return (
      <div>
        <JobUploader
          defaultFileList={fileList}
          onChange={(e) => this.handleFileChange(name, e)}
          disabled={false}
          label={label}
        />
      </div>
    );
  }

  renderForm() {
    const { formValue, jobType, type, disablityType } = this.state;
    const { StringType } = Schema.Types;
    const model = Schema.Model({
      jobType: StringType().isRequired(
        'Энэ талбарыг заавал бөглөх шаардлагатай.'
      ),
      type: StringType().isRequired('Энэ талбарыг заавал бөглөх шаардлагатай.'),
      workingHours: StringType().isRequired(
        'Энэ талбарыг заавал бөглөх шаардлагатай.'
      ),
      company: StringType().isRequired(
        'Энэ талбарыг заавал бөглөх шаардлагатай.'
      ),
      phone: StringType().isRequired(
        'Энэ талбарыг заавал бөглөх шаардлагатай.'
      ),
      title: StringType().isRequired(
        'Энэ талбарыг заавал бөглөх шаардлагатай.'
      ),
      description: StringType().isRequired(
        'Энэ талбарыг заавал бөглөх шаардлагатай.'
      ),
    });

    return (
      <Form
        onChange={(formValue) => {
          this.onFormChange(formValue);
        }}
        formValue={formValue}
        className='job-form'
        layout='horizontal'
        model={model}
      >
        <Row gutter={100}>
          <Col sm={12}>
            <FormGroup>
              <ControlLabel>
                Төрөл сонгох <span>*</span>
              </ControlLabel>
              <div className='form-item' style={{ marginBottom: 0 }}>
                <FormControl
                  style={{ width: 360 }}
                  accepter={SelectPicker}
                  name='type'
                  placeholder='Сонгох'
                  searchable={false}
                  data={types}
                  onChange={this.onTypeChange}
                  defaultValue={type ? type : undefined}
                />
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <ControlLabel>Хөгжлийн бэрхшээлийн төрөл</ControlLabel>
              <div className='form-item' style={{ marginBottom: 0 }}>
                <FormControl
                  style={{ width: 360 }}
                  accepter={SelectPicker}
                  name='disablityType'
                  placeholder='Сонгох'
                  searchable={false}
                  data={disablityTypes}
                  onChange={this.onDisablityChange}
                  defaultValue={disablityType ? disablityType : undefined}
                />
              </div>
            </FormGroup>
          </Col>
          <Col sm={12}>
            <FormGroup>
              <ControlLabel>
                Чиглэл сонгох <span>*</span>
              </ControlLabel>
              {/* <SelectPicker errorMessage={errorMessage} name="jobType" searchable={false} data={jobTypes} onChange={this.onJobChange} defaultValue={jobType ? jobType : undefined} /> */}
              <div className='form-item' style={{ marginBottom: 0 }}>
                <FormControl
                  style={{ width: 360 }}
                  accepter={SelectPicker}
                  name='jobType'
                  placeholder='Сонгох'
                  searchable={false}
                  data={jobTypes}
                  onChange={this.onJobChange}
                  defaultValue={jobType ? jobType : undefined}
                />
              </div>
            </FormGroup>
          </Col>
          <Col sm={12}>
            <FormGroup>
              <ControlLabel>
                Ажлын цаг <span>*</span>
              </ControlLabel>
              <FormControl name='workingHours' placeholder='текст' />
            </FormGroup>
          </Col>
          <Col sm={12}>
            <FormGroup>
              <ControlLabel>Байршил</ControlLabel>
              <FormControl name='location' placeholder='текст' />
            </FormGroup>
          </Col>
          <Col sm={12}>
            <FormGroup>
              <ControlLabel>
                Зарлагч <span>*</span>
              </ControlLabel>
              <FormControl name='company' placeholder='текст' />
            </FormGroup>
          </Col>
          <Col sm={12}>
            <FormGroup>
              <ControlLabel>
                Утас <span>*</span>
              </ControlLabel>
              <FormControl name='phone' placeholder='текст' />
            </FormGroup>
          </Col>
          <Col sm={12}>
            <FormGroup>
              <ControlLabel>Цалин</ControlLabel>
              <FormControl name='salary' placeholder='текст' />
            </FormGroup>
          </Col>
          <Col sm={12}>
            <div className='form-item'>
              <label>Зураг сонгох</label>
              <Row gutter={60}>
                <Col md={6} sm={12}>
                  {this.renderFileUpload('pictures', 'Зураг оруулах')}
                </Col>
              </Row>
            </div>
          </Col>

          <Col sm={24}>
            <FormGroup>
              <ControlLabel>
                Зарын гарчиг <span>*</span>
              </ControlLabel>
              <FormControl name='title' placeholder='Гарчиг' />
              <HelpBlock>Заавал бөглөх</HelpBlock>
            </FormGroup>
          </Col>
          <Col sm={24}>
            <FormGroup>
              <ControlLabel>
                Тайлбар <span>*</span>
              </ControlLabel>
              <FormControl
                name='description'
                rows={6}
                placeholder='текст'
                componentClass='textarea'
              />
              <HelpBlock>Заавал бөглөх</HelpBlock>
            </FormGroup>
          </Col>
          <Col sm={12}>
            <div className='form-item'>
              <Button
                type='submit'
                onClick={this.onSubmit}
                style={{ width: '60%' }}
                className='add'
              >
                <span>Зар нэмэх</span> <i className='fas fa-plus' />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    return (
      <div>
        <Container className='min'>
          <section>
            <h3 className='section-title' style={{ fontSize: '46px' }}>
              Зар нэмэх
            </h3>
            <Notice text='' />
            {this.renderForm()}
          </section>
        </Container>
      </div>
    );
  }
}

export default CareerForm;
