import React from 'react';
import { withRouter } from 'react-router-dom';
import Education from '../components/Education';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { queries } from '../graphql';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
class EducationContainer extends React.Component {
  render() {
    const { contentListQuery, contentsCountQuery } = this.props;

    if (contentListQuery && contentListQuery.loading) {
      return null;
    }

    const contents = contentListQuery.contents || [];
    const count = contentsCountQuery.contentsCount;

    const updatedProps = {
      ...this.props,
      contents,
      count,
    };

    return <Education {...updatedProps} />;
  }
}
const propTypes = {
  contentListQuery: PropTypes.object.isRequired,
};
EducationContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(gql(queries.contentListQuery), {
      name: 'contentListQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            category: 'education',
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        };
      },
    }),
    graphql(gql(queries.contentsCountQuery), {
      name: 'contentsCountQuery',
      options: ({ queryParams }) => {
        return {
          variables: {
            beginDate: queryParams.beginDate,
            endDate: queryParams.endDate,
            title: queryParams.title,
            importand: queryParams.importand,
          },
        };
      },
    })
  )(EducationContainer)
);
