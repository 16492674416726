import React from 'react';
import { Col, Container, Row } from 'rsuite';
import Head from '../../common/components/Head';

class AboutDetail extends React.Component {
  render() {
    const { news } = this.props;
    return (
      <div>
        <Head title={news.title} ogData={news} />
        <Container className="min">
          <section>
            <Row gutter={80}>
              <Col md={16} sm={24}>
             
                <div className="content">
                  <h3>{news.title}</h3>
                  <div className="main-content">
                    {/* <img src={news.pictures} alt={news.imageNote} /> */}
                    <div dangerouslySetInnerHTML={{ __html: news.content }} />
                  </div>
                </div>
  
              </Col>
              
            </Row>
          </section>
        </Container>
      </div>
    );
  }
}

export default AboutDetail;
