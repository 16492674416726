import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import Editor from '../../../../common/components/Editor';
import moment from 'moment';
import Uploader from '../../../../common/components/Uploader';
import { lawCategories } from '../../../constants';
import { Button, ButtonToolbar, Form, FormGroup, HelpBlock, FormControl, ControlLabel, SelectPicker, DatePicker } from 'rsuite';
class LawsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      formValue: props.detail || {},
      category: props.detail.category || '',
      content: props.detail.content || '',
      editor: props.detail.content || '',
      date: props.detail.date || Date.now().toString(),
      file: props.detail.file || [],
    };
  }

  onEditorChange = (content) => {
    this.setState({ editor: content });
  };

  onDateChange = (date) => {
    this.setState({ date: date });
  };

  onFormChange = (formValue) => {
    this.setState({ formValue });
  };

  onCategoryChange = (value) => {
    this.setState({ category: value });
  };

  onFileChange = (files) => {
    this.setState({ file: files });
  };

  handleChange(name, files) {
    this.setState({ [name]: files });
  }

  onSubmit = () => {
    const { formValue, category, editor, date, file } = this.state;
    const { detail, lawAdd, lawEdit } = this.props;

    const lawParams = {
      doc: {
        ...formValue,
        category: category,
        content: editor,
        date: date,
        file: file ? file[0].url : [],
      },
    };

    if (detail._id) {
      lawParams._id = detail._id;
      return lawEdit({ lawParams });
    }

    return lawAdd({ lawParams });
  };

  renderFileUpload(name, label) {
    const files = this.state[name];
    const fileList = files || [];

    // const disabled = fileList.length === 1;

    return (
      <div>
        <Uploader defaultFileList={fileList} onChange={(e) => this.handleChange(name, e)} disabled={false} label={label} />
        {fileList.length > 0 && fileList[0].url && <Avatar shape="square" size={64} src={fileList[0].url} />}
      </div>
    );
  }

  render() {
    const { content, category, formValue, date } = this.state;
    console.log(date, Date.now().toString());
    return (
      <Form
        onChange={(formValue) => {
          this.onFormChange(formValue);
        }}
        formValue={formValue}
      >
        <FormGroup>
          <ControlLabel>Гарчиг</ControlLabel>
          <FormControl name="title" style={{ width: 926 }} />
          <HelpBlock>Заавал бөглөх</HelpBlock>
        </FormGroup>
        <FormGroup>
          <ControlLabel>Огноо</ControlLabel>
          <DatePicker name="date" style={{ width: 280 }} value={moment(date, 'x').format('YYYY-MM-DD')} onChange={this.onDateChange} />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Thumbnail</ControlLabel>
          {this.renderFileUpload('file', 'Файл оруулах')}
          <HelpBlock>Заавал бөглөх</HelpBlock>
        </FormGroup>
        <FormGroup>
          <ControlLabel>Төрөл</ControlLabel>
          <SelectPicker
            placement="topStart"
            name="category"
            data={lawCategories}
            style={{ width: 224 }}
            searchable={false}
            onChange={(value) => this.onCategoryChange(value)}
            defaultValue={category ? category : undefined}
          />
          <HelpBlock>Заавал бөглөх</HelpBlock>
        </FormGroup>
        <FormGroup>
          <ControlLabel>Агуулга</ControlLabel>
          <HelpBlock>Заавал бөглөх</HelpBlock>
          <Editor name="content" onChange={this.onEditorChange} content={content} />
        </FormGroup>
        <FormGroup>
          <ButtonToolbar>
            <Button appearance="primary" onClick={this.onSubmit}>
              Submit
            </Button>
            <Button appearance="default" onClick={this.props.onCancel}>
              Cancel
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
      //   <Form onSubmit={this.handleSubmit}>
      //     {getFieldDecorator('content', {
      //       initialValue: detail.content || '',
      //     })(<Input.TextArea type="hidden" className="hidden" />)}

      //     <FormItem {...formItemLayout} label="Огноо">
      //       {getFieldDecorator('date', {
      //         initialValue: moment(detail.date) || '',
      //         rules: [{ type: 'object', required: true, message: 'Огноо оруулна уу' }],
      //       })(<DatePicker format="MM/DD/YYYY" />)}
      //     </FormItem>

      //     <FormItem {...formItemLayout} label="Гарчиг">
      //       {getFieldDecorator('title', {
      //         initialValue: detail.title || '',
      //         rules: [
      //           {
      //             required: true,
      //             message: 'Гарчиг оруулна уу!',
      //             whitespace: true,
      //           },
      //         ],
      //       })(<Input />)}
      //     </FormItem>
      //     <FormItem {...formItemLayout} label="Ангилал">
      //       {getFieldDecorator('category', {
      //         initialValue: detail.category || '',
      //         rules: [{ required: true, message: 'Ангилал сонгоно уу' }],
      //       })(
      //         <select placeholder="Ангилал">
      //           <option value="Хууль">Хууль</option>
      //           <option value="Бодлого">Бодлого</option>
      //           <option value="Зохицуулалт">Зохицуулалт</option>
      //           <option value="Үндэсний хөтөлбөр">Үндэсний хөтөлбөр</option>
      //         </select>
      //       )}
      //     </FormItem>

      //     <FormItem label="Агуулга">{getFieldDecorator('content')(<Editor onChange={this.onEditorChange} content={detail.content} />)}</FormItem>

      //     <FormItem {...formItemLayout} label="Файл оруулах">
      //       {this.renderFileUpload('file', 'Файл оруулах')}
      //     </FormItem>

      //     <div className="modal-actions">
      //       <Button onClick={this.props.onCancel}>Буцах</Button>
      //       &nbsp;
      //       <Button type="primary" htmlType="submit" className="form-button">
      //         Хадгалах
      //       </Button>
      //     </div>
      //   </Form>
    );
  }
}

LawsForm.propTypes = {
  detail: PropTypes.object.isRequired,
};

export default LawsForm;
