const faqAdd = `
  mutation faqAdd($doc: FaqInput){
    faqAdd(doc: $doc){
      _id
    }
  }
`;

const faqEdit = `
  mutation faqEdit($doc: FaqInput){
    faqEdit(doc: $doc){
      _id
    }
  }
`;

const faqRemove = `
  mutation faqRemove($_id: String) {
    faqRemove(_id: $_id)
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  faqRemove,
  faqAdd,
  faqEdit
};
