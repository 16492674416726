import React from 'react';
import { Table, Button, ButtonToolbar } from 'rsuite';
import ModalTrigger from '../../../../common/components/ModalTrigger';
import FilterUser from '../../common/FilterUser';
import UserCreate from '../containers/UserCreate';
import { confirmAlert } from 'react-confirm-alert';

const { Column, HeaderCell, Cell } = Table;

export default class DashboardMark extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.users || {},
      show: false,
    };
  }

  onRemove = (_id) => {
    confirmAlert({
      title: 'Та устгахдаа итгэлтэй байна уу?',
      buttons: [
        {
          label: 'Устгах',
          onClick: (e) => this.props.usersRemove(_id),
        },
        {
          label: 'Болих',
        },
      ],
    });
  };

  renderActions = (_id) => {
    return (
      <span className='action-wrapper'>
        <ModalTrigger
          iconClassName={'fas fa-edit'}
          btnColor={'cyan'}
          content={(props) => <UserCreate {...props} id={_id} />}
        ></ModalTrigger>
        &nbsp;
        <Button
          color='red'
          appearance='ghost'
          onClick={() => this.onRemove(_id)}
        >
          <i className='fas fa-trash'></i>
        </Button>
      </span>
    );
  };

  render() {
    return (
      <div>
        <h4 className='d-title'>Хандалтын эрх</h4>
        <div className='admin-bar'>
          <FilterUser
            historyProps={this.props.history}
            queryParams={this.props.queryParams}
          />

          <ButtonToolbar>
            <ModalTrigger
              btnText={'Нэмэх'}
              btnClassName={'create - btn'}
              iconClassName={'fas fa-plus'}
              btnColor={'blue'}
              content={(props) => <UserCreate {...props} />}
            ></ModalTrigger>
          </ButtonToolbar>
        </div>
        <Table
          height={400}
          data={this.state.data}
          onRowClick={(data) => {
            console.log(data);
          }}
        >
          <Column width={200}>
            <HeaderCell>User name</HeaderCell>
            <Cell dataKey='username' />
          </Column>{' '}
          <Column width={200}>
            <HeaderCell>First Name</HeaderCell>
            <Cell dataKey='firstName' />
          </Column>
          <Column width={160}>
            <HeaderCell>Last Name</HeaderCell>
            <Cell dataKey='lastName' />
          </Column>
          <Column width={200}>
            <HeaderCell>Email</HeaderCell>
            <Cell dataKey='email' />
          </Column>
          <Column width={100}>
            <HeaderCell>Number</HeaderCell>
            <Cell dataKey='number' />
          </Column>
          <Column width={160}>
            <HeaderCell>Role</HeaderCell>
            <Cell dataKey='role' />
          </Column>
          <Column width={140} fixed='right'>
            <HeaderCell>Үйлдэл</HeaderCell>

            <Cell>{(rowData) => this.renderActions(rowData._id)}</Cell>
          </Column>
        </Table>
      </div>
    );
  }
}
