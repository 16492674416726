const imageAdd = `
  mutation imageAdd($doc: ImageInput){
    imageAdd(doc: $doc){
      _id
    }
  }
`;

const imageEdit = `
  mutation imageEdit($doc: ImageInput){
    imageEdit(doc: $doc){
      _id
    }
  }
`;

const imageRemove = `
  mutation imageRemove($_id: String) {
    imageRemove(_id: $_id)
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  imageRemove,
  imageAdd,
  imageEdit,
};
