import React from 'react';
import {
  Container,
  Row,
  SelectPicker,
  Col,
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel
} from 'rsuite';
import queryString from 'query-string';

const { REACT_APP_API_URL } = process.env;

class CareerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: '',
      category: ''
    };
  }

  onCompanyChange = value => {
    this.setState({ company: value });
  };

  onCategoryChange = value => {
    this.setState({ category: value });
  };

  onSubmit = () => {
    const { company, category } = this.state;

    const stringified = queryString.stringify({
      companyId: company,
      configId: category
    });

    return window.open(`${REACT_APP_API_URL}/get-report?${stringified}`, '_blank');
  };

  renderForm() {
    const { company, category } = this.state;

    const { companyList, categoryList } = this.props;

    const companies = companyList.map(comp => ({
      label: comp.name,
      value: comp._id
    }));

    const categories = [{ label: 'Бүх үнэлгээ', value: 'all' }].concat(
      categoryList.map(cat => ({
        label: cat.name,
        value: cat._id
      }))
    );

    return (
      <Form className="job-form" layout="horizontal">
        <Row gutter={100}>
          <Col sm={24}>
            <FormGroup>
              <ControlLabel>
                Байгууллага сонгох <span>*</span>
              </ControlLabel>
              <div className="form-item" style={{ marginBottom: 0 }}>
                <FormControl
                  style={{ width: 360 }}
                  accepter={SelectPicker}
                  name="type"
                  placeholder="Сонгох"
                  clearAble={false}
                  data={companies}
                  onChange={this.onCompanyChange}
                  defaultValue={company ? company : undefined}
                />
              </div>
            </FormGroup>
          </Col>
          <Col sm={24}>
            <FormGroup>
              <ControlLabel>
                Үнэлгээ сонгох <span>*</span>
              </ControlLabel>
              <div className="form-item" style={{ marginBottom: 0 }}>
                <FormControl
                  style={{ width: 360 }}
                  accepter={SelectPicker}
                  name="type"
                  placeholder="Сонгох"
                  data={categories}
                  onChange={this.onCategoryChange}
                  defaultValue={category ? category : undefined}
                />
              </div>
            </FormGroup>
          </Col>

          <Col sm={12}>
            <div className="form-item">
              <Button
                type="submit"
                onClick={this.onSubmit}
                style={{ width: '60%', backgroundColor: 'blue' }}
                className="report"
              >
                <span>Тайлан авах </span>
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    return (
      <div>
        <Container className="min">
          <section>
            <h3 className="section-title" style={{ fontSize: '46px' }}>
              Үнэлгээний тайлан
            </h3>
            {this.renderForm()}
          </section>
        </Container>
      </div>
    );
  }
}

export default CareerForm;
