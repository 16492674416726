const faqListQuery = `
  query faqs {
    faqs {
      _id
      question
    }
  }
`;

const imageListQuery = `
 query images($type: String) {
    images(type: $type) {
      _id
      description
      type
      images
      url
    }
  }
`;

const contentListQuery = `
 query contents($title: String, $category: String, $importand: String, $page: String, $perPage: String) {
    contents(title: $title, category: $category, importand: $importand, page: $page, perPage: $perPage) {
      _id
      title
      category
      createdAt
      importand
      pictures
      content
      description
      imageNote
    }
  }
`;

const companiesTopQuery = `
  query companiesTop{
    companiesTop{
      name
      placeId
      score
    }
  }
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  faqListQuery,
  contentListQuery,
  imageListQuery,
  companiesTopQuery
};
