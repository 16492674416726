import React from 'react';
import { useHistory } from 'react-router';
const Marker = (options) => {
  const [marker, setMarker] = React.useState();
  const history = useHistory();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
      marker.addListener('click', () => {
        history.push({
          pathname: '/mark-map',
          search: `?id=${options.data._id}`,
        });
      });
      if (options.onClick) {
        marker.addListener('click', options.onClick);
      }
    }
  }, [marker, options, history]);

  return null;
};

export default Marker;
