import React from 'react';
import { Container, Row, Col, Input, Button } from 'rsuite';
class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ''
    };
  }

  handleInputChange = value => {
    this.setState({
      email: value
    });
  };

  submit = () => {
    const { email } = this.state;

    if (email) {
      this.props.forgotPassword({ email });
    }
  };

  renderForm() {
    return (
      <form className="login-form job-form">
        <Row gutter={100}>
          <Col sm={24}>
            <div className="form-item">
              <label>И-мэйл</label>
              <Input placeholder="И-мэйл" onChange={this.handleInputChange} />
            </div>
          </Col>
          <Col sm={24}>
            <div className="form-item">
              <Button
                className="add login"
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  this.submit(e);
                }}
              >
                <span>Батлах</span>
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    );
  }

  render() {
    return (
      <div>
        <Container className="min">
          <section>
            <h3 className="section-title" style={{ fontSize: '46px' }}>
              Нууц үг сэргээх
            </h3>
            <hr />
            <Row style={{ margin: '4rem 0' }} gutter={80}>
              <Col md={16}>{this.renderForm()}</Col>
              <Col md={8}></Col>
            </Row>
          </section>
        </Container>
      </div>
    );
  }
}

export default Login;
